import { allColors } from "../../util/colors";
import { highlight } from "../../util/util";
import Badge, { BadgeColor, BadgeSize } from "../common/badge/Badge";
import ClockRewindIcon from "../common/svg/ClockRewind";
import MapPinIcon from "../common/svg/MapPin";
import RouteIcon from "../common/svg/Route";
import TruckIcon from "../common/svg/Truck";
import { SvgProps, SvgType } from "../common/svg/types";
import {
  MegaSearchResultItem,
  MegaSearchResultItemType,
  MegaSearchResultMatchType,
  MegaSearchTab,
} from "./types";
import SearchSmIcon from "../common/svg/SearchSm";
import classNames from "classnames";

export default function MegaSearchItem(props: MegaSearchResultItem) {
  const { name, type, currentTab, matchType, matchText, searchTerm, onClick } =
    props;

  const ItemTypeIcon = () => {
    const iconProps: SvgProps = {
      type: SvgType.Line,
      height: "20",
      width: "20",
      stroke: allColors.black,
      classes: "mega-search-item__icon",
    };
    if (type === MegaSearchResultItemType.Text) {
      return <ClockRewindIcon {...iconProps} />;
    }
    if (type === MegaSearchResultItemType.Trip) {
      return <RouteIcon {...iconProps} />;
    }
    if (type === MegaSearchResultItemType.Site) {
      return <MapPinIcon {...iconProps} />;
    }
    if (type === MegaSearchResultItemType.Av) {
      return <TruckIcon {...iconProps} />;
    }
    if (type === MegaSearchResultItemType.AllResults) {
      return <SearchSmIcon {...iconProps} />;
    } else {
      return <SearchSmIcon {...iconProps} />;
    }
  };

  const ItemNameText = () => {
    let adjustedName: string = name;
    if (type === MegaSearchResultItemType.Trip) {
      adjustedName = "Trip " + adjustedName;
    }
    if (type === MegaSearchResultItemType.Av) {
      adjustedName = "AV# " + adjustedName;
    }
    return highlight(adjustedName, searchTerm, "mega-search-item__name");
  };
  const ItemMatchText = () => {
    let fullMatchText: string = matchText || "";
    if (matchType === MegaSearchResultMatchType.TripName) {
      fullMatchText = "Trip " + fullMatchText;
    }
    if (matchType === MegaSearchResultMatchType.AvName) {
      fullMatchText = "AV# " + fullMatchText;
    }
    return highlight(fullMatchText, searchTerm, "mega-search-item__match");
  };
  const ItemMatchHintText = () => {
    let matchTypeText: string = "";
    if (matchType === MegaSearchResultMatchType.TripName) {
      matchTypeText = "Trip ";
    }
    if (matchType === MegaSearchResultMatchType.AvName) {
      matchTypeText = "AV ";
    }
    if (matchType === MegaSearchResultMatchType.SiteName) {
      matchTypeText = "Stop ";
    }
    if (matchType === MegaSearchResultMatchType.LaneId) {
      matchTypeText = "Lane ID ";
    }
    return (
      <div className="mega-search-item__match-hint">
        {matchTypeText + "match found"}
      </div>
    );
  };

  const ItemBadgeText = () => {
    if (currentTab === MegaSearchTab.All) {
      return "Filter in current tab";
    }
    if (currentTab === MegaSearchTab.Trips) {
      return "View 'Trip Timeline'";
    }
    if (currentTab === MegaSearchTab.Stops) {
      return "View matches in 'Stops'";
    }
  };

  return (
    <li className="mega-search-item">
      <button className="mega-search-item__button" onClick={onClick}>
        <div className="mega-search-item__left-content">
          {ItemTypeIcon()}
          <div className="flex flex-col items-start">
            {ItemNameText()}
            {matchText &&
              matchType &&
              currentTab !== MegaSearchTab.All &&
              ItemMatchText()}
          </div>
        </div>
        <div
          className={classNames("mega-search-item__right-content", {
            "mega-search-item__right-content--all-results":
              type === MegaSearchResultItemType.AllResults,
          })}
        >
          {currentTab && type !== MegaSearchResultItemType.AllResults && (
            <Badge
              color={BadgeColor.Primary}
              size={BadgeSize.Xs}
              outlined
              rounded
              classes="mega-search-item__badge"
            >
              {ItemBadgeText()}
            </Badge>
          )}
          {currentTab !== MegaSearchTab.All && matchType && ItemMatchHintText()}
          {type === MegaSearchResultItemType.AllResults && (
            <Badge
              color={BadgeColor.Gray}
              size={BadgeSize.Xs}
              classes="mega-search-item__all-badge"
            >
              Press 'Enter'
            </Badge>
          )}
        </div>
      </button>
    </li>
  );
}
