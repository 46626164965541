import classNames from "classnames";
import {
  Stop,
  StopEventStatus,
  StopSequenceStatus,
  TimelinessStatus,
  TripStatus,
} from "../../../types/trip";
import AlertTriangleIcon from "../../common/svg/AlertTriangle";
import { allColors } from "../../../util/colors";
import { dayjs } from "../../../util/date-util";

export type StopProgressBarProps = {
  stop: Stop;
  isOrigin?: boolean;
  isFinalDestination?: boolean;
  cardVariant?: boolean;
  classes?: string;
};

export default function StopProgressBar(props: StopProgressBarProps) {
  const { stop, isOrigin, cardVariant, classes } = props;

  const completedDistancePercent =
    stop.travelled_distance && stop.total_distance
      ? Math.min(stop.travelled_distance / stop.total_distance, 1)
      : 0;

  const roundToOneDecimal = (number: number) => {
    return Math.round(number * 10) / 10;
  };

  const timeBetweenEstimatedAndActualDeparture = () => {
    const estimated = stop.scheduled_departure;
    const actual = stop.departed_at;
    let difference = null;
    if (estimated && actual) {
      difference = Math.abs(dayjs(estimated).diff(dayjs(actual), "minute"));
      return difference;
    } else {
      return null;
    }
  };

  const timeBetweenEstimatedAndActualArrival = () => {
    const estimated = stop.scheduled_arrival;
    const actual = stop.arrived_at;
    let difference = null;
    if (estimated && actual) {
      difference = Math.abs(dayjs(estimated).diff(dayjs(actual), "minute"));
      return difference;
    } else {
      return null;
    }
  };

  const stopStatusText = // origin is departed & late or early
    stop.sequence_status === StopSequenceStatus.OutOfSequence &&
    stop.event_status !== StopEventStatus.AwaitingAV
      ? `${stop.event_status === StopEventStatus.AVArrived ? "Arrived" : "Departed"} out of sequence`
      : stop.sequence_status === StopSequenceStatus.OutOfSequence
        ? "Arrived out of sequence"
        : stop.departed_at &&
            stop.scheduled_departure &&
            (stop.departure_status === TimelinessStatus.Early ||
              stop.departure_status === TimelinessStatus.Late) &&
            isOrigin
          ? `AV departed ${timeBetweenEstimatedAndActualDeparture()} min ${stop.departure_status === TimelinessStatus.Early ? "early" : "late"}`
          : // non-origin is departed & is late or early
            stop.event_status !== StopEventStatus.AwaitingAV &&
              stop.scheduled_arrival &&
              stop.arrived_at &&
              (stop.departure_status === TimelinessStatus.Early ||
                stop.departure_status === TimelinessStatus.Late) &&
              !isOrigin
            ? `AV arrived ${timeBetweenEstimatedAndActualArrival()} min ${stop.departure_status === TimelinessStatus.Early ? "early" : "late"}`
            : stop.sequence_status === StopSequenceStatus.Unplanned
              ? "Stop unexpected" // departed on time
              : stop.event_status === StopEventStatus.AVDeparted
                ? `AV completed stop activity`
                : // arrived on time
                  stop.event_status === StopEventStatus.AVArrived
                  ? `AV at stop`
                  : stop.sequence_status === StopSequenceStatus.Skipped &&
                      stop.trip_status === TripStatus.InProgress
                    ? "Likely skipped"
                    : stop.sequence_status === StopSequenceStatus.Skipped
                      ? "Skipped"
                      : stop.trip_status === TripStatus.InProgress &&
                          stop.ett &&
                          stop.stops_away === 0
                        ? `Arriving in ${stop.ett} min`
                        : stop.trip_status === TripStatus.InProgress &&
                            stop.stops_away &&
                            stop.stops_away > 0
                          ? `AV is ${stop.stops_away} stops away`
                          : stop.trip_status === TripStatus.InProgress
                            ? "Arriving soon"
                            : stop.trip_status === TripStatus.Scheduled
                              ? "Upcoming trip"
                              : stop.trip_status === TripStatus.Cancelled
                                ? "Trip Cancelled"
                                : stop.trip_status ===
                                    TripStatus.TrackingTimeout
                                  ? "Trip timed out"
                                  : null;
  return (
    <div
      className={classNames(
        "stop-progress-bar",
        {
          "stop-progress-bar--card-variant": cardVariant,
          "stop-progress-bar--in-progress":
            stop.trip_status === TripStatus.InProgress && stop.stops_away === 0,
          "stop-progress-bar--arrived":
            stop.event_status === StopEventStatus.AVArrived,
          "stop-progress-bar--departed":
            stop.event_status === StopEventStatus.AVDeparted,
        },
        classes
      )}
    >
      <div className="stop-progress-bar__bar-container">
        {stop.trip_status === TripStatus.InProgress &&
          stop.stops_away === 0 &&
          stop.event_status === StopEventStatus.AwaitingAV && (
            <div
              className="stop-progress-bar__progress-indicator"
              style={{
                width: stop.total_distance
                  ? // if in progress, set width to trip progress with min of 2% and max of 98%
                    `${Math.max(Math.min(100 * completedDistancePercent, 97), 5)}%`
                  : "0px",
                animationDuration: `${1000 * Math.max(completedDistancePercent, 0.05) || 500}s`,
              }}
            ></div>
          )}
      </div>
      {!cardVariant && (
        <div className="stop-progress-bar__text-content flex flex-row justify-between mt-1">
          <span className="flex flex-row items-center">
            {stopStatusText}
            {stop.sequence_status === StopSequenceStatus.Skipped && (
              <AlertTriangleIcon
                fill={allColors.gray[700]}
                height="16"
                width="16"
                classes="ml-2"
              />
            )}
          </span>
          {stop.total_distance && (
            <span>
              <b>
                {stop.travelled_distance
                  ? roundToOneDecimal(stop.travelled_distance)
                  : "0"}
              </b>{" "}
              / {roundToOneDecimal(stop.total_distance)} mi
            </span>
          )}
        </div>
      )}
      {stop.total_distance && (
        <progress
          className="sr-only"
          value={Math.min(
            stop.travelled_distance ? stop.travelled_distance : 0,
            stop.total_distance
          )}
          max={stop.total_distance}
        ></progress>
      )}
    </div>
  );
}
