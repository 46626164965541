import classNames from "classnames";

export type SupportModalIconProps = {
  classes?: string;
};

export default function SupportModalIcon(props: SupportModalIconProps) {
  const { classes } = props;

  return (
    <svg
      className={classNames("support-modal-icon", classes)}
      width="108"
      height="130"
      viewBox="80 27 108 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M120.096 45.6508C99.5517 36.7178 88.5521 36.45 87.0463 43.8645C85.5502 51.2293 96.5746 56.0727 106.697 60.2405C116.82 64.4084 120.096 45.6508 120.096 45.6508Z"
        fill="#C7D7FE"
      />
      <path
        d="M180.263 113.147V141.406C177.603 148.413 159.447 150.499 147.223 147.824C141.755 146.637 137.474 144.493 136.288 141.406V114.895L146.449 114.497L162.623 113.848L163.544 113.811L180.263 113.147Z"
        fill="#C7D7FE"
      />
      <path
        d="M180.613 116.984C180.502 117.135 180.383 117.279 180.266 117.423C174.858 124.207 166.323 127.984 159.459 130.035C153.317 131.87 148.519 132.33 148.519 132.33L144.586 114.493L141.537 100.668L133.52 64.3131L161.425 64.5232C165.689 66.5052 169.13 68.6207 172.222 71.3886C187.207 84.8036 188.998 105.997 180.613 116.984Z"
        fill="#C7D7FE"
      />
      <path
        d="M162.188 123.102C159.086 125.364 156.478 128.253 154.554 131.598C152.786 134.678 150.804 144.058 147.223 147.824C141.755 146.637 137.474 144.493 136.288 141.406V114.895L146.449 114.497L162.623 113.848C163.035 116.92 163.042 119.971 162.188 123.102Z"
        fill="#2D31A6"
      />
      <path
        d="M161.998 82.5768C160.113 86.2223 160.147 99.4085 163.284 105.614C164.28 107.584 154.082 98.7713 153.956 96.8409C153.875 95.6545 153.898 94.4975 154.001 93.3627C153.986 93.3476 153.979 93.3334 153.972 93.3111C153.861 93.0753 153.765 92.8394 153.677 92.6036C153.124 92.471 152.638 92.0731 152.432 91.3585L152.005 89.8775C150.679 87.9765 149.308 84.9629 150 82.8705C149.964 82.745 149.927 82.6276 149.89 82.5021C149.765 82.0597 149.794 81.669 149.927 81.338C148.092 80.7265 146.302 79.9229 144.57 78.9287C142.507 77.7495 144.363 74.5668 146.426 75.7452C147.089 76.1288 147.767 76.475 148.445 76.7918C152.654 78.7445 161.998 82.5768 161.998 82.5768Z"
        fill="#2D31A6"
      />
      <path
        d="M135.782 36.5106L86.6086 56.8663L121.42 140.96L170.593 120.604L135.782 36.5106Z"
        fill="#9B7AE9"
      />
      <path
        d="M115.233 139.134L121.412 140.96L86.6039 56.8684L80.4255 55.0421L115.233 139.134Z"
        fill="#2D3282"
      />
      <path
        d="M129.597 34.6886L135.776 36.5149L86.6039 56.8683L80.4255 55.0421L129.597 34.6886Z"
        fill="#9B7AE9"
      />
      <path
        d="M161.241 111.418L121.65 127.803L108.436 95.878L97.9852 70.6376L93.9553 61.0745L133.546 44.6825L161.241 111.418Z"
        fill="#CDBCF4"
      />
      <path
        d="M141.978 128.998C141.924 128.998 141.87 128.987 141.82 128.966C141.718 128.925 141.638 128.844 141.596 128.742L139.987 124.853C139.9 124.642 140 124.401 140.21 124.313L146.861 121.56C147.073 121.473 147.314 121.573 147.402 121.783L149.012 125.672C149.053 125.774 149.053 125.888 149.012 125.988C148.97 126.09 148.889 126.17 148.788 126.212L142.136 128.965C142.085 128.987 142.032 128.998 141.978 128.998ZM140.908 124.918L142.201 128.043L148.09 125.607L146.796 122.481L140.908 124.918Z"
        fill="white"
      />
      <path
        d="M125.754 59.1421L97.9919 70.6346L108.441 95.8761L136.203 84.3837L125.754 59.1421Z"
        fill="white"
      />
      <path
        d="M112.419 102.346C112.257 102.346 112.103 102.249 112.038 102.09C111.95 101.879 112.051 101.637 112.261 101.55L140.022 90.0583C140.234 89.9719 140.475 90.0716 140.562 90.2816C140.649 90.4926 140.549 90.7347 140.339 90.8219L112.578 102.314C112.525 102.336 112.472 102.346 112.419 102.346Z"
        fill="white"
      />
      <path
        d="M107.835 51.5115C107.673 51.5115 107.519 51.4153 107.453 51.256C107.366 51.0451 107.466 50.803 107.676 50.7158L117.333 46.7188C117.545 46.6316 117.786 46.7322 117.873 46.9422C117.96 47.1531 117.86 47.3952 117.65 47.4824L107.993 51.4794C107.942 51.5008 107.887 51.5115 107.835 51.5115Z"
        fill="#2D31A6"
      />
      <path
        d="M115.066 108.358C114.904 108.358 114.75 108.261 114.684 108.102C114.597 107.891 114.698 107.649 114.908 107.562L142.669 96.0711C142.88 95.9848 143.122 96.0844 143.209 96.2945C143.296 96.5054 143.195 96.7475 142.985 96.8347L115.225 108.326C115.173 108.348 115.119 108.358 115.066 108.358Z"
        fill="white"
      />
      <path
        d="M120.25 46.8737C120.738 46.8737 121.132 46.4788 121.132 45.9917C121.132 45.5046 120.738 45.1097 120.25 45.1097C119.763 45.1097 119.368 45.5046 119.368 45.9917C119.368 46.4788 119.763 46.8737 120.25 46.8737Z"
        fill="#2D31A6"
      />
      <path
        d="M131.069 52.8224C131.034 52.8224 130.999 52.818 130.964 52.8091L127.2 51.8185C126.98 51.7607 126.848 51.5346 126.906 51.3139C126.964 51.0932 127.191 50.9623 127.41 51.0193L131.174 52.0099C131.395 52.0677 131.527 52.2938 131.469 52.5145C131.42 52.6996 131.252 52.8224 131.069 52.8224Z"
        fill="#2D31A6"
      />
      <path
        d="M128.098 60.3501C127.955 60.3501 127.817 60.2762 127.741 60.1454C127.625 59.9478 127.693 59.695 127.889 59.5802L130.267 58.1936C130.461 58.0779 130.716 58.1446 130.832 58.3422C130.947 58.5398 130.88 58.7926 130.683 58.9074L128.306 60.294C128.24 60.3323 128.169 60.3501 128.098 60.3501Z"
        fill="#2D31A6"
      />
      <path
        d="M155.76 33.1623C154.646 33.1623 153.739 32.2554 153.739 31.1411C153.739 30.0268 154.646 29.1199 155.76 29.1199C156.875 29.1199 157.781 30.0268 157.781 31.1411C157.781 32.2554 156.875 33.1623 155.76 33.1623ZM155.76 29.9459C155.101 29.9459 154.565 30.4816 154.565 31.1402C154.565 31.7988 155.101 32.3346 155.76 32.3346C156.419 32.3346 156.955 31.7988 156.955 31.1402C156.955 30.4816 156.419 29.9459 155.76 29.9459Z"
        fill="#CDBCF4"
      />
      <path
        d="M96.0013 130.974C95.9462 130.974 95.8919 130.964 95.8385 130.94L92.0871 129.332C91.8771 129.242 91.7801 128.999 91.87 128.79C91.9607 128.579 92.2037 128.481 92.4129 128.573L96.1642 130.181C96.3742 130.271 96.4713 130.514 96.3814 130.723C96.3137 130.88 96.1615 130.974 96.0013 130.974Z"
        fill="#CDBCF4"
      />
      <path
        d="M172.222 71.3887C172.222 71.3887 167.326 88.6636 163.119 86.7109C149.383 77.8563 138.082 66.1288 134.444 58.5718C133.414 56.4305 133.221 54.2241 133.49 53.0022C135.445 44.1182 143.316 46.2622 172.222 71.3887Z"
        fill="#C7D7FE"
      />
      <path
        d="M144.524 56.1225C142.394 56.1225 139.702 55.1595 136.467 53.2336C136.271 53.117 136.206 52.8633 136.324 52.6666C136.441 52.47 136.696 52.4068 136.891 52.5234C142.473 55.8457 146.24 56.1768 148.089 53.5059C148.218 53.3172 148.475 53.2718 148.664 53.4009C148.851 53.5308 148.899 53.788 148.769 53.9758C147.778 55.407 146.359 56.1225 144.524 56.1225Z"
        fill="#2D31A6"
      />
      <path
        d="M82.695 70.075C72.2322 77.2084 88.0671 88.6342 95.8813 88.8781C103.695 89.1219 103.695 81.5525 99.5445 76.1796C95.3927 70.8075 88.0671 66.4118 82.695 70.075Z"
        fill="#C7D7FE"
      />
      <path
        d="M85.3811 83.7499C74.9182 90.8832 90.7531 102.309 98.5673 102.553C106.382 102.797 106.382 95.2273 102.231 89.8544C98.0796 84.4815 90.7531 80.0867 85.3811 83.7499Z"
        fill="#C7D7FE"
      />
      <path
        d="M96.7999 89.1513C91.6468 89.1513 85.1017 84.9202 84.7555 84.6942C84.5642 84.5696 84.5108 84.3133 84.6354 84.1228C84.7608 83.9323 85.0172 83.8781 85.2076 84.0027C85.31 84.0694 95.5138 90.667 99.9709 87.4425C100.156 87.3081 100.414 87.3499 100.548 87.5351C100.682 87.7202 100.639 87.9783 100.455 88.1118C99.4272 88.8549 98.1616 89.1513 96.7999 89.1513Z"
        fill="#2D31A6"
      />
      <path
        d="M87.907 82.4407C87.8438 82.4407 87.7806 82.4265 87.721 82.3962C87.5172 82.293 87.4353 82.0447 87.5386 81.8408C88.5985 79.7485 90.0786 78.4962 91.9405 78.1189C96.0122 77.2929 100.396 80.9517 100.581 81.1075C100.754 81.2543 100.776 81.5151 100.629 81.6895C100.482 81.8631 100.22 81.8862 100.047 81.7385C100.004 81.7029 95.7791 78.1812 92.1016 78.9297C90.5014 79.2545 89.2144 80.3599 88.2746 82.2146C88.2025 82.3579 88.0565 82.4407 87.907 82.4407Z"
        fill="#2D31A6"
      />
      <path
        d="M90.5394 97.3749C90.5074 97.3749 90.4744 97.3713 90.4397 97.3624C90.219 97.3081 90.0828 97.0839 90.138 96.8623C90.8242 94.081 92.565 92.2725 95.0392 91.7697C97.954 91.1778 101.408 92.5333 103.256 94.9959C103.392 95.1784 103.355 95.4374 103.173 95.5744C102.991 95.7106 102.732 95.675 102.594 95.4917C100.956 93.3076 97.7813 92.0563 95.2048 92.5796C93.6882 92.8884 91.7178 93.9164 90.9417 97.0607C90.8945 97.2485 90.7254 97.3749 90.5394 97.3749Z"
        fill="#2D31A6"
      />
      <path
        d="M154.88 64.3016C154.809 64.3016 154.738 64.2838 154.672 64.2455C154.475 64.1307 154.409 63.878 154.523 63.6804L156.902 59.6024C157.017 59.4048 157.27 59.3381 157.468 59.4538C157.664 59.5686 157.732 59.8214 157.616 60.0189L155.237 64.0969C155.16 64.2277 155.022 64.3016 154.88 64.3016Z"
        fill="white"
      />
    </svg>
  );
}
