import classNames from "classnames";
import AlertTriangleIcon from "../../../common/svg/AlertTriangle";
import { allColors } from "../../../../util/colors";
import StyledButton from "../../../common/button/StyledButton";
import ArrowIcon from "../../../common/svg/Arrow";
import { SvgDirection } from "../../../common/svg/types";

export type TripTimelineNoResultsProps = {
  classes?: string;
  onClose?: () => void;
};

export default function TripTimelineNoResults(
  props: TripTimelineNoResultsProps
) {
  const { onClose, classes } = props;

  return (
    <div className={classNames("trip-timeline-no-results", classes)}>
      <div className="flex flex-col items-center">
        <AlertTriangleIcon
          classes="mb-6"
          width="50"
          height="50"
          fill={allColors.gray[300]}
        />
        <div className="text-xl font-medium text-gray-600 mb-12">
          No trip found
        </div>
        <StyledButton color="primary" textOnly onClick={onClose}>
          Go back
          <ArrowIcon
            classes="ml-4"
            direction={SvgDirection.Right}
            stroke={allColors.primary[700]}
          />
        </StyledButton>
      </div>
    </div>
  );
}
