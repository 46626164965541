export enum MegaSearchTab {
  All = "all",
  Trips = "trips",
  Stops = "stops",
}

export type MegaSearchProps = {
  classes?: string;
  inputClasses?: string;
  containerClasses?: string;
  setSelectedValue?: (value: MegaSearchResultItem | null) => void;
};

export type MegaSearchResults = {
  total_count: number;
  results?: MegaSearchResultItems;
};

export type MegaSearchResultItems = {
  count?: number;
  label?: string;
  results?: MegaSearchResultItem[];
  searchTerm?: string;
  currentTab?: MegaSearchTab;
  onClickResult?: (result: MegaSearchResultItem) => void;
  classes?: string;
};

export type MegaSearchResultItem = {
  name: string;
  id?: string;
  type: MegaSearchResultItemType;
  matchType?: MegaSearchResultMatchType;
  matchText?: string;
  searchTerm?: string;
  currentTab?: MegaSearchTab;
  onClick?: () => void;
};

export enum MegaSearchResultItemType {
  Text = "text",
  Trip = "trip",
  Av = "av",
  Site = "site",
  LaneId = "lane_id",
  AllResults = "all_results",
}
export enum MegaSearchResultMatchType {
  TripName = "trip_name",
  AvName = "av_name",
  SiteName = "site_name",
  LaneId = "lane_id",
}
