import { Autocomplete, MenuItem, TextField } from "@mui/material";
import CheckmarkIcon from "../../common/svg/Checkmark";
import { AutocompleteItem, SingleAutocompleteProps } from "./types";
import { allColors } from "../../../util/colors";
import ChevronIcon from "../../common/svg/Chevron";
import { SvgDirection } from "../../common/svg/types";
import classNames from "classnames";
import CloseSmIcon from "../../common/svg/CloseSm";

const SingleAutocomplete = (props: SingleAutocompleteProps) => {
  const {
    value,
    startIcon,
    label,
    isDisabled,
    extraClasses,
    inputClasses,
    noResultsText,
    hideClearButton,
    ...otherProps
  } = props;

  return (
    <Autocomplete
      {...otherProps}
      value={value}
      clearIcon={
        hideClearButton ? null : <CloseSmIcon stroke={allColors.gray[400]} />
      }
      disablePortal
      renderInput={(params) => (
        <TextField
          {...params}
          classes={{ root: "autocomplete__input-label" }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {startIcon && startIcon}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          InputLabelProps={{ shrink: false }}
          placeholder={label}
        />
      )}
      renderOption={(props, option: AutocompleteItem) => (
        <MenuItem
          {...props}
          selected={value && value.value === option.value ? true : false}
          disableRipple
          classes={{
            root: "autocomplete__item",
            focusVisible: "autocomplete-item--focus-visible",
            disabled: "autocomplete-item--disabled",
            selected: "autocomplete-item--selected",
          }}
        >
          <div className="flex flex-row items-center">
            {option.startIcon}
            {option.extendedLabel ? option.extendedLabel : option.label}
          </div>
          {value && value.value === option.value && (
            <CheckmarkIcon stroke={allColors.primary[600]} classes="min-w-12" />
          )}
        </MenuItem>
      )}
      disabled={isDisabled}
      size="small"
      openOnFocus
      noOptionsText={noResultsText}
      popupIcon={
        <ChevronIcon direction={SvgDirection.Down} width="20" height="20" />
      }
      slotProps={{
        popupIndicator: { disableRipple: true },
        popper: {
          placement: "bottom-start",
        },
      }}
      classes={{
        root: classNames("autocomplete autocomplete--single", extraClasses),
        expanded: "autocomplete--expanded",
        focused: "autocomplete--focused",
        focusVisible: "autocomplete--focus-visible",
        inputRoot: classNames("autocomplete__input-root", inputClasses),
        input: "autocomplete__input",
        inputFocused: "autocomplete__input--focused",
        popper: "autocomplete__popper",
        paper: "autocomplete__paper",
        listbox: "autocomplete__listbox",
        noOptions: "autocomplete__no-options-text",
        groupLabel: "autocomplete__group-label",
        groupUl: "autocomplete__group-ul",
      }}
    />
  );
};

export default SingleAutocomplete;
