import classNames from "classnames";
import { allColors } from "../../util/colors";

export type DotProps = {
  color?: string;
  size?: string;
  classes?: string;
};

export default function Dot(props: DotProps) {
  const { color = allColors.white, size = "10px", classes } = props;

  return (
    <div
      className={classNames("dot", classes)}
      style={{
        height: size,
        width: size,
        borderRadius: size,
        backgroundColor: color,
      }}
    ></div>
  );
}
