import classNames from "classnames";
import { Trip } from "../../../types/trip";
import Table, { TableColumnWidths } from "../../common/table/Table";
import TableHeader, {
  ColumnSortState,
  TableHeaderColumn,
} from "../../common/table/TableHeader";
import TripsTableRow from "./TripsTableRow";
import { useXlBreakpoint } from "../../../util/breakpoints";
import { TripsSortMethod } from "../../../api/trip";
import { ReactElement, useMemo } from "react";
import LoadingSpinner from "../../common/loading-spinner/LoadingSpinner";

export type TripsTableProps = {
  trips?: Trip[];
  sortValue?: string | TripsSortMethod;
  onChangeSortValue?: (newValue: string | TripsSortMethod) => void;
  onClickItem?: (trip: Trip) => void;
  isLoadingItems?: boolean;
  noResults?: ReactElement;
  classes?: string;
};

export default function TripsTable(props: TripsTableProps) {
  const {
    trips,
    sortValue,
    onChangeSortValue,
    onClickItem,
    isLoadingItems,
    noResults,
    classes,
  }: TripsTableProps = props;
  const isXl = useXlBreakpoint();

  // `width` percentages must add up to 100%.
  const tripsTableColumnWidths: TableColumnWidths[] = [
    { minWidth: "160px", width: "15%" },
    { minWidth: "95px", width: "10%" },
    { minWidth: "95px", width: "10%" },
    { minWidth: "250px", width: "30%" },
    { minWidth: "150px", width: "20%" },
    { minWidth: "150px", width: "15%" },
    { minWidth: isXl ? "170px" : "60px" },
  ];

  const tripTableColumnInfo: TableHeaderColumn[] = [
    {
      name: "Trip name",
      value: "name",
      sortable: true,
      ...tripsTableColumnWidths[0],
    },
    {
      name: "Lane ID",
      value: "external_id",
      sortable: true,
      ...tripsTableColumnWidths[1],
    },
    {
      name: "AV#",
      value: "long_av_id",
      sortable: true,
      ...tripsTableColumnWidths[2],
    },
    {
      name: "Trip progress",
      value: "trip_progress",
      sortable: true,
      ...tripsTableColumnWidths[3],
    },
    {
      name: "Last activity at",
      ...tripsTableColumnWidths[4],
    },
    {
      name: "Next location",
      ...tripsTableColumnWidths[5],
    },
    {
      name: "",
      ...tripsTableColumnWidths[6],
    },
  ];

  const tripTableColumns: TableHeaderColumn[] = useMemo(() => {
    const currentSortColumnName = sortValue && sortValue.replace("-", "");

    const columnsWithActiveValue: TableHeaderColumn[] = tripTableColumnInfo.map(
      (column) => {
        const isActiveColumn = currentSortColumnName
          ? column.value === currentSortColumnName
          : false;
        const sortDirection =
          sortValue && sortValue[0] === "-"
            ? ColumnSortState.Descending
            : sortValue
              ? ColumnSortState.Ascending
              : undefined;
        return {
          ...column,
          currentState:
            isActiveColumn && sortDirection ? sortDirection : undefined,
        };
      }
    );
    return columnsWithActiveValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  return (
    <Table classes={classNames("trips-table", classes)}>
      <TableHeader
        columns={tripTableColumns}
        onChangeSortValue={(sortName, sortDirection) => {
          let newSortValue = sortName;
          if (sortDirection === ColumnSortState.Descending) {
            newSortValue = "-" + newSortValue;
          }
          onChangeSortValue && onChangeSortValue(newSortValue);
        }}
      />
      <tbody>
        {isLoadingItems && (
          <tr>
            <td colSpan={tripTableColumns.length} className="h-96">
              <LoadingSpinner centered classes="my-96" />
            </td>
          </tr>
        )}
        {!isLoadingItems && (!trips || trips.length <= 0) && (
          <tr>
            <td colSpan={tripTableColumns.length} className="h-96">
              {noResults}
            </td>
          </tr>
        )}
        {!isLoadingItems &&
          trips?.map((trip, index) => {
            return (
              <TripsTableRow
                key={index}
                trip={trip}
                onClickItem={() => onClickItem && onClickItem(trip)}
                columnWidths={tripsTableColumnWidths}
              />
            );
          })}
      </tbody>
    </Table>
  );
}
