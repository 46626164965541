import { AutocompleteItem, MultiAutocompleteProps } from "../types";
import MultiAutocomplete from "../MultiAutocomplete";
import { TimelinessStatus } from "../../../../types/trip";
import classNames from "classnames";

export type TimelinessFilterProps = Omit<MultiAutocompleteProps, "options">;

export const timelinessOptions: AutocompleteItem[] = [
  { label: "On time", value: TimelinessStatus.OnTime },
  { label: "Early", value: TimelinessStatus.Early },
  { label: "Late", value: TimelinessStatus.Late },
];

const TimelinessFilter = (props: TimelinessFilterProps) => {
  const { ...otherProps } = props;

  return (
    <MultiAutocomplete
      label="Timeliness"
      options={timelinessOptions}
      extraClasses={classNames("timeliness-filter", otherProps.extraClasses)}
      {...otherProps}
    />
  );
};

export default TimelinessFilter;
