import classNames from "classnames";
import { ConditionalWrapper } from "../../../util/util";

export enum BadgeColor {
  Primary = "primary",
  Error = "error",
  Warning = "warning",
  Success = "success",
  Gray = "gray",
  White = "white",
}

export enum BadgeSize {
  Xs = "xs",
  Sm = "sm",
  Md = "md",
  Lg = "lg",
}

export type BadgeProps = {
  color?: BadgeColor;
  size?: BadgeSize;
  outlined?: boolean;
  rounded?: boolean;
  classes?: string;
  children?: React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  onClick?: () => void;
};

export default function Badge(props: BadgeProps) {
  const {
    size = BadgeSize.Md,
    color = BadgeColor.Primary,
    outlined,
    rounded,
    classes,
    children,
    startIcon,
    endIcon,
    onClick,
  }: BadgeProps = props;

  return (
    <>
      <ConditionalWrapper
        condition={typeof onClick !== "undefined"}
        wrapper={(children: any) => {
          return <button onClick={onClick}>{children}</button>;
        }}
      >
        <div
          className={classNames(
            "badge",
            `badge--${size}`,
            `badge--${color}`,
            "flex items-center text-md font-medium",
            { "badge--outline": outlined },
            { "badge--rounded": rounded },
            classes
          )}
        >
          {startIcon}
          {children}
          {endIcon}
        </div>
      </ConditionalWrapper>
    </>
  );
}
