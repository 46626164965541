import { useEffect, useState } from "react";
import classNames from "classnames";
import {
  Stop,
  StopEventStatus,
  StopSequenceStatus,
  Trip,
} from "../../../../types/trip";
import TripTimelineItem from "../trip-timeline-item/TripTimelineItem";
import {
  CustomerSettingName,
  getSingleCustomerSetting,
} from "../../../../api/customer";

export type TripTimelineGroupProps = {
  trip: Trip;
  useMyTimezone?: boolean;
  classes?: string;
};

export default function TripTimelineGroup(props: TripTimelineGroupProps) {
  const { trip, useMyTimezone, classes } = props;
  const [adjustedItinerary, setAdjustedItinerary] = useState<Stop[] | null>(
    null
  );
  const [stopsToShowEta, setStopsToShowEta] = useState<number>(0);

  useEffect(() => {
    // Get customer settings to display ETAs
    getSingleCustomerSetting({
      name: CustomerSettingName.EstTimeStopsDisplay,
      customer_id: trip.customer_id,
    }).then((resp) => {
      if (typeof resp.value === "number") {
        setStopsToShowEta(resp.value);
      }
    });

    // Itirnerary adjustment logic
    let newItinerary: Stop[] = structuredClone(trip.itinerary.stops);
    let allOutOfSequenceStops: Stop[] = [];

    newItinerary.forEach((stop, index) => {
      if (stop.sequence_status === StopSequenceStatus.OutOfSequence) {
        // Add each OOS stop to list
        allOutOfSequenceStops.push(stop);
        // Change each OOS stop to skipped in its scheduled position
        newItinerary[index] = {
          ...stop,
          sequence_status: StopSequenceStatus.Skipped,
          event_status: StopEventStatus.AwaitingAV,
          arrived_at: null,
          departed_at: null,
          id: `${stop.id}-skipped_duplicate`,
        };
      }
    });

    // Sort out-of-sequence stops starting with lowest logged_order
    const oosStopsOrderd = allOutOfSequenceStops.sort((a, b) => {
      if (a.logged_order && b.logged_order) {
        return a.logged_order - b.logged_order;
      } else {
        return -1;
      }
    });
    oosStopsOrderd.forEach((stop) => {
      // Get position of previous logged stop in itinerary
      const previousStopIndex = newItinerary.findIndex((prevStop) => {
        if (stop.logged_order) {
          return (
            !prevStop.id.endsWith("duplicate") &&
            prevStop.logged_order === stop.logged_order - 1
          );
        } else return -1;
      });
      // Add duplicate stop object in it's logged position
      newItinerary.splice(previousStopIndex + 1, 0, stop);
    });

    // Set adjusted itinerary to be used in trip timeline
    setAdjustedItinerary(newItinerary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip]);

  const firstStopNotDeparted =
    adjustedItinerary &&
    adjustedItinerary.find(
      (stop) =>
        stop.event_status !== StopEventStatus.AVDeparted &&
        stop.sequence_status !== StopSequenceStatus.Skipped
    );

  // This is the index of the stop that is currently in progress
  const firstStopNotDepartedIndex =
    adjustedItinerary &&
    adjustedItinerary.findIndex(
      (stop) =>
        stop.event_status !== StopEventStatus.AVDeparted &&
        stop.sequence_status !== StopSequenceStatus.Skipped
    );

  // Check if the active stop has arrived
  const isInTransit =
    firstStopNotDeparted?.event_status === StopEventStatus.AwaitingAV;

  return (
    <div className={classNames("trip-timeline-group", classes)}>
      <ul>
        {adjustedItinerary &&
          adjustedItinerary.map((item, index) => {
            const shouldShowEta =
              // Don't show ETA if stop is origin
              index === 0
                ? false
                : // or if stop was skipped
                  item.sequence_status === StopSequenceStatus.Skipped
                  ? false
                  : // If previous stop has arrived or departed
                    adjustedItinerary[index - 1].event_status !==
                      StopEventStatus.AwaitingAV ||
                    // or the stop is upcoming and within the number of future ETAs to display based on the customer's setting
                    adjustedItinerary
                      .slice(Math.max(index - stopsToShowEta, 0), index)
                      .some(
                        (stop) =>
                          stop.event_status === StopEventStatus.AVDeparted
                      );

            const isPreviousStopSkipped =
              index > 0
                ? adjustedItinerary[index - 1].sequence_status ===
                  StopSequenceStatus.Skipped
                : false;

            return (
              <TripTimelineItem
                key={index}
                stop={item}
                stopIndex={index}
                isOrigin={index === 0}
                isFinalDestination={adjustedItinerary.length - 1 === index}
                isInTransit={isInTransit}
                isLastDeparted={
                  firstStopNotDepartedIndex
                    ? firstStopNotDepartedIndex - 1 === index
                    : false
                }
                isActiveStop={firstStopNotDepartedIndex === index}
                isPreviousStopSkipped={isPreviousStopSkipped}
                showEta={shouldShowEta}
                useMyTimezone={useMyTimezone}
              />
            );
          })}
      </ul>
    </div>
  );
}
