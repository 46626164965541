import classNames from "classnames";
import { SvgProps } from "./types";

export default function GooglePinIcon(props: SvgProps) {
  const { width = "20", height = "19", classes } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 19"
      fill="none"
      className={classNames("icon icon__google-pin", classes)}
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4801 7.21412L14.48 7.23792C14.4799 7.24795 14.4799 7.25797 14.4797 7.268C14.48 7.2797 14.4801 7.29149 14.4801 7.30339C14.4801 9.17161 13.0246 10.8253 12.0506 11.9319C11.7679 12.253 11.5259 12.5281 11.3717 12.7489C10.8231 13.5345 10.5336 14.3856 10.4574 14.7129C10.4574 14.9594 10.2528 15.1592 10.0003 15.1592C9.74782 15.1592 9.54317 14.9594 9.54317 14.7129C9.46698 14.3856 9.17747 13.5345 8.62893 12.7489C8.47472 12.5281 8.23264 12.253 7.95002 11.9319C6.97603 10.8253 5.52051 9.17161 5.52051 7.30339C5.52051 7.29149 5.52062 7.2797 5.52084 7.268C5.52062 7.25006 5.52051 7.2321 5.52051 7.21412C5.52051 4.79827 7.52617 2.83984 10.0003 2.83984C12.4744 2.83984 14.4801 4.79827 14.4801 7.21412ZM10.0003 8.821C10.9596 8.821 11.7373 8.06161 11.7373 7.12485C11.7373 6.1881 10.9596 5.4287 10.0003 5.4287C9.04094 5.4287 8.26323 6.1881 8.26323 7.12485C8.26323 8.06161 9.04094 8.821 10.0003 8.821Z"
          fill="#34A851"
        />
        <path
          d="M13.9787 5.20256C13.4301 4.17048 12.4727 3.37885 11.316 3.03223L8.74316 5.95455C9.05949 5.63092 9.50559 5.42929 9.99992 5.42929C10.9593 5.42929 11.737 6.18869 11.737 7.12544C11.737 7.50759 11.6076 7.86023 11.3892 8.14382L13.9787 5.20256Z"
          fill="#4285F5"
        />
        <path
          d="M8.00159 11.9914C7.98444 11.9719 7.96711 11.9522 7.94964 11.9323C7.30762 11.2029 6.45637 10.2358 5.95068 9.12615L8.62474 6.08887C8.39785 6.37549 8.26285 6.73497 8.26285 7.12527C8.26285 8.06203 9.04056 8.82142 9.99991 8.82142C10.4859 8.82142 10.9252 8.62655 11.2405 8.31249L8.00159 11.9914Z"
          fill="#F9BB0E"
        />
        <path
          d="M6.58047 4.38867C5.91925 5.15092 5.52051 6.13715 5.52051 7.21433C5.52051 7.23231 5.52062 7.25027 5.52084 7.26821C5.52062 7.27991 5.52051 7.2917 5.52051 7.3036C5.52051 7.94036 5.6896 8.5522 5.95107 9.12594L8.62061 6.09378L6.58047 4.38867Z"
          fill="#E74335"
        />
        <path
          d="M11.3164 3.03166C10.9003 2.90697 10.4584 2.83984 10.0004 2.83984C8.62942 2.83984 7.4023 3.44118 6.58057 4.38847L8.62071 6.09355L8.62515 6.08852C8.66226 6.04164 8.70182 5.99669 8.74366 5.95389L11.3164 3.03166Z"
          fill="#1A73E6"
        />
      </g>
    </svg>
  );
}
