import classNames from "classnames";
import CloseSmIcon from "../common/svg/CloseSm";
import StyledTabs, { TabType } from "../common/tabs/StyledTabs";
import { useEffect, useRef, useState } from "react";
import StyledButton from "../common/button/StyledButton";
import { SvgDirection } from "../common/svg/types";
import ArrowIcon from "../common/svg/Arrow";
import ChevronIcon from "../common/svg/Chevron";
import FilterControlOptionGroup from "./FilterControlOptionGroup";
import React from "react";
import { useOverlayContext } from "../../context/OverlayContext";
import {
  FilterControlProps,
  FilterControlOptionGroupProps,
  FilterControlValues,
  FilterControlValue,
} from "./types";

export default function FilterControl(props: FilterControlProps) {
  const {
    label,
    filters,
    categories,
    initialValues,
    hideClearButton,
    reference,
    defaultSortMethod,
    onClose,
    onSaveChanges,
    classes,
  }: FilterControlProps = props;

  const { isOverlayOpen } = useOverlayContext();

  const [tabValue, setTabValue] = useState<string | null>(null);
  const [currentFilter, setCurrentFilter] = useState<Omit<
    FilterControlOptionGroupProps,
    "selectedValues" | "onChangeValues"
  > | null>(null);
  const [currentValues, setCurrentValues] = useState<FilterControlValues[]>(
    initialValues || []
  );

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // on load, set the first tab as active
    if (categories && categories.length > 0) {
      setTabValue(categories[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Transition the content's height smoothly on inner content change
    const container = containerRef.current;
    if (container) {
      const previousHeight = container.scrollHeight;
      container.style.height = "100%";
      container.style.maxHeight = "auto";
      const newHeight = container.scrollHeight;
      const containerTransition = container.style.transition;
      container.style.transition = "";

      requestAnimationFrame(() => {
        container.style.height = previousHeight + "px";
        container.style.maxHeight = previousHeight + "px";
        container.style.transition = containerTransition;
        requestAnimationFrame(function () {
          container.style.height = newHeight + "px";
          container.style.maxHeight = newHeight + "px";
        });
      });
    }
  }, [tabValue, currentFilter]);

  const updateCurrentValues = (
    selectedFilter: Omit<
      FilterControlOptionGroupProps,
      "selectedValues" | "onChangeValues"
    >,
    newValues: FilterControlValue[]
  ) => {
    let allValues: FilterControlValues[] = structuredClone(currentValues);

    // Find the object containing the selected filter's values
    const indexOfSelectedFilter = allValues.findIndex(
      (filter) => filter.name === selectedFilter.name
    );

    if (indexOfSelectedFilter === -1) {
      // If no object contains values for the selected filter, add it
      allValues.push({ name: selectedFilter.name, values: newValues });
    } else {
      // Otherwise update the values
      allValues[indexOfSelectedFilter].values = newValues;
    }
    // Logic specific to "Sort By" category.
    // Removes values from other 'sortBy' options
    if (selectedFilter.category === "sortBy") {
      // Get all filter names that have category 'sortBy' and aren't the one currently being changed
      const otherSortByFilterNames =
        filters &&
        filters
          .filter(
            (filter) =>
              filter.category === "sortBy" &&
              filter.name !== selectedFilter.name
          )
          .map((filter) => filter.name);

      // Filter out all other 'sortBy' filter values
      const filteredResults = allValues.filter((filter) => {
        return (
          otherSortByFilterNames &&
          !otherSortByFilterNames.some(
            (filterName) => filter.name === filterName
          )
        );
      });

      setCurrentValues(filteredResults);
    } else {
      setCurrentValues(allValues);
    }
  };

  const clearFilters = () => {
    if (filters) {
      // get the 'name' property of a filters with the 'sortBy' category
      const allSortByFilterNames = filters
        .filter((filter) => filter.category === "sortBy")
        .map((filter) => filter.name);
      if (defaultSortMethod) {
        // If a default sort method is passed, clearing filters will reset to this value
        const defaultSortMethodName = defaultSortMethod.replace("-", "");
        const defaultSortMethodObject: FilterControlValues = {
          name: defaultSortMethodName,
          values: [{ label: "", value: defaultSortMethod }],
        };
        setCurrentValues([defaultSortMethodObject]);
      } else {
        // If there's no default sort method provided, filter out all filter values that aren't in the list above
        const clearedValuesExceptSortBy = currentValues.filter((value) =>
          allSortByFilterNames.includes(value.name)
        );
        setCurrentValues(clearedValuesExceptSortBy);
      }
    }
  };

  const FilterCategoryTabs = () => {
    if (categories && categories.length > 0) {
      let currentTabIndex = 0;
      if (tabValue) {
        currentTabIndex = categories
          .map((category) => category.value)
          .indexOf(tabValue);
      }
      const allTabLabels = categories.map((category) => category.label);

      return (
        <StyledTabs
          tabType={TabType.Underline}
          labels={allTabLabels}
          extraClasses="border-b border-b-gray-100"
          value={currentTabIndex}
          onChange={(_, newValue) => {
            setTabValue(categories[newValue].value);
          }}
        />
      );
    } else {
      return null;
    }
  };

  const FilterButton = (
    optionGroup: Omit<
      FilterControlOptionGroupProps,
      "selectedValues" | "onChangeValues"
    >
  ) => {
    const filterValues = currentValues.find(
      (group) => group.name === optionGroup.name
    );
    const singleValueLabel = optionGroup.options.find((option) => {
      if (filterValues && filterValues.values.length > 0) {
        return option.value === filterValues?.values[0].value;
      } else return null;
    })?.label;
    const CurrentFilterValuesText = () => {
      if (!filterValues || filterValues?.values.length <= 0) {
        return "Not set";
      }
      if (filterValues?.values.length === 1) {
        return singleValueLabel;
      }
      if (filterValues?.values.length >= 2) {
        return `${filterValues.values.length} selected`;
      }
    };

    return (
      <li key={optionGroup.name}>
        <button
          className="filter-control__filter-button"
          onClick={(e) => {
            e.stopPropagation();
            setCurrentFilter(optionGroup);
          }}
        >
          <div className="filter-control__filter-button-label">
            {optionGroup.label}
          </div>
          <div
            className={classNames("filter-control__filter-button-value", {
              "filter-control__filter-button-value--active":
                filterValues && filterValues.values.length > 0,
            })}
          >
            {CurrentFilterValuesText()}
            <ChevronIcon
              width="16"
              height="16"
              direction={SvgDirection.Right}
            />
          </div>
        </button>
      </li>
    );
  };

  return (
    <div
      className={classNames(
        "filter-control",
        { "filter-control--no-overlay": !isOverlayOpen },
        classes
      )}
      ref={reference}
    >
      <div className="filter-control__top-section">
        <div className="flex flex-col w-full">
          {currentFilter && (
            <h2 className="text-lg font-semibold">{currentFilter.label}</h2>
          )}
          {label && !currentFilter && (
            <h2 className="text-lg font-semibold">{label}</h2>
          )}
          {!currentFilter && FilterCategoryTabs()}
        </div>
        <div className="flex gap-8">
          {currentFilter && (
            <StyledButton
              color="secondary"
              iconOnly
              size="sm"
              outlined
              onClick={(e) => {
                e.stopPropagation();
                setCurrentFilter(null);
              }}
              classes="filter-control__icon-button"
            >
              <span className="sr-only">Back to all filters</span>
              <ArrowIcon width="18" height="18" direction={SvgDirection.Left} />
            </StyledButton>
          )}
          <StyledButton
            color="secondary"
            iconOnly
            size="sm"
            transparent
            onClick={(e) => {
              e.stopPropagation();
              onClose && onClose();
            }}
            classes="filter-control__icon-button my-px"
          >
            <span className="sr-only">Close filter control</span>
            <CloseSmIcon width="18" height="18" />
          </StyledButton>
        </div>
      </div>
      <div ref={containerRef} className="filter-control__middle-section">
        {!currentFilter && (
          <ul className="filter-control__option-group-buttons">
            {filters &&
              filters.map((filter) => {
                if (
                  categories &&
                  categories.length > 0 &&
                  filter.category !== tabValue
                ) {
                  return null;
                }
                return FilterButton(filter);
              })}
          </ul>
        )}
        {currentFilter && (
          <FilterControlOptionGroup
            {...currentFilter}
            selectedValues={
              currentValues.find((group) => group.name === currentFilter.name)
                ?.values || []
            }
            onChangeValues={(newValues) => {
              updateCurrentValues(currentFilter, newValues);
            }}
          />
        )}
      </div>
      <div className="filter-control__bottom-section">
        {currentFilter ? (
          <StyledButton
            onClick={() => setCurrentFilter(null)}
            color="primary"
            classes="filter-control__bottom-button"
          >
            Done
          </StyledButton>
        ) : (
          <React.Fragment>
            <StyledButton
              color="primary"
              classes="filter-control__bottom-button"
              onClick={() => onSaveChanges && onSaveChanges(currentValues)}
            >
              Save changes
            </StyledButton>
            {!hideClearButton && (
              <StyledButton
                color="secondary"
                outlined
                classes="filter-control__bottom-button"
                onClick={() => clearFilters()}
              >
                Clear all
              </StyledButton>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
