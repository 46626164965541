import { Navigate, useParams } from "react-router-dom";

export type LiveTrackingRedirectProps = {};

export default function LiveTrackingRedirect(props: LiveTrackingRedirectProps) {
  const { id } = useParams();

  if (id) {
    return <Navigate to={`/live-tracking/?view_trip=${id}`} />;
  } else {
    return <Navigate to={"/live-tracking/"} />;
  }
}
