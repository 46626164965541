import { Button, ButtonProps } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";

export type StyledButtonProps = Omit<
  ButtonProps,
  "size" | "variant" | "color" | "type"
> & {
  size?: "2xl" | "xl" | "lg" | "md" | "sm" | "xs";
  color?: "primary" | "secondary" | "destructive";
  outlined?: boolean;
  transparent?: boolean;
  textOnly?: boolean;
  dotLeading?: boolean;
  dotColor?: string;
  rounded?: boolean;
  iconOnly?: boolean;
  isSubmit?: boolean;
  disabled?: boolean;
  classes?: string;
  testId?: string;
};

export default function StyledButton(props: StyledButtonProps) {
  const {
    size = "lg",
    color = "primary",
    outlined,
    transparent,
    textOnly,
    dotLeading,
    dotColor,
    rounded,
    iconOnly,
    isSubmit,
    onClick,
    disabled,
    sx,
    testId,
    classes,
    ...otherProps
  }: StyledButtonProps = props;

  const [focusRingActive, setFocusRingActive] = useState<boolean>(false);
  const showFocusRing = !disabled && !textOnly && !transparent;

  const triggerFocusRing = () => {
    if (!focusRingActive) {
      setFocusRingActive(true);
    } else {
      // toggle active class off and then back on
      setFocusRingActive(false);
      setTimeout(() => {
        setFocusRingActive(true);
      }, 5);
    }
  };

  return (
    <Button
      variant={outlined ? "outlined" : textOnly ? "text" : "contained"}
      data-testid={testId}
      className={classNames(
        "button",
        `button--${size}`,
        `button--${color}`,
        classes,
        showFocusRing ? `focus-ring focus-ring--${color}` : "",
        {
          "button--outlined": outlined,
          "button--transparent": transparent,
          "button--text-only": textOnly,
          "button--dot-leading": dotLeading,
          "button--rounded": rounded,
          "button--icon-only": iconOnly,
          "focus-ring--active": showFocusRing && focusRingActive,
        }
      )}
      {...otherProps}
      onClick={
        isSubmit
          ? (e) => {
              triggerFocusRing();
              // if (formik) formik.submitForm();
              if (onClick) onClick(e);
            }
          : (e) => {
              triggerFocusRing();
              if (onClick) {
                onClick(e);
              }
            }
      }
      sx={{
        borderRadius:
          // Adds sharp corner to main primary button
          color === "primary" && !outlined && !iconOnly && !textOnly
            ? "1rem 1rem 1rem 0"
            : "1rem",
      }}
      disabled={
        // TODO: Add this back in once formik is being used
        // disabled !== undefined ? !formik?.isValid || disabled : disabled
        disabled
      }
      type={isSubmit ? "submit" : "button"}
      disableFocusRipple
      disableRipple
      disableElevation
    />
  );
}
