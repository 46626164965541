import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
} from "@mui/material";
import classNames from "classnames";
import StyledRadioButton, { RadioButtonSize } from "./StyledRadioButton";
import { ChangeEvent } from "react";

export interface RadioGroupOption {
  value: string;
  label?: string;
  disabled?: boolean;
}

export interface StyledRadioGroupProps {
  value?: string;
  groupId: string;
  groupName: string;
  options: RadioGroupOption[];
  required?: boolean;
  heading?: string;
  defaultValue?: string;
  radioButtonSize?: RadioButtonSize;
  classes?: string;
  rootClasses?: string;
  itemsClasses?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
}

export default function StyledRadioGroup(props: StyledRadioGroupProps) {
  const {
    value,
    groupId,
    groupName,
    defaultValue,
    options,
    required,
    heading,
    radioButtonSize = RadioButtonSize.Sm,
    onChange,
    classes,
    rootClasses,
    itemsClasses,
  } = props;

  return (
    <FormControl
      classes={{ root: classNames("radio-group-container", classes) }}
    >
      <FormLabel
        id={groupId}
        classes={{
          root: "radio-group__heading text-md text-black font-medium mb-2",
          asterisk: "radio-group__label-asterisk text-error-600",
        }}
        required={required}
      >
        {heading}
      </FormLabel>
      <RadioGroup
        aria-labelledby={groupId}
        defaultValue={defaultValue}
        name={groupName}
        classes={{ root: classNames("radio-group ml-5", rootClasses) }}
        onChange={onChange}
        value={value}
      >
        {options.map((item, index) => (
          <FormControlLabel
            key={index}
            value={item.value}
            control={<StyledRadioButton radioButtonSize={radioButtonSize} />}
            label={item.label}
            disabled={item.disabled}
            classes={{
              root: classNames(
                "radio-group__label-container mb-1",
                itemsClasses
              ),
              label: classNames("radio-group__label", {
                "text-sm ml-4": radioButtonSize === RadioButtonSize.Sm,
                "text-md ml-6": radioButtonSize === RadioButtonSize.Md,
              }),
            }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
