import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps, SvgType } from "./types";

export default function TruckIcon(props: SvgProps) {
  const {
    type = SvgType.Line,
    width = "24",
    height = "24",
    fill = allColors.black,
    stroke = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__truck", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      {type === SvgType.Solid ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8385 3H5.16146C4.63433 2.99998 4.17954 2.99997 3.80497 3.03057C3.40963 3.06287 3.01641 3.13419 2.63803 3.32698C2.07354 3.6146 1.6146 4.07354 1.32698 4.63803C1.13419 5.01641 1.06287 5.40963 1.03057 5.80497C0.999969 6.17954 0.999984 6.63428 1 7.16142V15C1 16.3406 1.87937 17.4759 3.09276 17.8604C3.48636 19.656 5.08622 21 7 21C8.86384 21 10.4299 19.7252 10.874 18H13.9804L14 18.0002H14.5355C14.7782 19.6962 16.2368 21 18 21C19.7841 21 21.2565 19.665 21.4727 17.9395C21.5673 17.9185 21.6656 17.8893 21.7654 17.848C22.2554 17.645 22.6448 17.2556 22.8478 16.7656C22.9447 16.5315 22.9752 16.3058 22.9882 16.1154C23 15.9413 23 15.7367 23 15.5267L23 12.6629L23.0001 12.6119C23.0006 12.422 23.0012 12.1828 22.9447 11.9475C22.8957 11.7434 22.8149 11.5484 22.7053 11.3694C22.5788 11.1631 22.4093 10.9944 22.2746 10.8605L22.2385 10.8245L18.1757 6.76171L18.1397 6.72556C18.0058 6.59091 17.8371 6.42137 17.6308 6.29491C17.4518 6.18525 17.2568 6.10445 17.0527 6.05545C16.8173 5.99895 16.5782 5.99959 16.3883 6.0001L16.3373 6.00019H14.9824C14.9788 5.93289 14.9746 5.8678 14.9694 5.80497C14.9371 5.40963 14.8658 5.01641 14.673 4.63803C14.3854 4.07354 13.9265 3.6146 13.362 3.32698C12.9836 3.13419 12.5904 3.06287 12.195 3.03057C11.8205 2.99997 11.3657 2.99998 10.8385 3ZM20.9997 15.6957C21 15.6394 21 15.5749 21 15.5002V12.6629C21 12.5319 20.9998 12.4666 20.9976 12.4191L20.9974 12.4155L20.995 12.4128C20.963 12.3777 20.9169 12.3313 20.8243 12.2387L16.7615 8.17593C16.6689 8.0833 16.6225 8.03723 16.5874 8.00519L16.5847 8.00277L16.5811 8.0026C16.5336 8.00043 16.4683 8.00019 16.3373 8.00019H15V15.6962C15.6125 14.6798 16.7268 14 18 14C19.273 14 20.3872 14.6796 20.9997 15.6957ZM7 15C5.89543 15 5 15.8954 5 17C5 18.1046 5.89543 19 7 19C8.10457 19 9 18.1046 9 17C9 15.8954 8.10457 15 7 15ZM16.5 17.5C16.5 16.6716 17.1716 16 18 16C18.8284 16 19.5 16.6716 19.5 17.5C19.5 18.3284 18.8284 19 18 19C17.1716 19 16.5 18.3284 16.5 17.5Z"
          fill={fill}
        />
      ) : (
        <path
          d="M14 7H16.3373C16.5818 7 16.7041 7 16.8192 7.02763C16.9213 7.05213 17.0188 7.09253 17.1083 7.14736C17.2092 7.2092 17.2957 7.29568 17.4686 7.46863L21.5314 11.5314C21.7043 11.7043 21.7908 11.7908 21.8526 11.8917C21.9075 11.9812 21.9479 12.0787 21.9724 12.1808C22 12.2959 22 12.4182 22 12.6627V15.5C22 15.9659 22 16.1989 21.9239 16.3827C21.8224 16.6277 21.6277 16.8224 21.3827 16.9239C21.1989 17 20.9659 17 20.5 17M15.5 17H14M14 17V7.2C14 6.0799 14 5.51984 13.782 5.09202C13.5903 4.71569 13.2843 4.40973 12.908 4.21799C12.4802 4 11.9201 4 10.8 4H5.2C4.0799 4 3.51984 4 3.09202 4.21799C2.71569 4.40973 2.40973 4.71569 2.21799 5.09202C2 5.51984 2 6.0799 2 7.2V15C2 16.1046 2.89543 17 4 17M14 17H10M10 17C10 18.6569 8.65685 20 7 20C5.34315 20 4 18.6569 4 17M10 17C10 15.3431 8.65685 14 7 14C5.34315 14 4 15.3431 4 17M20.5 17.5C20.5 18.8807 19.3807 20 18 20C16.6193 20 15.5 18.8807 15.5 17.5C15.5 16.1193 16.6193 15 18 15C19.3807 15 20.5 16.1193 20.5 17.5Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}
