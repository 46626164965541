import { AutocompleteItem, MultiAutocompleteProps } from "../types";
import MultiAutocomplete from "../MultiAutocomplete";
import { StopEventStatus, StopSequenceStatus } from "../../../../types/trip";
import classNames from "classnames";

export type StopStatusFilterProps = Omit<MultiAutocompleteProps, "options">;

export const stopStatusOptions: AutocompleteItem[] = [
  { label: "Awaiting AV", value: StopEventStatus.AwaitingAV },
  { label: "Arrived", value: StopEventStatus.AVArrived },
  { label: "Departed", value: StopEventStatus.AVDeparted },
  { label: "Skipped", value: StopSequenceStatus.Skipped },
];

const StopStatusFilter = (props: StopStatusFilterProps) => {
  const { ...otherProps } = props;

  return (
    <MultiAutocomplete
      label="Stop status"
      options={stopStatusOptions}
      extraClasses={classNames("stop-status-filter", otherProps.extraClasses)}
      {...otherProps}
    />
  );
};

export default StopStatusFilter;
