import { Input, InputProps } from "@mui/material";
import { allColors } from "../../../util/colors";
import classNames from "classnames";
import { ConditionalWrapper } from "../../../util/util";
import AlertCircleIcon from "../../common/svg/AlertCircle";

export interface StyledInputProps extends Omit<InputProps, "error" | "size"> {
  label?: string;
  labelClasses?: string;
  numeric?: boolean;
  defaultValue?: string;
  placeholder?: string;
  startIcon?: React.ReactElement;
  endIcon?: React.ReactElement;
  error?: string;
  errorText?: string;
  size?: "md" | "lg";
  hideFocusRing?: boolean;
  extraClasses?: string;
}

export default function StyledInput(props: StyledInputProps) {
  const {
    label,
    labelClasses,
    numeric,
    defaultValue,
    placeholder,
    startIcon,
    endIcon,
    error,
    errorText,
    extraClasses,
    size = "md",
    hideFocusRing,
    ...otherProps
  } = props;

  const isErrored = error !== undefined && error !== "";

  return (
    <ConditionalWrapper
      condition={label !== undefined}
      wrapper={(children: any) => {
        return (
          <label
            className={classNames("input__label", labelClasses)}
            htmlFor={otherProps.name}
          >
            <span>{label}</span>
            {children}
          </label>
        );
      }}
    >
      <Input
        {...otherProps}
        id={otherProps.name}
        classes={{
          root: classNames(
            "input",
            { "input--error": isErrored },
            { "input--md": size === "md", "input--lg": size === "lg" },
            { "input--hide-focus-ring": hideFocusRing },
            extraClasses
          ),
          input: "input__input",
          focused: "input--focused",
          disabled: "input--disabled",
        }}
        disableUnderline
        defaultValue={defaultValue}
        inputMode={numeric ? "numeric" : undefined}
        inputProps={{
          placeholder: placeholder ? placeholder : undefined,
          pattern: numeric ? "[0-9]*" : undefined,
        }}
        error={isErrored}
        startAdornment={startIcon}
        endAdornment={
          <>
            {isErrored && <AlertCircleIcon stroke={allColors.error[600]} />}
            {endIcon}
          </>
        }
      />
      {error && <p className="input__error">{error}</p>}
    </ConditionalWrapper>
  );
}
