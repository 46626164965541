import { useSyncExternalStore } from "react";
import { UserInfo } from "../types/common";

export const useLocalStorage = () => {
  const setValue = (userInfo: UserInfo | null) => {
    const newValue = JSON.stringify(userInfo);

    if (userInfo === null) {
      localStorage.removeItem("user");
      // TODO: Remove this once Ops Portal is integrated into a single app.
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
    } else {
      localStorage.setItem("user", JSON.stringify(userInfo));
    }
    window.dispatchEvent(
      new StorageEvent("storage", { key: "user", newValue })
    );
  };

  const getSnapshot = () => {
    return localStorage.getItem("user");
  };

  const subscribe = (listener: () => void) => {
    window.addEventListener("storage", listener);
    return () => {
      window.removeEventListener("storage", listener);
    };
  };

  const store = useSyncExternalStore(subscribe, getSnapshot);

  return [store, setValue] as const;
};
