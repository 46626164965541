import { AutocompleteItem, MultiAutocompleteProps } from "../types";
import MultiAutocomplete from "../MultiAutocomplete";
import { TripStatus } from "../../../../types/trip";
import classNames from "classnames";

export type TripStatusFilterProps = Omit<MultiAutocompleteProps, "options">;

export const tripStatusOptions: AutocompleteItem[] = [
  { label: "Upcoming", value: TripStatus.Scheduled },
  { label: "In Progress", value: TripStatus.InProgress },
  { label: "Completed", value: TripStatus.Completed },
  { label: "Cancelled", value: TripStatus.Cancelled },
  { label: "Timed Out", value: TripStatus.TrackingTimeout },
];

const TripStatusFilter = (props: TripStatusFilterProps) => {
  const { ...otherProps } = props;

  return (
    <MultiAutocomplete
      label="Trip status"
      options={tripStatusOptions}
      extraClasses={classNames("trip-status-filter", otherProps.extraClasses)}
      {...otherProps}
    />
  );
};

export default TripStatusFilter;
