import classNames from "classnames";
import StyledCheckbox from "../form/checkbox/StyledCheckbox";
import StyledRadioButton from "../form/radio-button/StyledRadioButton";
import { FilterControlFilterOption } from "./types";

export default function FilterControlOption(props: FilterControlFilterOption) {
  const {
    label,
    value,
    checked,
    singleSelect,
    onChange,
  }: FilterControlFilterOption = props;

  return (
    <li
      className={classNames("filter-control-option", {
        "filter-control-option--checked": checked,
      })}
    >
      <button
        className="filter-control-option__button"
        onClick={() => onChange && onChange({ label: label, value: value })}
      >
        <div className="filter-control-option__label">{label}</div>
        {singleSelect ? (
          <StyledRadioButton checked={checked} />
        ) : (
          <StyledCheckbox checked={checked} />
        )}
      </button>
    </li>
  );
}
