import { Radio, RadioProps } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";

export enum RadioButtonSize {
  Sm = "small",
  Md = "medium",
}

export interface StyledRadioButtonProps extends RadioProps {
  radioButtonSize?: RadioButtonSize;
}

export default function StyledRadioButton(props: StyledRadioButtonProps) {
  const {
    radioButtonSize = RadioButtonSize.Sm,
    checked,
    onChange,
    ...otherProps
  } = props;

  const [focusRingActive, setFocusRingActive] = useState<boolean>(false);
  const showFocusRing = !otherProps.disabled;

  const RadioButtonIcon = () => {
    return <div className="radio-button__icon"></div>;
  };

  const triggerFocusRing = () => {
    if (!focusRingActive) {
      setFocusRingActive(true);
    } else {
      // toggle active class off and then back on
      setFocusRingActive(false);
      setTimeout(() => {
        setFocusRingActive(true);
      }, 5);
    }
  };

  return (
    <Radio
      {...otherProps}
      icon={RadioButtonIcon()}
      checkedIcon={RadioButtonIcon()}
      classes={{
        root: classNames("radio-button", {
          "radio-button--sm": radioButtonSize === RadioButtonSize.Sm,
          "radio-button--md": radioButtonSize === RadioButtonSize.Md,
          "focus-ring focus-ring--primary": showFocusRing,
          "focus-ring--active": showFocusRing && focusRingActive,
        }),
        checked: "radio-button--checked",
        disabled: "radio-button--disabled",
      }}
      disableRipple
      onChange={(e) => {
        triggerFocusRing();
        if (onChange) {
          onChange(e, checked && checked !== undefined ? checked : false);
        }
      }}
    />
  );
}
