import classNames from "classnames";
import Logo, { LogoType } from "../../common/logo/Logo";
import ProfileInfo from "../../common/profile-info/ProfileInfo";
import LogOutIcon from "../../common/svg/LogOut";
import NavigationMenu, {
  NavigationMenuProps,
} from "../navigation-menu/NavigationMenu";
import StyledTooltip from "../../common/tooltip/StyledTooltip";
import { useEffect, useState } from "react";
import ProfileImage from "../../common/profile-image/ProfileImage";
import ActionMenu from "../../common/action-menu/ActionMenu";
import {
  useSmBreakpoint,
  useMdBreakpoint,
  useXlBreakpoint,
} from "../../../util/breakpoints";
import { useUserContext } from "../../../context/UserContext";
import { useOverlayContext } from "../../../context/OverlayContext";
import CloseLgIcon from "../../common/svg/CloseLg";
import { allColors } from "../../../util/colors";
import { useLocalStorage } from "../../../util/local-storage";

export type SideNavigationProps = {
  topMenu?: NavigationMenuProps;
  bottomMenu?: NavigationMenuProps;
  bottomContent?: JSX.Element;
  isHidden?: boolean;
  isMobileNavigation?: boolean;
  onCloseMobileNav?: () => void;
  isMinimizedByDefault?: boolean;
  currentPath?: string;
  classes?: string;
};

export default function SideNavigation(props: SideNavigationProps) {
  const {
    topMenu,
    bottomContent,
    bottomMenu,
    isMobileNavigation,
    onCloseMobileNav,
    currentPath,
    classes,
  }: SideNavigationProps = props;

  const isSm = useSmBreakpoint();
  const isMd = useMdBreakpoint();
  const isXl = useXlBreakpoint();
  const { userInfo } = useUserContext();
  const { isOverlayOpen } = useOverlayContext();
  const [, setLocalStorageValue] = useLocalStorage();

  const [isMinimized, setIsMinimized] = useState<boolean>(!isXl);

  useEffect(() => {
    // when mobile side nav is open and the user expands to desktop, close the menu
    if (isSm && isMobileNavigation && onCloseMobileNav) {
      onCloseMobileNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSm]);

  useEffect(() => {
    setIsMinimized(true);
  }, [isMd]);

  useEffect(() => {
    setIsMinimized(!isXl);
  }, [isXl]);

  return (
    <nav
      className={classNames(
        "side-navigation",
        {
          "side-navigation--minimized": isMinimized && !isMobileNavigation,
          "side-navigation--mobile": isMobileNavigation,
          "side-navigation--hidden": isMobileNavigation && !isOverlayOpen,
        },
        classes
      )}
    >
      <div className="side-navigation__content">
        <div className="side-navigation__top-section">
          {!isMobileNavigation && (
            <button className="side-navigation__logo-button">
              <Logo
                type={isMinimized ? LogoType.Symbol : LogoType.Full}
                classes="side-navigation__logo"
              />
              <span className="sr-only">Gatik Omni home</span>
            </button>
          )}
          {topMenu && (
            <NavigationMenu
              {...topMenu}
              currentPath={currentPath}
              isMinimized={isMinimized && !isMobileNavigation}
            />
          )}
        </div>
        <div className="side-navigation__bottom-section">
          {bottomMenu && (
            <NavigationMenu
              {...bottomMenu}
              currentPath={currentPath}
              isMinimized={isMinimized && !isMobileNavigation}
            />
          )}
          {(!isMinimized || isMobileNavigation) && bottomContent}
          {isMinimized && !isMobileNavigation ? (
            <ActionMenu
              buttonId={`side-nav-profile-button`}
              menuId={`side-nav-profile-menu`}
              buttonContent={<ProfileImage userInfo={userInfo} />}
              items={[
                {
                  content: <ProfileInfo userInfo={userInfo} />,
                  bottomDivider: true,
                },
                {
                  endIcon: <LogOutIcon />,
                  content: "Log out",
                  onClick: () => {
                    setLocalStorageValue(null);
                    // TODO: Remove this once Ops Portal is integrated into a single app.
                    localStorage.removeItem("access_token");
                    localStorage.removeItem("refresh_token");
                  },
                },
              ]}
              menuClasses="side-navigation__profile-menu"
              containerClasses="side-navigation__profile-menu-container"
            />
          ) : (
            <div className="side-navigation__profile-section">
              <ProfileInfo
                userInfo={userInfo}
                classes="side-navigation__profile-info ml-4"
              />
              <button
                className="p-4 rounded-lg focus-ring focus-ring--secondary focus-ring--without-shadow"
                onClick={() => {
                  setLocalStorageValue(null);
                  // TODO: Remove this once Ops Portal is integrated into a single app.
                  localStorage.removeItem("access_token");
                  localStorage.removeItem("refresh_token");
                }}
              >
                <LogOutIcon />
                <span className="sr-only">Log out</span>
              </button>
            </div>
          )}
        </div>
      </div>
      {!isMobileNavigation && isMd && (
        <StyledTooltip
          header={isMinimized ? "Expand side nav" : "Collapse side nav"}
          placement={"right"}
          followCursor
          enterDelay={1000}
        >
          <button
            className="side-navigation__expand-collapse-button"
            onClick={() => setIsMinimized(!isMinimized)}
          >
            <span className="sr-only">
              {isMinimized ? "Expand side nav" : "Collapse side nav"}
            </span>
          </button>
        </StyledTooltip>
      )}
      {isMobileNavigation && (
        <button
          className="side-navigation__close-button"
          onClick={onCloseMobileNav}
        >
          <span className="sr-only">Close navigation menu</span>
          <CloseLgIcon
            classes="side-navigation__close-icon"
            stroke={allColors.white}
          />
        </button>
      )}
    </nav>
  );
}
