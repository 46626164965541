import { Navigate, useParams } from "react-router-dom";

export type ResetPasswordRedirectProps = {};

export default function ResetPasswordRedirect(
  props: ResetPasswordRedirectProps
) {
  const { signature } = useParams();

  if (signature) {
    return <Navigate to={`/reset-password/${signature}`} />;
  } else {
    return <Navigate to={"/reset-password/"} />;
  }
}
