import classNames from "classnames";
import SupportModalIcon from "./SupportModalIcon";
import { useEffect, useState } from "react";
import StyledButton from "../common/button/StyledButton";
import MailIcon from "../common/svg/Mail";
import { allColors } from "../../util/colors";
import {
  GATIK_PHONE_NUMBER,
  GATIK_PHONE_NUMBER_TEXT,
} from "../../util/constants";
import { SvgType } from "../common/svg/types";
import PhoneCallIcon from "../common/svg/PhoneCall";
import StyledTooltip from "../common/tooltip/StyledTooltip";
import { isMobileDevice } from "../../util/util";
import Modal from "./Modal";

export type SupportModalProps = {
  onClose?: () => void;
  classes?: string;
};

export default function SupportModal(props: SupportModalProps) {
  const { onClose, classes } = props;

  const [justCopiedEmail, setJustCopiedEmail] = useState<boolean>(false);
  const [justCopiedPhone, setJustCopiedPhone] = useState<boolean>(false);

  useEffect(() => {
    if (justCopiedEmail) {
      // "Email copied to clipboard!" text stays on the screen for the duration of this setTimeout
      setTimeout(() => {
        setJustCopiedEmail(false);
      }, 1500);
    }
  }, [justCopiedEmail]);

  useEffect(() => {
    if (justCopiedPhone) {
      // "Phone number copied to clipboard!" text stays on the screen for the duration of this setTimeout
      setTimeout(() => {
        setJustCopiedPhone(false);
      }, 1500);
    }
  }, [justCopiedPhone]);

  return (
    <Modal onClose={onClose} classes={classNames("support-modal", classes)}>
      <div className="support-modal__image-container">
        <SupportModalIcon />
      </div>
      <div className="text-center mb-24 mt-16">
        <h1 className="text-xl sm:text-display-xs sm:text-display-sm font-semibold mb-2">
          Get Support
        </h1>
        <p className="text-xs sm:text-md text-gray-600 sm:font-medium">
          Have questions or feedback?
        </p>
      </div>
      <StyledTooltip
        open={justCopiedEmail}
        placement={"top"}
        body={`Email address copied to clipboard!`}
      >
        <StyledButton
          size="lg"
          color="primary"
          outlined
          onClick={() => {
            navigator.clipboard.writeText("omni@gatik.ai");
            setJustCopiedEmail(true);
          }}
          classes="w-full"
        >
          <MailIcon
            width="20"
            height="20"
            stroke={allColors.primary[700]}
            classes="mr-4"
          />
          omni@gatik.ai
        </StyledButton>
      </StyledTooltip>
      <StyledTooltip
        open={justCopiedPhone}
        placement={"top"}
        body={`Phone number copied to clipboard!`}
      >
        <StyledButton
          size="lg"
          color="primary"
          href={isMobileDevice ? `tel:${GATIK_PHONE_NUMBER}` : undefined}
          onClick={
            !isMobileDevice
              ? () => {
                  navigator.clipboard.writeText(GATIK_PHONE_NUMBER);
                  setJustCopiedPhone(true);
                }
              : undefined
          }
          classes="w-full mt-4"
        >
          <PhoneCallIcon
            type={SvgType.Solid}
            width="20"
            height="20"
            fill={allColors.white}
            classes="mr-4"
          />
          {GATIK_PHONE_NUMBER_TEXT}
        </StyledButton>
      </StyledTooltip>
    </Modal>
  );
}
