import classNames from "classnames";
import { SvgProps } from "./types";

export default function GoogleIcon(props: SvgProps) {
  const { width = "8", height = "8", classes } = props;

  return (
    <svg
      className={classNames("icon icon__google", classes)}
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2993_2920)">
        <path
          d="M7.92208 4.09208C7.92208 3.82017 7.90004 3.5468 7.85301 3.2793H4.08008V4.81962H6.24066C6.151 5.31641 5.86292 5.75587 5.4411 6.03513V7.03458H6.73009C7.48703 6.3379 7.92208 5.30906 7.92208 4.09208Z"
          fill="#4285F4"
        />
        <path
          d="M4.07999 8.00036C5.15881 8.00036 6.0686 7.64614 6.73147 7.03471L5.44248 6.03526C5.08385 6.27925 4.62087 6.41741 4.08146 6.41741C3.03792 6.41741 2.15311 5.71338 1.83564 4.76685H0.505493V5.79716C1.18453 7.14789 2.56759 8.00036 4.07999 8.00036Z"
          fill="#34A853"
        />
        <path
          d="M1.83426 4.76666C1.6667 4.26987 1.6667 3.73193 1.83426 3.23515V2.20483H0.505579C-0.0617554 3.33509 -0.0617554 4.66671 0.505579 5.79697L1.83426 4.76666Z"
          fill="#FBBC04"
        />
        <path
          d="M4.07999 1.58322C4.65027 1.5744 5.20143 1.78899 5.61444 2.18289L6.75646 1.04087C6.03333 0.361835 5.07356 -0.0114887 4.07999 0.000269555C2.56759 0.000269555 1.18453 0.852741 0.505493 2.20494L1.83417 3.23525C2.15017 2.28724 3.03645 1.58322 4.07999 1.58322Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_2993_2920">
          <rect width="8" height="8" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
