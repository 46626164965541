import classNames from "classnames";
import Badge, { BadgeColor, BadgeSize } from "../../common/badge/Badge";
import { TimelinessStatus } from "../../../types/trip";
import Dot from "../../common/dot";
import { allColors } from "../../../util/colors";

export type StopTimelinessBadgeProps = {
  type?: TimelinessStatus;
  classes?: string;
};

export default function StopTimelinessBadge(props: StopTimelinessBadgeProps) {
  const { type, classes }: StopTimelinessBadgeProps = props;

  return (
    <Badge
      size={BadgeSize.Sm}
      color={
        type === TimelinessStatus.OnTime || type === TimelinessStatus.Early
          ? BadgeColor.Success
          : type === TimelinessStatus.Late
            ? BadgeColor.Warning
            : BadgeColor.Gray
      }
      startIcon={
        <Dot
          size="6px"
          classes="mr-2"
          color={
            type === TimelinessStatus.OnTime || type === TimelinessStatus.Early
              ? allColors.success[500]
              : type === TimelinessStatus.Late
                ? allColors.warning[400]
                : allColors.gray[500]
          }
        />
      }
      rounded
      classes={classNames("timeliness-badge whitespace-nowrap", classes)}
    >
      {type === TimelinessStatus.OnTime
        ? "On Time"
        : type === TimelinessStatus.Early
          ? "Early"
          : type === TimelinessStatus.Late
            ? "Late"
            : "Unknown"}
    </Badge>
  );
}
