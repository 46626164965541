import classNames from "classnames";
import FilterControl from "../../components/filter-control/FilterControl";
import { useMdBreakpoint } from "../../util/breakpoints";
import { timelinessOptions } from "../../components/form/autocomplete/timeliness-filter/TimelinessFilter";
import { stopStatusOptions } from "../../components/form/autocomplete/stop-status-filter/StopStatusFilter";
import {
  FilterControlProps,
  FilterControlOptionGroupProps,
} from "../../components/filter-control/types";
import { StopsSortMethod } from "../../api/stop";

export interface StopsFilterModalProps extends FilterControlProps {}

const sortOptionParams: Omit<
  FilterControlOptionGroupProps,
  "options" | "label" | "name" | "selectedValues" | "onChangeValues"
> = {
  category: "sortBy",
  singleSelect: true,
};

export const stopsFilters: Omit<
  FilterControlOptionGroupProps,
  "selectedValues" | "onChangeValues"
>[] = [
  {
    label: "Status",
    name: "stop_status",
    category: "filter",
    options: stopStatusOptions,
  },
  {
    label: "Timeliness",
    name: "stop_timeliness",
    category: "filter",
    options: timelinessOptions,
  },
  {
    label: "Site Name",
    name: "site_name",
    category: "sortBy",
    singleSelect: true,
    options: [
      { label: "Ascending", value: StopsSortMethod.SiteNameAsc },
      { label: "Descending", value: StopsSortMethod.SiteNameDesc },
    ],
    ...sortOptionParams,
  },
  {
    label: "Trip Name",
    name: "trip_name",
    category: "sortBy",
    singleSelect: true,
    options: [
      { label: "Ascending", value: StopsSortMethod.TripNameAsc },
      { label: "Descending", value: StopsSortMethod.TripNameDesc },
    ],
    ...sortOptionParams,
  },
  {
    label: "AV#",
    name: "av_id",
    category: "sortBy",
    singleSelect: true,
    options: [
      { label: "Ascending", value: StopsSortMethod.AvIdAsc },
      { label: "Descending", value: StopsSortMethod.AvIdDesc },
    ],
    ...sortOptionParams,
  },
  {
    label: "Stop Progress",
    name: "stop_progress",
    category: "sortBy",
    singleSelect: true,
    options: [
      { label: "Ascending", value: StopsSortMethod.StopProgressAsc },
      { label: "Descending", value: StopsSortMethod.StopProgressDesc },
    ],
    ...sortOptionParams,
  },
  {
    label: "Arrived Time",
    name: "arrived_at",
    category: "sortBy",
    singleSelect: true,
    options: [
      { label: "Ascending", value: StopsSortMethod.ArrivedAtAsc },
      { label: "Descending", value: StopsSortMethod.ArrivedAtDesc },
    ],
    ...sortOptionParams,
  },
  {
    label: "Departed Time",
    name: "departed_at",
    category: "sortBy",
    singleSelect: true,
    options: [
      { label: "Ascending", value: StopsSortMethod.DepartedAtAsc },
      { label: "Descending", value: StopsSortMethod.DepartedAtDesc },
    ],
    ...sortOptionParams,
  },
];

export default function StopsFilterModal(props: StopsFilterModalProps) {
  const { ...otherProps } = props;

  const isMd = useMdBreakpoint();

  return (
    <FilterControl
      {...otherProps}
      filters={stopsFilters}
      categories={[
        {
          label: "Filters",
          value: "filter",
        },
        {
          label: "Sort By",
          value: "sortBy",
        },
      ]}
      classes={classNames("live-tracking__filter-control", {
        "live-tracking__filter-control--desktop": isMd,
        "live-tracking__filter-control--mobile": !isMd,
      })}
    />
  );
}
