import classNames from "classnames";
import {
  Stop,
  StopEventStatus,
  StopSequenceStatus,
  TimelinessStatus,
  TripStatus,
} from "../../../../types/trip";
import { useSmBreakpoint } from "../../../../util/breakpoints";
import TripTimelineIcon from "../trip-timeline-icon/TripTimelineIcon";
import StyledButton from "../../../common/button/StyledButton";
import ChevronIcon from "../../../common/svg/Chevron";
import { SvgDirection } from "../../../common/svg/types";
import { allColors } from "../../../../util/colors";
import { useState } from "react";
import {
  getTimezoneCorrectedDate,
  getTimezoneText,
  isDayAfter,
} from "../../../../util/date-util";
import TripTimelineTable from "../trip-timeline-table/TripTimelineTable";
import React from "react";
import Badge, { BadgeColor, BadgeSize } from "../../../common/badge/Badge";
import { useUserContext } from "../../../../context/UserContext";

export type TripTimelineItemProps = {
  stop: Stop;
  stopIndex: number;
  isOrigin?: boolean;
  isFinalDestination?: boolean;
  isHighlighted?: boolean;
  isLastDeparted?: boolean;
  isActiveStop?: boolean;
  isInTransit?: boolean;
  isPreviousStopSkipped?: boolean;
  showEta?: boolean;
  useMyTimezone?: boolean;
  classes?: string;
};

export default function TripTimelineItem(props: TripTimelineItemProps) {
  const {
    stop,
    stopIndex,
    isOrigin,
    isFinalDestination,
    isHighlighted,
    isLastDeparted,
    isActiveStop,
    isInTransit,
    isPreviousStopSkipped,
    showEta,
    useMyTimezone,
    classes,
  } = props;

  const { userInfo } = useUserContext();
  const [showTable, setShowTable] = useState<boolean>(
    isLastDeparted && isInTransit && stop.trip_status !== TripStatus.Completed
      ? true
      : isActiveStop && !isInTransit
        ? true
        : isOrigin && stop.event_status !== StopEventStatus.AVDeparted
          ? true
          : isHighlighted
            ? true
            : false
  );

  const isSm = useSmBreakpoint();

  const isStopTimeDayAfter = (date: string) => {
    return (
      stop.trip_scheduled_start &&
      isDayAfter(stop.trip_scheduled_start, date, stop.timezone)
    );
  };
  const timezoneText = () => {
    return useMyTimezone && userInfo?.timezone
      ? getTimezoneText(userInfo.timezone)
      : stop.timezone
        ? getTimezoneText(stop.timezone)
        : "UTC";
  };

  const getTime = (date: string, timeliness?: TimelinessStatus) => {
    const formattedTime = `${getTimezoneCorrectedDate(
      date,
      useMyTimezone && userInfo?.timezone ? userInfo?.timezone : stop?.timezone
    ).format("h:mm A")} ${timezoneText()}`;
    const dayAfterBadge = isStopTimeDayAfter(date) ? (
      <Badge
        color={
          timeliness && timeliness === TimelinessStatus.Late
            ? BadgeColor.Warning
            : BadgeColor.Success
        }
        size={BadgeSize.Xs}
        rounded
        classes=" ml-2 text-sm"
      >
        +1
      </Badge>
    ) : (
      ""
    );
    return (
      <React.Fragment>
        <span
          className={classNames({
            "text-success-600":
              timeliness === TimelinessStatus.Early ||
              timeliness === TimelinessStatus.OnTime,
            "text-warning-600": timeliness === TimelinessStatus.Late,
          })}
        >
          {formattedTime}
        </span>
        {dayAfterBadge}
      </React.Fragment>
    );
  };

  const infoDivider = () => (
    <div className="trip-timeline-item__info-divider"></div>
  );

  const stopNumberText = () => {
    if (isOrigin) {
      return isSm ? "Origin" : "";
    } else if (isFinalDestination) {
      return isSm ? "Terminus" : "";
    } else {
      return isSm ? `Stop ${stopIndex}` : `${stopIndex}`;
    }
  };

  const expandCollapseButton = () => (
    <StyledButton
      color={showTable ? "primary" : "secondary"}
      size="xs"
      outlined
      iconOnly
      rounded
      classes="rounded-full px-2 ml-4"
      onClick={() => setShowTable(!showTable)}
    >
      <span className="sr-only">
        {showTable ? "Hide additional info" : "Show additional info"}
      </span>
      <ChevronIcon
        direction={showTable ? SvgDirection.Up : SvgDirection.Down}
        width="16"
        height="16"
        stroke={showTable ? allColors.primary[600] : allColors.gray[600]}
      />
    </StyledButton>
  );

  const getTimelinessText = (
    displayedStatus: "Arrived" | "Departed",
    timeliness?: TimelinessStatus
  ) => {
    const timelinessText =
      timeliness === TimelinessStatus.Early
        ? " early"
        : timeliness === TimelinessStatus.OnTime
          ? " on time"
          : timeliness === TimelinessStatus.Late
            ? " late"
            : null;
    return (
      <React.Fragment>
        <span
          className={classNames({
            "text-success-600":
              timeliness === TimelinessStatus.Early ||
              timeliness === TimelinessStatus.OnTime,
            "text-warning-600": timeliness === TimelinessStatus.Late,
          })}
        >
          {displayedStatus}
          {timelinessText}
        </span>
        {infoDivider()}
      </React.Fragment>
    );
  };

  const outOfSequenceInfoText = () => {
    return (
      <React.Fragment>
        <div className="text-warning-600">Out-of-sequence</div>
        {infoDivider()}
      </React.Fragment>
    );
  };

  const stopInfo = () => {
    if (isOrigin && stop.departed_at) {
      return (
        <React.Fragment>
          {getTimelinessText("Departed", stop.departure_status)}
          {getTime(stop.departed_at, stop.departure_status)}
        </React.Fragment>
      );
    } else if (isOrigin && stop.scheduled_departure) {
      return (
        <span className="text-success-600">
          ETA {getTime(stop.scheduled_departure)}
        </span>
      );
    } else if (stop.arrived_at) {
      return (
        <React.Fragment>
          {getTimelinessText("Arrived", stop.arrival_status)}
          {getTime(stop.arrived_at, stop.arrival_status)}
        </React.Fragment>
      );
    } else if (stop.event_status === StopEventStatus.AVDeparted) {
      return <span className="text-gray-500">Departed</span>;
    } else if (stop.sequence_status === StopSequenceStatus.Skipped) {
      return <span>Skipped</span>;
    } else if (stop.trip_status === TripStatus.Cancelled) {
      return <span className="text-error-600">Cancelled</span>;
    } else if (stop.event_status === StopEventStatus.AVArrived) {
      return <span className="text-gray-500">Arrived</span>;
    } else if (stop.eta && showEta) {
      return <span className="text-success-600">ETA {getTime(stop.eta)}</span>;
    } else {
      return null;
    }
  };

  // rounds to one decimal place
  const round = (number: number) => {
    return Math.round(number * 10) / 10;
  };

  const betweenInfo = () => {
    if (stop.event_status === StopEventStatus.AVDeparted) {
      return (
        <React.Fragment>
          <div>
            Travel time{" "}
            <span className="ml-4 font-semibold">
              {stop.elapsed_travel_time ? `${stop.elapsed_travel_time}m` : "--"}
            </span>
          </div>
          {stop.total_distance && stop.total_distance !== 0 && (
            <React.Fragment>
              {infoDivider()}
              <div>
                Distance{" "}
                <span className="ml-4 font-semibold">
                  {round(stop.total_distance)} mi
                </span>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    } else if (isActiveStop) {
      const distanceDriven =
        stop.total_distance && stop.travelled_distance
          ? round(stop.total_distance - stop.travelled_distance)
          : null;
      return (
        <React.Fragment>
          <div>
            Elapsed travel time{" "}
            <span className="ml-4 font-semibold">
              {stop.elapsed_travel_time ? `${stop.elapsed_travel_time}m` : "--"}
            </span>
          </div>
          {distanceDriven &&
            distanceDriven !== 0 &&
            stop.total_distance &&
            stop.total_distance !== 0 && (
              <React.Fragment>
                {infoDivider()}
                <div>
                  Distance{" "}
                  <span className="ml-4 font-semibold">
                    {distanceDriven} / {round(stop.total_distance)} mi
                  </span>
                </div>
              </React.Fragment>
            )}
        </React.Fragment>
      );
    } else if (stop.travel_time) {
      return (
        <div>
          Estimated travel time{" "}
          <span className="ml-4 font-semibold">{stop.travel_time} min</span>
        </div>
      );
    } else {
      return <div>No travel info available</div>;
    }
  };

  return (
    <li
      className={classNames(
        "trip-timeline-item",
        {
          "trip-timeline-item--active":
            isActiveStop &&
            !isFinalDestination &&
            stop.trip_status !== TripStatus.Scheduled,
          "trip-timeline-item--arrived":
            stop.event_status === StopEventStatus.AVArrived,
          "trip-timeline-item--departed":
            stop.event_status === StopEventStatus.AVDeparted,
          "trip-timeline-item--last-departed": isLastDeparted,
          "trip-timeline-item--in-transit": isInTransit,
          "trip-timeline-item--cancelled":
            stop.trip_status === TripStatus.Cancelled,
        },
        classes
      )}
    >
      {!isOrigin && (
        <div className="trip-timeline-item__between-section">
          <div className="trip-timeline-item__stop-number"></div>
          <div className="trip-timeline-item__timeline-icon trip-timeline-item__timeline-icon--between">
            <TripTimelineIcon
              stop={stop}
              timelineIconType="between"
              isOrigin={isOrigin}
              isFinalDestination={isFinalDestination}
              isLastDeparted={isLastDeparted}
              isActiveStop={isActiveStop}
              isInTransit={isInTransit}
              isItemOpen={showTable}
              isPreviousStopSkipped={isPreviousStopSkipped}
            />
          </div>
          <div className="trip-timeline-item__between-content">
            {betweenInfo()}
          </div>
        </div>
      )}
      <div className="trip-timeline-item__stop-section">
        <div className="trip-timeline-item__stop-number">
          {stopNumberText()}
        </div>
        <div className="trip-timeline-item__timeline-icon trip-timeline-item__timeline-icon--stop">
          <TripTimelineIcon
            stop={stop}
            timelineIconType="stop"
            isOrigin={isOrigin}
            isFinalDestination={isFinalDestination}
            isLastDeparted={isLastDeparted}
            isActiveStop={isActiveStop}
            isInTransit={isInTransit}
            isItemOpen={showTable}
            isPreviousStopSkipped={isPreviousStopSkipped}
          />
        </div>
        <div className="trip-timeline-item__stop-content">
          <div className="trip-timeline-item__stop-content-header">
            <div className="mt-1">{stop.site.properties.name}</div>
            {expandCollapseButton()}
          </div>
          {showTable ? (
            <div className="trip-timeline-item__stop-table">
              <TripTimelineTable
                isOrigin={isOrigin}
                stop={stop}
                useMyTimezone={useMyTimezone}
              />
            </div>
          ) : (
            <div className="trip-timeline-item__stop-info">
              {stop.sequence_status === StopSequenceStatus.OutOfSequence &&
                outOfSequenceInfoText()}
              {stopInfo()}
            </div>
          )}
        </div>
      </div>
    </li>
  );
}
