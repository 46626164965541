import { MegaSearchResultItems } from "./types";
import MegaSearchItem from "./MegaSearchItem";
import classNames from "classnames";

export default function MegaSearchGroup(props: MegaSearchResultItems) {
  const {
    count,
    label,
    results,
    searchTerm,
    currentTab,
    classes,
    onClickResult,
  } = props;

  return (
    <div className={classNames("mega-search-group", classes)}>
      <div className="mega-search-group__label">
        {label && (
          <div className="mega-search-group__label-text">
            {label}
            {count && ` (${count})`}
          </div>
        )}
        <div className="mega-search-group__label-line"></div>
      </div>

      <ul className="mega-search-group__items">
        {results &&
          results.map((item, index) => {
            return (
              <MegaSearchItem
                key={index}
                {...item}
                searchTerm={searchTerm}
                currentTab={currentTab}
                onClick={() => onClickResult && onClickResult(item)}
              />
            );
          })}
      </ul>
    </div>
  );
}
