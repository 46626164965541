import { useEffect, useRef, useState } from "react";
import { AutocompleteItem } from "../components/form/autocomplete/types";

// Returns true if the device is a mobile phone.
export const isMobileDevice =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const useAsyncEffect = (func: () => Promise<any>, deps: Array<any>) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  useEffect(() => {
    setLoading(true);
    func()
      .then((data) => {
        setData(data);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return { loading, data };
};

export const checkIsStaff = (role?: string) =>
  role === "superuser" || role === "staff";

export const generateMapLink = (address: string) =>
  `https://www.google.com/maps/search/?api=1&query=${address}`;

interface ConditionalWrapperProps {
  condition?: boolean;
  wrapper: any;
  children: React.ReactNode;
}

export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: ConditionalWrapperProps): JSX.Element => {
  return condition ? wrapper(children) : children;
};

// TODO: This shouldn't be needed, but MUI autocomplete isn't removing options.
// aria-selected is also not being set to true.
// removes all duplicated objects (i.e. double negatives)
export const getNewMultiAutocompleteValues = (values: AutocompleteItem[]) => {
  const newSelectedValues = values.filter((option) => {
    const count = values.reduce(
      (acc, cur) => (cur.value === option.value ? ++acc : acc),
      0
    );
    if (count <= 1) {
      return option;
    } else return null;
  });
  return newSelectedValues;
};

export const useScrollbarWidth = () => {
  const didCompute = useRef(false);
  const widthRef = useRef(0);

  if (didCompute.current) return widthRef.current;

  // Creating invisible container
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll"; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement("div");
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode?.removeChild(outer);

  didCompute.current = true;
  widthRef.current = scrollbarWidth;

  return scrollbarWidth;
};

export const highlight = (
  text: string,
  searchTerm?: string,
  className?: string
) => {
  let newText: string = text;
  if (searchTerm) {
    const regexMatch = new RegExp(String.raw`${searchTerm}`, "i");
    const foundMatch = text.match(regexMatch);
    newText = text.replace(
      regexMatch,
      `<span class='${className ? className + "--highlighted" : "highlighted"}'>${foundMatch && foundMatch[0]}</span>`
    );
  }
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: newText }}
    ></div>
  );
};
