import classNames from "classnames";

export type TableProps = {
  children: React.ReactNode;
  classes?: string;
};

export type TableColumnWidths = {
  minWidth?: string;
  width?: string;
  maxWidth?: string;
};

export default function Table(props: TableProps) {
  const { children, classes }: TableProps = props;

  return <table className={classNames("table", classes)}>{children}</table>;
}
