import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { ConditionalWrapper } from "../../../util/util";

export type LoadingSpinnerProps = {
  color?: string;
  size?: string;
  centered?: boolean;
  classes?: string;
};

export default function LoadingSpinner(props: LoadingSpinnerProps) {
  const {
    color = allColors.primary[500],
    size = "5rem",
    centered,
    classes,
  } = props;

  return (
    <ConditionalWrapper
      condition={centered}
      wrapper={(children: any) => (
        <div className="loading-spinner__container">{children}</div>
      )}
    >
      <svg
        className={classNames("loading-spinner", classes)}
        width={size}
        height={size}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="loading-spinner__path"
          fill="none"
          stroke={color}
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    </ConditionalWrapper>
  );
}
