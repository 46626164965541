import { Autocomplete, MenuItem, TextField } from "@mui/material";
import CheckmarkIcon from "../../common/svg/Checkmark";
import { allColors } from "../../../util/colors";
import { AutocompleteItem, MultiAutocompleteProps } from "./types";
import ChevronIcon from "../../common/svg/Chevron";
import { SvgDirection } from "../../common/svg/types";
import CloseSmIcon from "../../common/svg/CloseSm";
import CloseLgIcon from "../../common/svg/CloseLg";
import classNames from "classnames";

const MultiAutocomplete = (props: MultiAutocompleteProps) => {
  const {
    value,
    startIcon,
    label,
    hideLabel,
    isDisabled,
    extraClasses,
    maxTags,
    maxValues,
    noResultsText,
    ...otherProps
  } = props;

  return (
    <Autocomplete
      {...otherProps}
      value={value}
      multiple
      disableCloseOnSelect
      clearIcon={<CloseSmIcon stroke={allColors.gray[400]} />}
      disablePortal
      limitTags={maxTags || 1}
      renderInput={(params) => (
        <TextField
          {...params}
          classes={{ root: "autocomplete__input-label" }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {startIcon && startIcon}
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          InputLabelProps={{ shrink: false }}
          placeholder={hideLabel ? undefined : label}
        />
      )}
      renderOption={(props, option: AutocompleteItem) => {
        return (
          <MenuItem
            {...props}
            disableRipple
            selected={
              value &&
              value.some(
                (selectedValues) => selectedValues.value === option.value
              )
            }
            disabled={
              // if there is a max value set, disable other options
              value &&
              maxValues !== undefined &&
              value.length >= maxValues &&
              !value.some(
                (selectedValues) => selectedValues.value === option.value
              )
            }
            classes={{
              root: "autocomplete__item",
            }}
          >
            <div className="flex flex-row items-center">
              {option.startIcon}
              {option.label}
            </div>
            {value &&
              value.some(
                (selectedValues) => selectedValues.value === option.value
              ) && (
                <CheckmarkIcon
                  stroke={allColors.primary[600]}
                  classes="min-w-12"
                />
              )}
          </MenuItem>
        );
      }}
      disabled={isDisabled}
      size="small"
      openOnFocus
      noOptionsText={noResultsText}
      popupIcon={
        <ChevronIcon direction={SvgDirection.Down} width="20" height="20" />
      }
      slotProps={{
        popupIndicator: { disableRipple: true },
        clearIndicator: {
          disableRipple: true,
          children: <CloseLgIcon stroke={allColors.black} />,
        },
        popper: {
          placement: "bottom-start",
        },
      }}
      ChipProps={{
        classes: {
          root: "autocomplete__tag",
          label: "autocomplete__tag-label",
        },
      }}
      classes={{
        root: classNames("autocomplete autocomplete--multi", extraClasses),
        expanded: "autocomplete--expanded",
        focused: "autocomplete--focused",
        focusVisible: "autocomplete--focus-visible",
        inputRoot: "autocomplete__input-root",
        input: "autocomplete__input",
        inputFocused: "autocomplete__input--focused",
        popper: "autocomplete__popper",
        paper: "autocomplete__paper",
        listbox: "autocomplete__listbox",
        noOptions: "autocomplete--no-options",
        groupLabel: "autocomplete__group-label",
        groupUl: "autocomplete__group-ul",
      }}
    />
  );
};

export default MultiAutocomplete;
