import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps, SvgType } from "./types";

export default function RouteIcon(props: SvgProps) {
  const {
    type = SvgType.Line,
    width = "24",
    height = "24",
    stroke = allColors.black,
    fill = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__route", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      {type === SvgType.Solid ? (
        <>
          <path
            d="M1 5C1 2.79086 2.79086 1 5 1C7.20914 1 9 2.79086 9 5C9 7.20914 7.20914 9 5 9C2.79086 9 1 7.20914 1 5Z"
            fill={fill}
          />
          <path
            d="M15 19C15 16.7909 16.7909 15 19 15C21.2091 15 23 16.7909 23 19C23 21.2091 21.2091 23 19 23C16.7909 23 15 21.2091 15 19Z"
            fill={fill}
          />
          <path
            d="M15.3531 6.06507C14.5699 6.00083 13.4769 6 11.9344 6H11.5C10.9477 6 10.5 5.55229 10.5 5C10.5 4.44772 10.9477 4 11.5 4H11.9847C13.4658 3.99999 14.6415 3.99998 15.5166 4.07176C15.9623 4.10831 16.3749 4.16645 16.7361 4.26872C17.0962 4.37071 17.4674 4.53362 17.771 4.82093C18.5209 5.53055 18.8532 6.57592 18.6508 7.58832C18.5688 7.99822 18.3599 8.34552 18.1247 8.63678C17.8889 8.92882 17.5857 9.21455 17.2429 9.50177C16.5699 10.0657 15.61 10.7446 14.4008 11.5999L12.3366 13.06L12.3275 13.0665L10.2952 14.504C9.03591 15.3947 8.14403 16.0265 7.54168 16.5313C7.24297 16.7816 7.05051 16.9722 6.93141 17.1197C6.83283 17.2418 6.81192 17.301 6.80979 17.3072C6.74386 17.6426 6.85381 17.9884 7.10132 18.2242C7.10666 18.228 7.1579 18.2643 7.30888 18.307C7.4913 18.3587 7.7585 18.4032 8.14691 18.435C8.93014 18.4993 10.0232 18.5001 11.5656 18.5001H12.5C13.0523 18.5001 13.5 18.9478 13.5 19.5001C13.5 20.0524 13.0523 20.5001 12.5 20.5001H11.5154C10.0343 20.5001 8.85854 20.5001 7.98342 20.4283C7.53772 20.3918 7.12512 20.3336 6.76396 20.2314C6.40379 20.1294 6.03268 19.9665 5.72905 19.6791C4.97913 18.9695 4.6468 17.9242 4.84926 16.9118C4.93124 16.5019 5.14014 16.1546 5.3753 15.8633C5.61109 15.5713 5.91438 15.2855 6.25714 14.9983C6.55071 14.7523 6.89886 14.4844 7.29848 14.1896C7.33639 14.1524 7.37778 14.1177 7.42256 14.086L13.2048 9.99611C14.4641 9.1054 15.356 8.47355 15.9584 7.96881C16.2571 7.71852 16.4495 7.5279 16.5686 7.38039C16.6672 7.25831 16.6881 7.19913 16.6902 7.19289C16.7562 6.8575 16.6462 6.51164 16.3987 6.2759C16.3934 6.27206 16.3422 6.23582 16.1911 6.19306C16.0087 6.1414 15.7415 6.09692 15.3531 6.06507Z"
            fill={fill}
          />
        </>
      ) : (
        <path
          d="M11.5 5H11.9344C14.9816 5 16.5053 5 17.0836 5.54729C17.5836 6.02037 17.8051 6.71728 17.6702 7.39221C17.514 8.17302 16.2701 9.05285 13.7823 10.8125L9.71772 13.6875C7.2299 15.4471 5.98599 16.327 5.82984 17.1078C5.69486 17.7827 5.91642 18.4796 6.41636 18.9527C6.99474 19.5 8.51836 19.5 11.5656 19.5H12.5M8 5C8 6.65685 6.65685 8 5 8C3.34315 8 2 6.65685 2 5C2 3.34315 3.34315 2 5 2C6.65685 2 8 3.34315 8 5ZM22 19C22 20.6569 20.6569 22 19 22C17.3431 22 16 20.6569 16 19C16 17.3431 17.3431 16 19 16C20.6569 16 22 17.3431 22 19Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}
