import classNames from "classnames";
import {
  MegaSearchProps,
  MegaSearchResultItem,
  MegaSearchResultItemType,
  MegaSearchTab,
} from "./types";
import { useEffect, useMemo, useRef, useState } from "react";
import StyledInput from "../form/input/StyledInput";
import SearchSmIcon from "../common/svg/SearchSm";
import StyledTabs, { TabType } from "../common/tabs/StyledTabs";
import MegaSearchGroup from "./MegaSearchGroup";
import LoadingSpinner from "../common/loading-spinner/LoadingSpinner";
import { useDebounce } from "../../util/debounce";
import StyledButton from "../common/button/StyledButton";
import { allColors } from "../../util/colors";
import CloseCircleIcon from "../common/svg/CloseCircle";
import { SvgType } from "../common/svg/types";
import {
  ResultsFor307Stops,
  ResultsFor307Text,
  ResultsFor307Trips,
} from "../../util/mock-data/mockSearchData";

export default function MegaSearch(props: MegaSearchProps) {
  const { classes, inputClasses, containerClasses, setSelectedValue } = props;

  const [isFocusedWithin, setIsFocusedWithin] = useState<boolean>(false);
  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);
  const [tabValue, setTabValue] = useState<MegaSearchTab>(MegaSearchTab.All);
  const [results, setResults] = useState<MegaSearchResultItem[]>([]);
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [windowWidth, setWindowWidth] = useState<number | undefined>(
    window.innerWidth
  );
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [lastSelectedValue, setLastSelectedValue] =
    useState<MegaSearchResultItem | null>();
  const [debouncedValue, setDebouncedValue] = useState<string>("");
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);

  const searchContainerRef = useRef<HTMLDivElement | null>(null);
  const searchContentRef = useRef<HTMLDivElement | null>(null);

  const setNewWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Minimized mega search when outside click is pressed
    const handleOutsideSearchClick = (e: any) => {
      if (
        // is clicked element inside container
        !searchContainerRef?.current?.contains(e.target) &&
        // or focused element inside container
        !searchContainerRef?.current?.contains(document.activeElement)
      ) {
        setIsFocusedWithin(false);
      }
    };
    // resize listener used to resize height of content container
    window.addEventListener("resize", setNewWindowWidth);
    const timeoutId = setTimeout(() => {
      document.addEventListener("click", handleOutsideSearchClick, false);
    }, 10);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", setNewWindowWidth);
      document.removeEventListener("click", handleOutsideSearchClick, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedSearchInput = useDebounce(() => {
    setDebouncedValue(searchInputValue);
  }, 500);

  useEffect(() => {
    // revert search input if user clicks outside without selecting a value
    if (!isFocusedWithin) {
      if (lastSelectedValue?.name) {
        setSearchInputValue(lastSelectedValue.name);
      } else {
        setSearchInputValue("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocusedWithin]);

  useEffect(() => {
    debouncedSearchInput();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInputValue]);

  useEffect(() => {
    if (debouncedValue.length > 2) {
      setResults([]);
      setTotalCount(undefined);
      setIsLoadingResults(true);
      setTimeout(() => {
        let results: MegaSearchResultItem[] = [];
        if (tabValue === MegaSearchTab.All) {
          results = ResultsFor307Text.filter((result) =>
            result.name.toLowerCase().includes(debouncedValue.toLowerCase())
          );
        }
        if (tabValue === MegaSearchTab.Trips) {
          const textAndTrips = ResultsFor307Text.concat(ResultsFor307Trips);
          results = textAndTrips.filter(
            (result) =>
              result.type === MegaSearchResultItemType.Trip &&
              (result.name
                .toLowerCase()
                .includes(debouncedValue.toLowerCase()) ||
                (result.matchText &&
                  result.matchText
                    .toLowerCase()
                    .includes(debouncedValue.toLowerCase())))
          );
        }
        if (tabValue === MegaSearchTab.Stops) {
          const textAndStops = ResultsFor307Text.concat(ResultsFor307Stops);
          results = textAndStops.filter(
            (result) =>
              result.type === MegaSearchResultItemType.Site &&
              (result.name
                .toLowerCase()
                .includes(debouncedValue.toLowerCase()) ||
                (result.matchText &&
                  result.matchText
                    .toLowerCase()
                    .includes(debouncedValue.toLowerCase())))
          );
        }
        setTotalCount(results.length > 0 ? results.length : undefined);
        setResults(results.slice(0, 7));
        setIsLoadingResults(false);
      }, 500);
      // TODO: Actually call autocompleteSearch endpoint
    } else {
      setTotalCount(undefined);
      setResults([]);
    }
  }, [debouncedValue, tabValue]);

  useEffect(() => {
    // Transition the content's height smoothly on inner content change
    const container = searchContainerRef.current;
    const content = searchContentRef.current;
    if (content && container) {
      const previousHeight = content.scrollHeight;
      content.style.height = "100%";
      content.style.maxHeight = "auto";
      container.style.overflowY = "hidden";
      const newHeight = content.scrollHeight;
      const containerTransition = content.style.transition;
      content.style.transition = "";

      requestAnimationFrame(() => {
        content.style.height = previousHeight + "px";
        content.style.maxHeight = previousHeight + "px";
        content.style.transition = containerTransition;
        requestAnimationFrame(() => {
          content.style.height = newHeight + "px";
          content.style.maxHeight = newHeight + "px";
          setTimeout(() => {
            container.style.overflowY = "auto";
          }, 200);
        });
      });
    }
  }, [tabValue, searchInputValue, debouncedValue, results, windowWidth]);

  const handleFocus = () => {
    setIsFocusedWithin(true);
  };

  const handleInputKeyDown = (
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      submitTextSearch();
      event.currentTarget.blur();
    }
  };

  const submitTextSearch = (text?: string) => {
    const textValue: MegaSearchResultItem = {
      name: text || searchInputValue,
      type: MegaSearchResultItemType.Text,
      currentTab: tabValue,
    };
    submitValue(textValue);
  };

  const clearValue = () => {
    setSearchInputValue("");
    setDebouncedValue("");
    setLastSelectedValue(null);
    setSelectedValue && setSelectedValue(null);
  };

  const submitValue = (value: MegaSearchResultItem) => {
    setIsFocusedWithin(false);
    setSearchInputValue(value.name);
    if (value.name !== "") {
      updateRecentSearches(value.name);
    }
    setLastSelectedValue(value);
    setSelectedValue && setSelectedValue(value);
  };

  const updateRecentSearches = (value: string) => {
    const searchesFromStorage = localStorage.getItem(
      "liveTrackingRecentSearches"
    );
    if (searchesFromStorage) {
      const listOfSearches: string[] = JSON.parse(searchesFromStorage);
      if (listOfSearches && listOfSearches.length > 0) {
        const duplicateIndex = listOfSearches.indexOf(value);
        if (duplicateIndex === -1) {
          // if the search term is new, add it to the beginning of the array
          listOfSearches.unshift(value);
        } else {
          // if duplicate, move the search term to the beginning of the array
          listOfSearches.unshift(listOfSearches.splice(duplicateIndex, 1)[0]);
        }
        localStorage.setItem(
          "liveTrackingRecentSearches",
          JSON.stringify(listOfSearches)
        );
      } else {
        localStorage.setItem(
          "liveTrackingRecentSearches",
          JSON.stringify([value])
        );
      }
    } else {
      localStorage.setItem(
        "liveTrackingRecentSearches",
        JSON.stringify([value])
      );
    }
  };

  const visibleRecentSearches: MegaSearchResultItem[] = useMemo(() => {
    const numberOfItemsToShow = 3;
    const searchesFromStorage = localStorage.getItem(
      "liveTrackingRecentSearches"
    );
    if (searchesFromStorage) {
      const listOfSearches: string[] = JSON.parse(searchesFromStorage);
      if (listOfSearches && listOfSearches.length > 0) {
        if (debouncedValue.length > 2) {
          const searchMatches = listOfSearches
            .filter((search) =>
              search.toLowerCase().includes(debouncedValue.toLowerCase())
            )
            .splice(0, numberOfItemsToShow);
          return searchMatches.map((match) => {
            return { name: match, type: MegaSearchResultItemType.Text };
          });
        } else {
          const firstThreeSearches = listOfSearches.splice(
            0,
            numberOfItemsToShow
          );
          return firstThreeSearches.map((match) => {
            return { name: match, type: MegaSearchResultItemType.Text };
          });
        }
      } else return [];
    } else return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, lastSelectedValue]);

  return (
    <div className={classNames("mega-search__container", containerClasses)}>
      <div
        className={classNames(
          "mega-search",
          `mega-search--${tabValue}`,
          { "mega-search--focused": isFocusedWithin },
          classes
        )}
        ref={searchContainerRef}
        onFocus={(e) => {
          if (!e.target.classList.contains("mega-search__clear-button")) {
            handleFocus();
          }
        }}
      >
        <StyledInput
          startIcon={<SearchSmIcon />}
          placeholder="Search by AV#, Lane ID, Trip ID or Site"
          extraClasses={classNames(
            "mega-search__input",
            { "mega-search__input--focused": isFocusedWithin },
            inputClasses
          )}
          hideFocusRing
          value={searchInputValue}
          onChange={(e) => setSearchInputValue(e.target.value)}
          onKeyDown={(e) => handleInputKeyDown(e)}
          endIcon={
            isFocusedWithin || searchInputValue ? (
              <StyledButton
                color="secondary"
                size="sm"
                textOnly
                rounded
                iconOnly
                onClick={clearValue}
                classes="mega-search__clear-button"
              >
                <CloseCircleIcon
                  type={SvgType.Line}
                  // Fake hide the clear icon when there isn't a search value
                  width={searchInputValue ? "20" : "0"}
                  height={searchInputValue ? "20" : "0"}
                  stroke={
                    searchInputValue ? allColors.gray[500] : "transparent"
                  }
                />
              </StyledButton>
            ) : undefined
          }
        />
        {isFocusedWithin && (
          <div className="mega-search__content" ref={searchContentRef}>
            <hr />
            <StyledTabs
              tabType={TabType.Underline}
              labels={["All Tabs", "in 'Trips'", "in 'Stops'"]}
              extraClasses="mega-search__tabs"
              value={currentTabIndex}
              onChange={(_, newValue) => {
                setCurrentTabIndex(newValue);
                newValue === 0
                  ? setTabValue(MegaSearchTab.All)
                  : newValue === 1
                    ? setTabValue(MegaSearchTab.Trips)
                    : setTabValue(MegaSearchTab.Stops);
              }}
            />
            {visibleRecentSearches && visibleRecentSearches.length > 0 && (
              <MegaSearchGroup
                label="Recent Searches"
                currentTab={tabValue}
                results={visibleRecentSearches}
                searchTerm={debouncedValue}
                onClickResult={(result) => {
                  submitValue(result);
                }}
              />
            )}
            {(!visibleRecentSearches || visibleRecentSearches.length === 0) &&
              debouncedValue.length <= 2 &&
              !isLoadingResults && (
                <div className="mega-search__no-results">
                  <SearchSmIcon
                    width="30"
                    height="30"
                    stroke={allColors.gray[500]}
                  />
                  <p className="text-md text-gray-500 font-medium my-4">
                    Start typing to find matches
                  </p>
                </div>
              )}
            {results.length === 0 &&
              debouncedValue.length > 2 &&
              !isLoadingResults && (
                <div className="mega-search__no-results">
                  <SearchSmIcon
                    width="30"
                    height="30"
                    stroke={allColors.gray[600]}
                  />
                  <p className="text-md font-semibold my-4">
                    No results matching "{debouncedValue}"
                  </p>
                </div>
              )}
            {results.length > 0 && debouncedValue.length > 2 && (
              <MegaSearchGroup
                label="Search Matches"
                count={totalCount}
                currentTab={tabValue}
                results={results}
                searchTerm={debouncedValue}
                onClickResult={(result) => {
                  submitValue(result);
                }}
              />
            )}
            {isLoadingResults && <LoadingSpinner classes="mx-auto my-16" />}
            {debouncedValue.length > 2 && (
              <MegaSearchGroup
                results={[
                  {
                    name: `Search all results for "${searchInputValue}"`,
                    type: MegaSearchResultItemType.AllResults,
                  },
                ]}
                onClickResult={() => {
                  const textValueObject = {
                    name: searchInputValue,
                    type: MegaSearchResultItemType.Text,
                  };
                  submitValue(textValueObject);
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
