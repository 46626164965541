import classNames from "classnames";
import { Stop } from "../../../types/trip";
import Table, { TableColumnWidths } from "../../common/table/Table";
import TableHeader, {
  ColumnSortState,
  TableHeaderColumn,
} from "../../common/table/TableHeader";
import StopsTableRow from "./StopsTableRow";
import { useXlBreakpoint } from "../../../util/breakpoints";
import { StopsSortMethod } from "../../../api/stop";
import { ReactElement, useMemo } from "react";
import LoadingSpinner from "../../common/loading-spinner/LoadingSpinner";

export type StopsTableProps = {
  stops?: Stop[];
  sortValue?: string | StopsSortMethod;
  onChangeSortValue?: (newValue: string | StopsSortMethod) => void;
  isLoadingItems?: boolean;
  noResults?: ReactElement;
  classes?: string;
};

export default function StopsTable(props: StopsTableProps) {
  const {
    stops,
    sortValue,
    onChangeSortValue,
    isLoadingItems,
    noResults,
    classes,
  }: StopsTableProps = props;

  const isXl = useXlBreakpoint();

  // `width` percentages must add up to 100%.
  const stopsTableColumnWidths: TableColumnWidths[] = [
    { minWidth: "50px", maxWidth: "50px" },
    { minWidth: "170px", width: "30%" },
    { minWidth: "150px", width: "30%" },
    { minWidth: "180px", width: "16%" },
    { minWidth: "150px", width: "12%" },
    { minWidth: "150px", width: "12%" },
  ];
  const stopsTableColumnWidthsXl: TableColumnWidths[] = [
    { minWidth: "50px", maxWidth: "50px" },
    { minWidth: "170px", width: "20%" },
    { minWidth: "50px", width: "10%" },
    { minWidth: "60px", width: "10%" },
    { minWidth: "150px", width: "20%" },
    { minWidth: "80px", width: "9%" },
    { minWidth: "130px", width: "11%" },
    { minWidth: "120px", width: "10%" },
    { minWidth: "120px", width: "10%" },
  ];

  const variableStopsTableColumnWidths: TableColumnWidths[] = isXl
    ? stopsTableColumnWidthsXl
    : stopsTableColumnWidths;

  const stopTableHeaderContent: TableHeaderColumn[] = [
    {
      name: "",
      ...variableStopsTableColumnWidths[0],
    },
    {
      name: "Stop",
      value: "site_name",
      sortable: true,
      ...variableStopsTableColumnWidths[1],
    },
    {
      name: "Stop progress",
      value: "stop_progress",
      sortable: true,
      ...variableStopsTableColumnWidths[2],
    },
    {
      name: "Stop timeliness",
      ...variableStopsTableColumnWidths[3],
    },
    {
      name: "Arrived at",
      sortable: true,
      value: "arrived_at",
      ...variableStopsTableColumnWidths[4],
    },
    {
      name: "Departed at",
      sortable: true,
      value: "departed_at",
      ...variableStopsTableColumnWidths[5],
    },
  ];
  const stopTableHeaderContentXl: TableHeaderColumn[] = [
    {
      name: "",
      ...variableStopsTableColumnWidths[0],
    },
    {
      name: "Stop",
      value: "site_name",
      sortable: true,
      ...variableStopsTableColumnWidths[1],
    },
    {
      name: "Trip name",
      value: "trip_name",
      sortable: true,
      ...variableStopsTableColumnWidths[2],
    },
    {
      name: "AV#",
      value: "av_id",
      sortable: true,
      ...variableStopsTableColumnWidths[3],
    },
    {
      name: "Stop progress",
      value: "stop_progress",
      sortable: true,
      ...variableStopsTableColumnWidths[4],
    },
    {
      name: "Stop timeliness",
      ...variableStopsTableColumnWidths[5],
    },
    {
      name: "Stop status",
      ...variableStopsTableColumnWidths[6],
    },
    {
      name: "Arrived at",
      sortable: true,
      value: "arrived_at",
      ...variableStopsTableColumnWidths[7],
    },
    {
      name: "Departed at",
      sortable: true,
      value: "departed_at",
      ...variableStopsTableColumnWidths[8],
    },
  ];

  const variableStopsTableColumnInfo: TableHeaderColumn[] = isXl
    ? stopTableHeaderContentXl
    : stopTableHeaderContent;

  const stopTableColumns: TableHeaderColumn[] = useMemo(() => {
    const currentSortColumnName = sortValue && sortValue.replace("-", "");

    const columnsWithActiveValue: TableHeaderColumn[] =
      variableStopsTableColumnInfo.map((column) => {
        const isActiveColumn = currentSortColumnName
          ? column.value === currentSortColumnName
          : false;
        const sortDirection =
          sortValue && sortValue[0] === "-"
            ? ColumnSortState.Descending
            : sortValue
              ? ColumnSortState.Ascending
              : undefined;
        return {
          ...column,
          currentState:
            isActiveColumn && sortDirection ? sortDirection : undefined,
        };
      });
    return columnsWithActiveValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue, isXl]);

  return (
    <Table classes={classNames("stops-table", classes)}>
      <TableHeader
        columns={stopTableColumns}
        onChangeSortValue={(sortName, sortDirection) => {
          let newSortValue = sortName;
          if (sortDirection === ColumnSortState.Descending) {
            newSortValue = "-" + newSortValue;
          }
          onChangeSortValue && onChangeSortValue(newSortValue);
        }}
      />
      <tbody>
        {isLoadingItems && (
          <tr>
            <td colSpan={stopTableColumns.length} className="h-96">
              <LoadingSpinner centered classes="my-96" />
            </td>
          </tr>
        )}
        {!isLoadingItems && (!stops || stops.length <= 0) && (
          <tr>
            <td colSpan={stopTableColumns.length} className="h-96">
              {noResults}
            </td>
          </tr>
        )}
        {stops?.map((stop, index) => {
          return (
            <StopsTableRow
              key={index}
              stop={stop}
              columnWidths={variableStopsTableColumnWidths}
            />
          );
        })}
      </tbody>
    </Table>
  );
}
