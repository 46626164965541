import "./styles/index.scss";
import App from "./App";
import * as ReactDOM from "react-dom/client";

// LogRocket.init("ddlx1a/gatik");
// LogRocket.init(process.env.REACT_APP_LOGROCKET_API_KEY || "");

const container = document.getElementById("root")!;

ReactDOM.createRoot(container).render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);
