export interface UserInfo {
  admin_user?: string;
  avatar?: string;
  customer_name?: string;
  customer_id?: string;
  allowed_customers?: string[];
  first_name?: string;
  last_name?: string;
  email?: string;
  timezone?: string | null;
  profile?: FetchUserJobInfo;

  access_key?: string;
  refresh_key?: string;
}

export interface FetchUserJobInfo {
  job_category?: string;
  job_name?: string;
  job_seniority?: string;
}

export enum JobCategoryType {
  Internal = "internal",
  External = "external",
}
export interface JobCategory {
  id: string;
  name: string;
  order: number;
  type: JobCategoryType;
}
export interface JobSeniority {
  id: string;
  name: string;
  order: number;
}
export interface Job {
  category: string;
  id: string;
  name: string;
  order: number;
}
