import axios from "axios";
import { UserInfo } from "../types/common";
import { refreshLogin } from "./auth";

const omniAxios = axios.create({
  baseURL: "/api/",
  headers: {
    Accept: "application/json",
  },
  paramsSerializer: {
    indexes: null,
  },
});

omniAxios.interceptors.request.use(
  (config) => {
    const userInfo = localStorage.getItem("user");
    if (userInfo) {
      const parsedUserInfo: UserInfo = JSON.parse(userInfo);
      const accessToken = parsedUserInfo.access_key;
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// If any call fails, refresh the access token and retry the call.
omniAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.name === "CanceledError") {
      // When cancelled a request, don't try retrying the request, such as aborting a call when a newer call is made (live tracking -> fetchTrips)
    } else if (!error.response) {
      console.error("No error response given:", error);
    } else if (error.response.status === 401 && !originalRequest._retry) {
      const userInfo = localStorage.getItem("user");
      setTimeout(async () => {
        if (userInfo) {
          const parsedUserInfo: UserInfo = JSON.parse(userInfo);
          const refreshToken = parsedUserInfo.refresh_key;
          await refreshLogin(refreshToken || "")
            .then((resp) => {
              originalRequest.headers["Authorization"] =
                `Bearer ${resp?.access}`;
              originalRequest._retry = true;
              const newUserInfo = {
                ...parsedUserInfo,
                access_key: resp?.access,
              };
              localStorage.setItem("user", JSON.stringify(newUserInfo));
              return omniAxios(originalRequest);
            })
            .catch((reason) => {
              console.error(reason);
              localStorage.removeItem("user");
              // TODO: Remove this once Ops Portal is integrated into a single app.
              localStorage.removeItem("access_token");
              localStorage.removeItem("refresh_token");

              if (!window.location.pathname.includes("login")) {
                window.location.replace("/login?auth=expired");
              }
            });
        }
      }, 250);
    }
    return Promise.reject(error);
  }
);

export default omniAxios;
