import classNames from "classnames";
import FilterControl from "../../components/filter-control/FilterControl";
import { dayOptions } from "../../components/form/autocomplete/day-filter/DayFilter";
import { useMdBreakpoint } from "../../util/breakpoints";
import { FilterControlProps } from "../../components/filter-control/types";

export interface GlobalFilterModalProps extends FilterControlProps {}

export default function GlobalFilterModal(props: GlobalFilterModalProps) {
  const { ...otherProps } = props;

  const isMd = useMdBreakpoint();

  return (
    <FilterControl
      {...otherProps}
      label="Global Filters"
      hideClearButton
      filters={[
        {
          label: "Date",
          name: "day",
          singleSelect: true,
          options: dayOptions,
        },
      ]}
      classes={classNames("live-tracking__filter-control", {
        "live-tracking__filter-control--desktop": isMd,
        "live-tracking__filter-control--mobile": !isMd,
      })}
    />
  );
}
