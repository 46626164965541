import { useEffect, useState } from "react";

export enum Breakpoints {
  xs = 500,
  sm = 700,
  md = 900,
  lg = 1200,
  xl = 1600,
}

export const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return { width };
};

export const useXsBreakpoint = () => {
  const { width } = useViewport();

  return width >= Breakpoints.xs;
};
export const useSmBreakpoint = () => {
  const { width } = useViewport();

  return width >= Breakpoints.sm;
};
export const useMdBreakpoint = () => {
  const { width } = useViewport();

  return width >= Breakpoints.md;
};
export const useLgBreakpoint = () => {
  const { width } = useViewport();

  return width >= Breakpoints.lg;
};
export const useXlBreakpoint = () => {
  const { width } = useViewport();

  return width >= Breakpoints.xl;
};
