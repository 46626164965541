import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps } from "./types";

export default function CustomerServiceIcon(props: SvgProps) {
  const {
    width = "24",
    height = "24",
    stroke = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames(classes, "icon icon__customer-service")}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.1666 9.83708C14.1666 9.54907 14.1666 9.40506 14.21 9.27668C14.336 8.90374 14.6681 8.75901 15.0009 8.60744C15.375 8.43706 15.562 8.35186 15.7473 8.33687C15.9577 8.31986 16.1685 8.36519 16.3483 8.46611C16.5867 8.59991 16.753 8.85415 16.9232 9.06089C17.7093 10.0157 18.1024 10.4932 18.2462 11.0197C18.3623 11.4445 18.3623 11.8889 18.2462 12.3137C18.0365 13.0817 17.3737 13.7253 16.8831 14.3212C16.6322 14.626 16.5067 14.7784 16.3483 14.8673C16.1685 14.9682 15.9577 15.0136 15.7473 14.9965C15.562 14.9816 15.375 14.8964 15.0009 14.726C14.6681 14.5744 14.336 14.4297 14.21 14.0567C14.1666 13.9284 14.1666 13.7843 14.1666 13.4963V9.83708Z"
        stroke={stroke}
        strokeWidth="1.75"
      />
      <path
        d="M5.83329 9.83722C5.83329 9.47451 5.82311 9.14855 5.52989 8.89354C5.42323 8.80079 5.28184 8.73638 4.99905 8.60758C4.62497 8.43719 4.43792 8.352 4.25259 8.33701C3.69655 8.29204 3.39739 8.67155 3.07673 9.06102C2.29058 10.0159 1.89751 10.4933 1.75368 11.0198C1.63761 11.4447 1.63761 11.889 1.75368 12.3139C1.96346 13.0818 2.62623 13.7255 3.1168 14.3213C3.42603 14.6969 3.72143 15.0396 4.25259 14.9967C4.43792 14.9817 4.62497 14.8965 4.99905 14.7261C5.28184 14.5973 5.42323 14.5329 5.52989 14.4401C5.82311 14.1851 5.83329 13.8592 5.83329 13.4965V9.83722Z"
        stroke={stroke}
        strokeWidth="1.75"
      />
      <path
        d="M16.6666 8.75002V7.50002C16.6666 4.27836 13.6818 1.66669 9.99992 1.66669C6.31802 1.66669 3.33325 4.27836 3.33325 7.50002V8.75002"
        stroke={stroke}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6667 14.5834C16.6667 18.3334 13.3333 18.3334 10 18.3334"
        stroke={stroke}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
