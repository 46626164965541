import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps, SvgType } from "./types";

export default function SquareMapIcon(props: SvgProps) {
  const {
    type = SvgType.Line,
    width = "24",
    height = "24",
    stroke = allColors.black,
    fill = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__square-map", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      {type === SvgType.Solid ? (
        <>
          <path
            d="M5.74813 2.0442C5.91283 2.03074 5.99518 2.02401 6.09222 2.03504C6.36557 2.06612 6.64957 2.2372 6.80482 2.46433C6.85994 2.54496 6.89872 2.63545 6.97628 2.81643L10.7243 11.5617L3.18621 15.7206C2.79577 15.9361 2.60055 16.0438 2.44083 16.0248C2.30147 16.0082 2.17548 15.9338 2.09364 15.8198C1.99986 15.6892 1.99984 15.4662 1.99981 15.0203C1.99963 12.5998 1.99996 10.1792 1.99996 7.75869C1.99994 6.95373 1.99993 6.28936 2.04415 5.74818C2.09008 5.18608 2.18864 4.66938 2.43593 4.18404C2.81943 3.43139 3.43135 2.81947 4.184 2.43598C4.66933 2.18868 5.18603 2.09012 5.74813 2.0442Z"
            fill={fill}
          />
          <path
            d="M3.04239 18.0842C2.70657 18.2695 2.53867 18.3621 2.40687 18.5462C2.31319 18.6771 2.2248 18.94 2.22038 19.1008C2.21415 19.3272 2.26469 19.441 2.36576 19.6686C2.38771 19.7181 2.41107 19.7672 2.43593 19.816C2.81943 20.5686 3.43135 21.1805 4.184 21.564C4.66933 21.8113 5.18603 21.9099 5.74813 21.9558C6.28932 22 6.95367 22 7.75863 22H13.9846C14.3897 22 14.5922 22 14.7188 21.9147C14.8295 21.8402 14.9058 21.7245 14.9308 21.5934C14.9593 21.4434 14.8795 21.2572 14.7199 20.8849L11.516 13.4091L3.04239 18.0842Z"
            fill={fill}
          />
          <path
            d="M18.2518 21.9558C18.0873 21.9692 18.0051 21.976 17.9081 21.965C17.6346 21.9339 17.3505 21.7628 17.1952 21.5355C17.1402 21.4549 17.1014 21.3645 17.0239 21.1838L9.28024 3.11514C9.12066 2.7428 9.04088 2.55663 9.06938 2.40664C9.0943 2.27554 9.1706 2.15983 9.28129 2.08528C9.40792 2 9.61046 2 10.0156 2H16.2413C17.0462 1.99999 17.7106 1.99998 18.2518 2.0442C18.8139 2.09012 19.3306 2.18868 19.8159 2.43598C20.5686 2.81947 21.1805 3.43139 21.564 4.18404C21.8113 4.66938 21.9098 5.18608 21.9558 5.74818C22 6.28937 22 6.95372 22 7.75868V16.2413C22 17.0463 22 17.7106 21.9558 18.2518C21.9098 18.8139 21.8113 19.3306 21.564 19.816C21.1805 20.5686 20.5686 21.1805 19.8159 21.564C19.3306 21.8113 18.8139 21.9099 18.2518 21.9558Z"
            fill={fill}
          />
        </>
      ) : (
        <path
          d="M8 3L16 21M3 17L12 12M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}
