import classNames from "classnames";
import { Stop, StopEventStatus, Trip } from "../../../types/trip";
import Badge, { BadgeColor, BadgeSize } from "../../common/badge/Badge";
import TripProgressBar from "../trip-progress-bar/TripProgressBar";
import StyledButton from "../../common/button/StyledButton";
import { useXlBreakpoint } from "../../../util/breakpoints";
import { allColors } from "../../../util/colors";
import ArrowIcon from "../../common/svg/Arrow";
import { SvgDirection, SvgType } from "../../common/svg/types";
import RouteIcon from "../../common/svg/Route";
import { TableColumnWidths } from "../../common/table/Table";

export type TripsTableRowProps = {
  trip: Trip;
  columnWidths?: TableColumnWidths[];
  onClickItem?: () => void;
  classes?: string;
};

export default function TripsTableRow(props: TripsTableRowProps) {
  const { trip, columnWidths, onClickItem, classes }: TripsTableRowProps =
    props;

  const isXl = useXlBreakpoint();

  const columnWidthsStyles = (column: number) => {
    if (columnWidths && columnWidths[column]) {
      return {
        minWidth: columnWidths[column].minWidth,
        width: columnWidths[column].width,
        maxWidth: columnWidths[column].maxWidth,
      };
    } else return undefined;
  };

  // Returns "Next location" stop. This is the first stop awaiting AV or the second stop if origin is awaiting AV
  const nextLocationStop: Stop | undefined = trip.itinerary.stops.find(
    (stop, index) =>
      stop.event_status === StopEventStatus.AwaitingAV && index !== 0
  );
  // Returns the index of the next location stop
  const nextLocationStopIndex: number = trip.itinerary.stops.findIndex(
    (stop, index) =>
      stop.event_status === StopEventStatus.AwaitingAV && index !== 0
  );
  // Returns "last activity at" stop. If all stops have departed, show the final destination.
  const lastActivityAtStop: Stop =
    nextLocationStopIndex === -1
      ? trip.itinerary.stops[trip.itinerary.stops.length - 1]
      : trip.itinerary.stops[nextLocationStopIndex - 1];

  return (
    <tr className={classNames("trips-table-row", classes)}>
      <td className="trips-table-row__cell" style={columnWidthsStyles(0)}>
        <Badge
          size={BadgeSize.Sm}
          color={BadgeColor.White}
          startIcon={
            <RouteIcon
              type={SvgType.Solid}
              fill={allColors.gray[800]}
              classes="mr-2 min-w-6"
              width="12"
              height="12"
            />
          }
          onClick={() => onClickItem && onClickItem()}
          classes="trips-table-row__badge text-left"
        >
          <span className="leading-7 py-1">{trip.itinerary.name}</span>
        </Badge>
      </td>
      <td className="trips-table-row__cell" style={columnWidthsStyles(1)}>
        {trip.template?.external_id ? trip.template?.external_id : "--"}
      </td>
      <td className="trips-table-row__cell" style={columnWidthsStyles(2)}>
        <Badge
          size={BadgeSize.Sm}
          color={BadgeColor.White}
          classes="trips-table-row__badge"
        >
          {trip.av.av_id}
        </Badge>
      </td>
      <td className="trips-table-row__cell" style={columnWidthsStyles(3)}>
        <TripProgressBar
          stops={trip.itinerary.stops}
          tripStatus={trip.status}
        />
      </td>
      <td className="trips-table-row__cell" style={columnWidthsStyles(4)}>
        {lastActivityAtStop.site.properties.name}
      </td>
      <td className="trips-table-row__cell" style={columnWidthsStyles(5)}>
        {nextLocationStop ? nextLocationStop.site.properties.name : "--"}
      </td>
      <td className="trips-table-row__cell" style={columnWidthsStyles(6)}>
        <StyledButton
          size="sm"
          transparent
          iconOnly={!isXl}
          onClick={() => onClickItem && onClickItem()}
        >
          <span className={isXl ? undefined : "sr-only"}>View Details</span>
          <ArrowIcon
            width="20"
            height="20"
            stroke={allColors.primary[600]}
            direction={SvgDirection.Right}
            classes="xl:ml-3"
          />
        </StyledButton>
      </td>
      {/* <td style={{ width: columnWidths[7] }}></td> */}
    </tr>
  );
}
