import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps, SvgType } from "./types";

export default function UserIcon(props: SvgProps) {
  const {
    type = SvgType.Line,
    width = "24",
    height = "24",
    stroke = allColors.black,
    fill = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__user", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      {type === SvgType.Solid ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.786 14.5C10.9287 14.5005 13.0713 14.5005 15.214 14.5C15.9407 14.4998 16.4358 14.4997 16.8682 14.576C18.9383 14.941 20.559 16.5617 20.924 18.6318C21.0003 19.0642 21.0002 19.5593 21 20.286C21 20.4444 21.0048 20.6038 20.9772 20.7605C20.8677 21.3815 20.3815 21.8677 19.7605 21.9772C19.6243 22.0012 19.4808 22.0005 19.4095 22.0001C14.4703 21.9736 9.52965 21.9736 4.5905 22.0001C4.51914 22.0005 4.37568 22.0012 4.23951 21.9772C3.6185 21.8677 3.13228 21.3815 3.02278 20.7605C2.99515 20.6038 3.00001 20.4444 2.99997 20.286C2.99981 19.5593 2.9997 19.0642 3.07595 18.6318C3.44096 16.5617 5.06169 14.941 7.13174 14.576C7.56415 14.4997 8.05932 14.4998 8.786 14.5Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.49999 7.5C6.49999 4.46243 8.96242 2 12 2C15.0376 2 17.5 4.46243 17.5 7.5C17.5 10.5376 15.0376 13 12 13C8.96242 13 6.49999 10.5376 6.49999 7.5Z"
            fill={fill}
          />
        </>
      ) : (
        <path
          d="M20 21C20 19.6044 20 18.9067 19.8278 18.3389C19.44 17.0605 18.4395 16.06 17.1611 15.6722C16.5933 15.5 15.8956 15.5 14.5 15.5H9.5C8.10444 15.5 7.40665 15.5 6.83886 15.6722C5.56045 16.06 4.56004 17.0605 4.17224 18.3389C4 18.9067 4 19.6044 4 21M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}
