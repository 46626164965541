export enum SvgType {
  Solid = "solid",
  Line = "line",
}

export enum SvgDirection {
  Up = "up",
  Right = "right",
  Down = "down",
  Left = "left",
}

export type SvgProps = {
  type?: SvgType;
  width?: string;
  height?: string;
  fill?: string;
  innerFill?: string;
  stroke?: string;
  classes?: string;
  direction?: SvgDirection;
};
