import classNames from "classnames";
import {
  Stop,
  StopEventStatus,
  StopSequenceStatus,
  TripStatus,
} from "../../../types/trip";
import Badge, { BadgeColor, BadgeSize } from "../../common/badge/Badge";
import StopProgressBar from "../stop-progress-bar/StopProgressBar";
import StyledButton from "../../common/button/StyledButton";
import { allColors } from "../../../util/colors";
import { TableColumnWidths } from "../../common/table/Table";
import GooglePinIcon from "../../common/svg/GooglePin";
import StopTimelinessBadge from "../stop-timeliness-badge/StopTimelinessBadge";
import Dot from "../../common/dot";
import {
  getTimezoneCorrectedDate,
  getTimezoneText,
  isDayAfter,
} from "../../../util/date-util";
import StyledTooltip from "../../common/tooltip/StyledTooltip";
import { useXlBreakpoint } from "../../../util/breakpoints";

export type StopsTableRowProps = {
  stop: Stop;
  columnWidths?: TableColumnWidths[];
  classes?: string;
};

export default function StopsTableRow(props: StopsTableRowProps) {
  const { stop, columnWidths, classes }: StopsTableRowProps = props;

  const isXl = useXlBreakpoint();

  const columnWidthsStyles = (column: number) => {
    if (columnWidths && columnWidths[column]) {
      return {
        minWidth: columnWidths[column].minWidth,
        width: columnWidths[column].width,
        maxWidth: columnWidths[column].maxWidth,
      };
    } else return undefined;
  };
  const timezoneText = () => {
    return stop.timezone ? getTimezoneText(stop.timezone) : "UTC";
  };
  const getTime = (date: string) => {
    return `${getTimezoneCorrectedDate(date, stop?.timezone).format("h:mm A")} ${timezoneText()}`;
  };
  const getFullDateTime = (date: string) => {
    return getTimezoneCorrectedDate(date, stop?.timezone).format("LLLL");
  };
  const isStopTimeDayAfter = (date: string) => {
    return (
      stop.trip_scheduled_start &&
      isDayAfter(stop.trip_scheduled_start, date, stop.timezone)
    );
  };
  const getDayAfterBadge = () => {
    return (
      <Badge
        color={BadgeColor.Primary}
        size={BadgeSize.Sm}
        rounded
        classes=" ml-2 text-sm"
      >
        +1
      </Badge>
    );
  };

  const stopStatusContent = () => {
    if (stop.trip_status === TripStatus.Cancelled) {
      return "Cancelled";
    } else if (stop.sequence_status === StopSequenceStatus.Skipped) {
      return "Skipped";
    } else if (stop.event_status === StopEventStatus.AVDeparted) {
      return "Completed";
    } else if (stop.event_status === StopEventStatus.AVArrived) {
      return "In Progress";
    } else if (
      stop.trip_status === TripStatus.InProgress &&
      stop.stops_away === 0
    ) {
      return "In Progress";
    } else {
      return "--";
    }
  };

  const stopTimelinessContent = () => {
    if (stop.is_origin && stop.event_status === StopEventStatus.AVDeparted) {
      return <StopTimelinessBadge type={stop.departure_status} />;
    } else if (stop.event_status !== StopEventStatus.AwaitingAV) {
      return <StopTimelinessBadge type={stop.arrival_status} />;
    } else if (stop.eta) {
      return (
        <StyledTooltip placement="top" header={`${getFullDateTime(stop.eta)}`}>
          <div className="flex items-center">
            <Dot color={allColors.success[600]} classes="mr-3" />
            <span>
              ETA{" "}
              <b>
                {getTime(stop.eta)}
                {isStopTimeDayAfter(stop.eta) && " +1"}
              </b>
            </span>
          </div>
        </StyledTooltip>
      );
    } else {
      return "--";
    }
  };

  return (
    <tr className={classNames("stops-table-row", classes)}>
      <td className="stops-table-row__cell" style={columnWidthsStyles(0)}>
        <StyledButton
          color="secondary"
          size="sm"
          outlined
          iconOnly
          classes="stops-table-row__map-pin-button"
          onClick={() =>
            window
              .open(
                `https://www.google.com/maps/search/?api=1&query=${stop.site.properties.description}`,
                "_blank"
              )
              ?.focus()
          }
        >
          <GooglePinIcon width="30" height="30" />
        </StyledButton>
      </td>
      <td className="stops-table-row__cell" style={columnWidthsStyles(1)}>
        {stop.site.properties.name}
      </td>
      {isXl && (
        <td className="trips-table-row__cell" style={columnWidthsStyles(2)}>
          <Badge
            size={BadgeSize.Sm}
            color={BadgeColor.White}
            onClick={() => console.log("TODO: open trip timeline")}
            classes="trips-table-row__badge"
          >
            {stop.trip_name}
          </Badge>
        </td>
      )}
      {isXl && (
        <td className="trips-table-row__cell" style={columnWidthsStyles(3)}>
          <Badge
            size={BadgeSize.Sm}
            color={BadgeColor.White}
            classes="trips-table-row__badge"
          >
            {stop.av_id}
          </Badge>
        </td>
      )}
      <td className="trips-table-row__cell" style={columnWidthsStyles(4)}>
        <StopProgressBar
          stop={stop}
          isOrigin={stop.is_origin}
          isFinalDestination={stop.is_final_destination}
        />
      </td>
      {isXl && (
        <td className="trips-table-row__cell" style={columnWidthsStyles(5)}>
          {stopStatusContent()}
        </td>
      )}
      <td className="trips-table-row__cell" style={columnWidthsStyles(6)}>
        {stopTimelinessContent()}
      </td>
      <td className="trips-table-row__cell" style={columnWidthsStyles(7)}>
        {stop.arrived_at ? (
          <StyledTooltip
            placement="top"
            header={`${getFullDateTime(stop.arrived_at)} ${timezoneText()}`}
          >
            <div>
              {getTime(stop.arrived_at)}
              {isStopTimeDayAfter(stop.arrived_at) && getDayAfterBadge()}
            </div>
          </StyledTooltip>
        ) : (
          "--"
        )}
      </td>
      <td className="trips-table-row__cell" style={columnWidthsStyles(8)}>
        {stop.departed_at ? (
          <StyledTooltip
            placement="top"
            header={`${getFullDateTime(stop.departed_at)} ${timezoneText()}`}
          >
            <div>
              {getTime(stop.departed_at)}
              {isStopTimeDayAfter(stop.departed_at) && getDayAfterBadge()}
            </div>
          </StyledTooltip>
        ) : (
          "--"
        )}
      </td>
    </tr>
  );
}
