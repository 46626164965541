import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps } from "./types";

export default function MapPinCircleIcon(props: SvgProps) {
  const {
    width = "24",
    height = "24",
    fill = allColors.black,
    innerFill = allColors.white,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__map-pin-circle", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.98062 10.6636C5.98062 7.33971 8.67512 4.64521 11.999 4.64521C15.3228 4.64521 18.0173 7.33971 18.0173 10.6636C18.0173 12.3528 17.2504 13.8334 16.2066 15.1887C15.3344 16.3212 14.2179 17.428 13.1028 18.5336C12.8921 18.7424 12.6807 18.9519 12.4718 19.1609C12.2107 19.422 11.7873 19.422 11.5261 19.1609C11.3171 18.9518 11.1058 18.7424 10.895 18.5334C9.77984 17.4279 8.66349 16.3212 7.79128 15.1887C6.74751 13.8334 5.98062 12.3528 5.98062 10.6636ZM14.0051 10.6636C14.0051 11.7715 13.1069 12.6697 11.999 12.6697C10.891 12.6697 9.99285 11.7715 9.99285 10.6636C9.99285 9.55561 10.891 8.65744 11.999 8.65744C13.1069 8.65744 14.0051 9.55561 14.0051 10.6636Z"
        fill={innerFill}
      />
    </svg>
  );
}
