import classNames from "classnames";
import StyledButton from "../button/StyledButton";
import ArrowIcon from "../svg/Arrow";
import { SvgDirection } from "../svg/types";
import React from "react";
import usePagination from "@mui/material/usePagination";
import { useLgBreakpoint, useSmBreakpoint } from "../../../util/breakpoints";

export interface StyledPaginationProps {
  count: number;
  page: number;
  onChangePage?: (event: React.ChangeEvent<unknown>, page: number) => void;
  extraClasses?: string;
}

export default function StyledPagination(props: StyledPaginationProps) {
  const { count, page, onChangePage, extraClasses }: StyledPaginationProps =
    props;

  const isSm = useSmBreakpoint();
  const isLg = useLgBreakpoint();

  const { items } = usePagination({
    count: count,
    page: page,
    onChange: onChangePage,
    boundaryCount: 1,
    siblingCount: 1,
  });

  return (
    <ul className={classNames("pagination", extraClasses)}>
      {items.map(({ page, type, selected, ...item }, index) => {
        const isPreviousNext = type === "previous" || type === "next";
        const isEllipsis = type === "start-ellipsis" || type === "end-ellipsis";
        if (isEllipsis) {
          return (
            <li key={index} className="pagination__ellipsis">
              …
            </li>
          );
        } else if (isPreviousNext) {
          return (
            <li key={index}>
              <StyledButton
                classes={classNames(
                  "pagination__button pagination__button--previous-next",
                  {
                    "mr-6": type === "previous" && isSm,
                    "ml-6": type === "next" && isSm,
                  }
                )}
                color={"secondary"}
                size={"sm"}
                outlined
                iconOnly={!isLg}
                {...item}
              >
                {type === "previous" ? (
                  <React.Fragment>
                    <ArrowIcon
                      direction={SvgDirection.Left}
                      width="20"
                      height="20"
                      classes={isLg ? "mr-4" : ""}
                    />{" "}
                    <span className={isLg ? "" : "sr-only"}>Previous</span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <span className={isLg ? "" : "sr-only"}>Next</span>{" "}
                    <ArrowIcon
                      direction={SvgDirection.Right}
                      width="20"
                      height="20"
                      classes={isLg ? "ml-4" : ""}
                    />
                  </React.Fragment>
                )}
              </StyledButton>
            </li>
          );
        } else
          return (
            <li key={index}>
              <StyledButton
                key={index}
                classes={classNames(
                  "pagination__button pagination__button--page",
                  {
                    "pagination__button pagination__button--selected": selected,
                  }
                )}
                color={"secondary"}
                size={"sm"}
                outlined={isPreviousNext}
                transparent={!isPreviousNext}
                {...item}
              >
                {page}
              </StyledButton>
            </li>
          );
      })}
    </ul>
  );
}
