import classNames from "classnames";
import { ConditionalWrapper } from "../../../util/util";
import { useEffect, useState } from "react";
import ArrowIcon from "../svg/Arrow";
import { SvgDirection } from "../svg/types";
import { TableColumnWidths } from "./Table";

export type TableHeaderProps = {
  columns: TableHeaderColumn[];
  onChangeSortValue?: (value: string, sortState: ColumnSortState) => void;
  classes?: string;
};

export interface TableHeaderColumn extends TableColumnWidths {
  name: string;
  value?: string;
  sortable?: boolean;
  currentState?: ColumnSortState;
}

export enum ColumnSortState {
  Ascending = "ascending",
  Descending = "descending",
}

export default function Table(props: TableHeaderProps) {
  const { columns, onChangeSortValue, classes }: TableHeaderProps = props;

  const [currentSortColumn, setCurrentSortColumn] = useState<
    string | undefined
  >();
  const [currentSortState, setCurrentSortState] = useState<
    undefined | ColumnSortState
  >(undefined);

  useEffect(() => {
    const defaultColumn = columns.find((column) => column.currentState);
    if (defaultColumn?.value) {
      setCurrentSortColumn(defaultColumn.value);
      setCurrentSortState(defaultColumn.currentState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  useEffect(() => {
    if (currentSortColumn && currentSortState && onChangeSortValue) {
      onChangeSortValue(currentSortColumn, currentSortState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSortColumn, currentSortState]);

  const onChangeSort = (columnValue: string) => {
    if (columnValue === currentSortColumn) {
      currentSortState === ColumnSortState.Ascending
        ? setCurrentSortState(ColumnSortState.Descending)
        : setCurrentSortState(ColumnSortState.Ascending);
    } else {
      setCurrentSortColumn(columnValue);
      setCurrentSortState(ColumnSortState.Ascending);
    }
  };

  return (
    <thead className={classNames("table-header", classes)}>
      <tr>
        {columns.map((column, index) => {
          return (
            <th
              key={index}
              scope="col"
              style={{
                minWidth: column.minWidth,
                width: column.width,
                maxWidth: column.maxWidth,
              }}
            >
              <ConditionalWrapper
                condition={column.sortable}
                wrapper={(children: any) => {
                  return (
                    <button
                      className="table-header__sort-button"
                      onClick={() => {
                        if (column.value) {
                          onChangeSort(column.value);
                        }
                      }}
                    >
                      {children}{" "}
                      <ArrowIcon
                        width="18"
                        height="18"
                        direction={
                          currentSortColumn === column.value &&
                          currentSortState === ColumnSortState.Descending
                            ? SvgDirection.Down
                            : SvgDirection.Up
                        }
                        classes={classNames(
                          "table-header__sort-arrow",
                          "ml-2",
                          {
                            "table-header__sort-arrow--active":
                              currentSortColumn === column.value,
                          }
                        )}
                      />
                    </button>
                  );
                }}
              >
                {column.name}
              </ConditionalWrapper>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}
