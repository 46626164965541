import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps, SvgType } from "./types";
import React from "react";

export default function EyeHideIcon(props: SvgProps) {
  const {
    type = SvgType.Solid,
    width = "24",
    height = "24",
    stroke = allColors.black,
    fill = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__eye-hide", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      {type === SvgType.Solid ? (
        <React.Fragment>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.29283 2.29289C2.68336 1.90237 3.31652 1.90237 3.70705 2.29289C9.71605 8.3019 15.7036 14.2894 21.707 20.2929C22.0976 20.6834 22.0976 21.3166 21.707 21.7071C21.3165 22.0976 20.6834 22.0976 20.2928 21.7071L17.1391 18.5534C15.6943 19.3988 13.9716 20 11.9999 20C9.13826 20 6.80527 18.7353 5.07667 17.2926C3.35042 15.8518 2.16689 14.1857 1.57415 13.2472L1.55006 13.2092C1.43242 13.024 1.27969 12.7836 1.20249 12.4468C1.14017 12.1748 1.14019 11.825 1.20255 11.553C1.27979 11.2161 1.43296 10.9751 1.5509 10.7895L1.57518 10.7512C2.17755 9.79768 3.40084 8.07527 5.19529 6.60956L2.29283 3.70711C1.90231 3.31658 1.90231 2.68342 2.29283 2.29289ZM8.55386 9.96814C8.2021 10.5638 7.99994 11.2591 7.99994 11.9999C7.99994 14.2091 9.7908 15.9999 11.9999 15.9999C12.7409 15.9999 13.4361 15.7977 14.0317 15.446L12.5181 13.9324C12.353 13.9765 12.1794 13.9999 11.9999 13.9999C10.8954 13.9999 9.99994 13.1045 9.99994 11.9999C9.99994 11.8204 10.0234 11.6468 10.0675 11.4818L8.55386 9.96814Z"
            fill={fill}
          />
          <path
            d="M15.9922 11.7496C15.8681 9.73992 14.26 8.13182 12.2503 8.00771L8.79156 4.54897C9.77116 4.20697 10.8423 4 11.9999 4C14.8616 4 17.1946 5.26472 18.9232 6.70743C20.6494 8.14818 21.833 9.81429 22.4257 10.7528L22.4498 10.7908C22.5674 10.976 22.7201 11.2163 22.7973 11.5532C22.8597 11.8251 22.8597 12.1749 22.7973 12.4468C22.7201 12.7837 22.5674 13.024 22.4498 13.2092L22.4257 13.2472C22.0022 13.9177 21.2772 14.9597 20.2681 16.0255L15.9922 11.7496Z"
            fill={fill}
          />
        </React.Fragment>
      ) : (
        <path
          d="M10.7429 5.09232C11.1494 5.03223 11.5686 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7767C21.8518 11.9016 21.8517 12.0987 21.8231 12.2236C21.7849 12.3899 21.7164 12.4985 21.5792 12.7156C21.2793 13.1901 20.8222 13.8571 20.2165 14.5805M6.72432 6.71504C4.56225 8.1817 3.09445 10.2194 2.42111 11.2853C2.28428 11.5019 2.21587 11.6102 2.17774 11.7765C2.1491 11.9014 2.14909 12.0984 2.17771 12.2234C2.21583 12.3897 2.28393 12.4975 2.42013 12.7132C3.54554 14.4952 6.89541 19 12.0004 19C14.0588 19 15.8319 18.2676 17.2888 17.2766M3.00042 3L21.0004 21M9.8791 9.87868C9.3362 10.4216 9.00042 11.1716 9.00042 12C9.00042 13.6569 10.3436 15 12.0004 15C12.8288 15 13.5788 14.6642 14.1217 14.1213"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}
