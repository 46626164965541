import { createContext, useContext, useEffect, useState } from "react";
import { UserInfo } from "../types/common";
import { fetchUserInfo } from "../api/auth";
import { useLocalStorage } from "../util/local-storage";
import { dayjs } from "../util/date-util";

interface UserContextValue {
  userInfo: UserInfo | null;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo | null>>;
}
type UserContextProps = {
  children?: any;
};

const UserContext = createContext<UserContextValue | undefined>(undefined);

export default function UserProvider(props: UserContextProps) {
  const { children }: UserContextProps = props;

  const [localStorageValue, setLocalStorageValue] = useLocalStorage();
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [hasFetchedAdditionalUserInfo, setHasFetchedAdditionalUserInfo] =
    useState<boolean>(false);

  // Local timzone
  const browserTimezoneValue = dayjs.tz.guess();

  // fetches additional info such as name, customer, timezone, ect.
  const getAdditionalUserInfo = async () => {
    if (localStorageValue) {
      const parsedUserInfo = JSON.parse(localStorageValue);
      setHasFetchedAdditionalUserInfo(true);
      await fetchUserInfo()
        .then((resp) => {
          setLocalStorageValue({
            ...parsedUserInfo,
            admin_user: resp.admin_user,
            avatar: resp.avatar,
            customer_name: resp.customer_name,
            customer_id: resp.customer_id,
            allowed_customers: resp.allowed_customers,
            first_name: resp.first_name,
            last_name: resp.last_name,
            email: resp.email,
            // If no user timezone is set, use browser timezone.
            timezone: resp.timezone || browserTimezoneValue,
            profile: resp.profile,
          });
        })
        .catch(() => {
          console.error(
            "Something went wrong when fetching additional user info."
          );
        });
    }
  };

  useEffect(() => {
    if (typeof localStorageValue === "string") {
      setUserInfo(JSON.parse(localStorageValue));
      if (!hasFetchedAdditionalUserInfo) {
        getAdditionalUserInfo();
      }
    } else {
      setUserInfo(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorageValue]);

  return (
    <UserContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserContext.Provider>
  );
}

// Catch errors when useContent is used outside of provider
export const useUserContext = () => {
  const userContext = useContext(UserContext);
  if (userContext === undefined) {
    throw new Error("useUserContext must be inside a UserProvider");
  }
  return userContext;
};
