import classNames from "classnames";
import StyledButton from "../../../components/common/button/StyledButton";
import SignedOutTemplate from "../../../components/templates/SignedOutTemplate";
import { useSmBreakpoint } from "../../../util/breakpoints";
import SignedOutContainer from "../SignedOutContainer";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import StyledInput from "../../../components/form/input/StyledInput";
import ArrowIcon from "../../../components/common/svg/Arrow";
import { allColors } from "../../../util/colors";
import { SvgDirection, SvgType } from "../../../components/common/svg/types";
import ResetPasswordLockIcon from "./ResetPasswordLockIcon";
import SignUpMailIcon from "../sign-up/SignUpMailIcon";
import EyeHideIcon from "../../../components/common/svg/EyeHide";
import EyeShowIcon from "../../../components/common/svg/EyeShow";
import ResetPasswordKeyIcon from "./ResetPasswordKeyIcon";
import CheckmarkCircleIcon from "../../../components/common/svg/CheckmarkCircle";
import { ConditionalWrapper } from "../../../util/util";
import StyledTooltip from "../../../components/common/tooltip/StyledTooltip";
import { resetPassword, resetPasswordConfirm } from "../../../api/auth";
import Snackbar, {
  SnackbarType,
} from "../../../components/common/snackbar/Snackbar";

export type ResetPasswordProps = {
  classes?: string;
};

export enum ResetPasswordStep {
  EnterEmail = "0",
  EmailSent = "email-sent",
  EnterNewPassword = "1",
  PasswordChanged = "success",
}

export default function ResetPasswordPage(props: ResetPasswordProps) {
  const { classes }: ResetPasswordProps = props;

  const [stepParams, setStepParams] = useSearchParams();
  const { signature } = useParams();

  const isSm = useSmBreakpoint();
  const navigate = useNavigate();

  const [step, setStep] = useState(stepParams.get("step"));
  const [emailAddress, setEmailAddress] = useState(stepParams.get("email"));
  const [resendCountdown, setResendCountdown] = useState(0);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (signature) {
      setStepParams({
        step: ResetPasswordStep.EnterNewPassword,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature]);

  useEffect(() => {
    setStep(stepParams.get("step"));
    if (stepParams.get("email")) {
      setEmailAddress(stepParams.get("email"));
      stepParams.delete("email");
    }

    // If step param in url doesn't match any used values, remove the param
    if (
      step &&
      step !== ResetPasswordStep.EmailSent &&
      step !== ResetPasswordStep.EnterEmail &&
      step !== ResetPasswordStep.EnterNewPassword &&
      step !== ResetPasswordStep.PasswordChanged
    ) {
      setStepParams({});
      setFormErrorMessage(
        "The URL you requested did not match an associated page. If you believe this was an error, please contact Gatik support."
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepParams]);

  useEffect(() => {
    resendCountdown > 0 &&
      setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
  }, [resendCountdown]);

  const EmailSchema = Yup.object().shape({
    // have to use this long regex because Yup's email validation is not great
    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email address"
      )
      .required("Required"),
  });
  const PasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(9, "Must be at least 9 characters.")
      .matches(/(?=.*\W)/, "Must contain at least 1 symbol.")
      .matches(/(?=.*[A-Z])/, "Must contain at least 1 uppercase.")
      .matches(/(?=.*[a-z])/, "Must contain at least 1 lowercase.")
      .matches(/(?=.*\d)/, "Must contain at least 1 number.")
      .required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords don't match")
      .required("Required"),
  });

  const sendEmail = async (email: string | null) => {
    if (email) {
      await resetPassword({ email: email })
        .then(() => {
          setStepParams({
            step: ResetPasswordStep.EmailSent,
          });
        })
        .catch((error) => {
          if (error.response) {
            console.error(error.response.data);
          }
          if (error.response.data.detail) {
            setFormErrorMessage(error.response.data.detail);
          }
        });
      setEmailAddress(email);
      setResendCountdown(30);
    } else {
      console.error("Valid email address not found.");
      setStepParams({
        step: ResetPasswordStep.EnterEmail,
      });
    }
  };

  const confirmEmail = async (password: string | null, signature?: string) => {
    if (signature && password) {
      await resetPasswordConfirm({ password: password, signature: signature })
        .then((response) => {
          if (response.data.detail) {
            setStepParams({
              step: ResetPasswordStep.PasswordChanged,
            });
          }
        })
        .catch((error) => {
          if (error.response.data.password) {
            setFormErrorMessage(error.response.data.password);
          }
          if (error.response.data.signature) {
            setFormErrorMessage(error.response.data.signature);
          }
        });
    } else {
      console.error("Something went wrong. Please ensure a password is given.");
    }
  };

  const EnterEmailForm = () => {
    return (
      <React.Fragment>
        <div className="reset-password-page__image-container">
          <ResetPasswordLockIcon />
        </div>
        <div className="text-center mb-12 mt-16">
          <h1 className="text-display-xs sm:text-display-sm font-semibold mb-2">
            Reset password
          </h1>
          <p className="text-md text-gray-600 font-medium">
            Please enter the email address associated with your account.
          </p>
        </div>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={EmailSchema}
          onSubmit={(values) => {
            sendEmail(values.email);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            let isErrored = isSubmitting || typeof errors.email === "string";
            return (
              <form
                onSubmit={handleSubmit}
                className="flex flex-col items-stretch gap-10"
              >
                <StyledInput
                  type="email"
                  name="email"
                  label="Email"
                  placeholder="Enter your email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={touched.email ? errors.email : undefined}
                />
                <StyledButton
                  color="primary"
                  isSubmit
                  disabled={isErrored || values.email === ""}
                >
                  Send link{" "}
                  <ArrowIcon
                    width="20"
                    height="20"
                    stroke={
                      isErrored || values.email === ""
                        ? allColors.gray[400]
                        : allColors.white
                    }
                    direction={SvgDirection.Right}
                    classes="ml-4"
                  />
                </StyledButton>
              </form>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  };
  const EmailSent = () => {
    return (
      <React.Fragment>
        <div className="reset-password-page__image-container">
          <SignUpMailIcon />
        </div>
        <div className="text-center mb-12 mt-16">
          <h1 className="text-display-xs sm:text-display-sm font-semibold mb-2">
            Check your inbox
          </h1>
          <p className="text-md text-gray-600 font-medium">
            We've sent you an email. Please follow the instructions to reset
            your password.
          </p>
        </div>
        <StyledButton
          size={isSm ? "md" : "2xl"}
          color="primary"
          outlined
          classes="mb-6 w-full"
          disabled={resendCountdown !== 0}
          onClick={() => sendEmail(emailAddress)}
        >
          Resend email {resendCountdown !== 0 && `(${resendCountdown}s)`}
        </StyledButton>
        <div className="text-center mt-2">
          <StyledButton
            size="md"
            color="secondary"
            textOnly
            onClick={() => navigate("/login")}
          >
            Go back to login page
          </StyledButton>
        </div>
      </React.Fragment>
    );
  };
  const NewPasswordForm = () => {
    return (
      <React.Fragment>
        <div className="reset-password-page__image-container">
          <ResetPasswordKeyIcon />
        </div>
        <div className="text-center mb-12 mt-16">
          <h1 className="text-display-xs sm:text-display-sm font-semibold mb-2">
            Create new password
          </h1>
          <p className="text-md text-gray-600 font-medium">
            Please enter your new password.
          </p>
        </div>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={PasswordSchema}
          onSubmit={async (values, { setSubmitting }) => {
            confirmEmail(values.password, signature);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            const isErrored =
              typeof errors.password === "string" ||
              typeof errors.confirmPassword === "string" ||
              values.password === "" ||
              values.confirmPassword === "";

            const PasswordCriteriaText = (
              text: string,
              criteria: boolean | undefined | null
            ) => {
              return (
                <span
                  className={classNames("reset-password-page__password-rule", {
                    "reset-password-page__password-rule--success": criteria,
                  })}
                >
                  <CheckmarkCircleIcon
                    type={SvgType.Solid}
                    width="16"
                    height="16"
                    fill={
                      criteria ? allColors.success[600] : allColors.gray[500]
                    }
                    classes="mr-2 mb-px"
                  />
                  {text}
                </span>
              );
            };
            return (
              <React.Fragment>
                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col items-stretch gap-10"
                >
                  <StyledInput
                    type={showPassword ? "text" : "password"}
                    name="password"
                    label="Create password"
                    placeholder="Create password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={
                      touched.password && errors.password === "Required"
                        ? errors.password
                        : undefined
                    }
                    endIcon={
                      <button
                        type="button"
                        className="ml-4"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <span className="sr-only">
                          {showPassword ? "Hide password" : "Show password"}
                        </span>
                        {!showPassword ? (
                          <EyeHideIcon
                            type={SvgType.Solid}
                            fill={allColors.grayLightMode[500]}
                          />
                        ) : (
                          <EyeShowIcon
                            type={SvgType.Solid}
                            fill={allColors.grayLightMode[500]}
                          />
                        )}
                      </button>
                    }
                  />
                  <div className="flex flex-wrap gap-2">
                    {PasswordCriteriaText(
                      "9 characters",
                      values.password.length >= 9
                    )}
                    {PasswordCriteriaText(
                      "1 symbol",
                      /(?=.*\W)/.test(values.password)
                    )}
                    {PasswordCriteriaText(
                      "1 uppercase",
                      /(?=.*[A-Z])/.test(values.password)
                    )}
                    {PasswordCriteriaText(
                      "1 lowercase",
                      /(?=.*[a-z])/.test(values.password)
                    )}
                    {PasswordCriteriaText(
                      "1 number",
                      /(?=.*\d)/.test(values.password)
                    )}
                  </div>

                  <StyledInput
                    type={showPassword ? "text" : "password"}
                    name="confirmPassword"
                    label="Confirm password"
                    placeholder="Confirm new password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.confirmPassword}
                    error={
                      touched.confirmPassword
                        ? errors.confirmPassword
                        : undefined
                    }
                    endIcon={
                      <button
                        type="button"
                        className="ml-4"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <span className="sr-only">
                          {showPassword ? "Hide password" : "Show password"}
                        </span>
                        {!showPassword ? (
                          <EyeHideIcon
                            type={SvgType.Solid}
                            fill={allColors.grayLightMode[500]}
                          />
                        ) : (
                          <EyeShowIcon
                            type={SvgType.Solid}
                            fill={allColors.grayLightMode[500]}
                          />
                        )}
                      </button>
                    }
                  />
                  <ConditionalWrapper
                    condition={
                      typeof errors.password === "string" ||
                      typeof errors.confirmPassword === "string"
                    }
                    wrapper={(children: any) => {
                      return (
                        <StyledTooltip
                          placement="top"
                          header={errors.password || errors.confirmPassword}
                        >
                          {children}
                        </StyledTooltip>
                      );
                    }}
                  >
                    <StyledButton
                      classes="w-full"
                      color="primary"
                      isSubmit
                      disabled={isErrored}
                    >
                      Next{" "}
                      <ArrowIcon
                        width="20"
                        height="20"
                        stroke={
                          isErrored ? allColors.gray[400] : allColors.white
                        }
                        direction={SvgDirection.Right}
                        classes="ml-4"
                      />
                    </StyledButton>
                  </ConditionalWrapper>
                </form>
              </React.Fragment>
            );
          }}
        </Formik>
      </React.Fragment>
    );
  };
  const Success = () => {
    return (
      <React.Fragment>
        <div className="reset-password-page__image-container">
          <ResetPasswordKeyIcon />
        </div>
        <div className="text-center mb-12 mt-16">
          <h1 className="text-display-xs sm:text-display-sm font-semibold mb-2">
            Success!
          </h1>
          <p className="text-md text-gray-600 font-medium">
            Your password has been updated.
          </p>
        </div>
        <StyledButton
          size={isSm ? "md" : "2xl"}
          color="primary"
          classes="mb-6 w-full"
          onClick={() => navigate("/login")}
        >
          Go back to login page
        </StyledButton>
      </React.Fragment>
    );
  };

  return (
    <SignedOutTemplate>
      <SignedOutContainer
        innerContentBorderVariant={isSm}
        headerButtonType="sign_in"
      >
        <div className={classNames("reset-password-page", classes)}>
          {(!step || step === ResetPasswordStep.EnterEmail) && EnterEmailForm()}
          {step === ResetPasswordStep.EmailSent && EmailSent()}
          {step === ResetPasswordStep.EnterNewPassword && NewPasswordForm()}
          {step === ResetPasswordStep.PasswordChanged && Success()}
        </div>
        {formErrorMessage && (
          <Snackbar
            type={SnackbarType.Error}
            body={formErrorMessage}
            onDismiss={() => setFormErrorMessage(null)}
            containerClasses="reset-password-page__snackbar-container"
          />
        )}
      </SignedOutContainer>
    </SignedOutTemplate>
  );
}
