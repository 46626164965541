import { SvgProps } from "../../../components/common/svg/types";

export default function ResetPasswordLockIcon(props: SvgProps) {
  const { width = "290", height = "194" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 290 194"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M115.033 110.791H59.3965V35.1104H115.033V110.791ZM67.9128 102.274H106.517V43.6257H67.9128V102.274Z"
        fill="#C7D7FE"
      />
      <path
        d="M87.2141 148.28C69.1056 148.28 54.4258 133.6 54.4258 115.492V64.7861H120.002V115.493C120.003 133.6 105.323 148.28 87.2141 148.28Z"
        fill="#6172F3"
      />
      <path
        d="M87.2146 106.968C92.0211 106.968 95.9175 103.072 95.9175 98.2654C95.9175 93.4589 92.0211 89.5625 87.2146 89.5625C82.4081 89.5625 78.5117 93.4589 78.5117 98.2654C78.5117 103.072 82.4081 106.968 87.2146 106.968Z"
        fill="#1F235B"
      />
      <path
        d="M90.4886 96.6914H83.9414V118.495H90.4886V96.6914Z"
        fill="#1F235B"
      />
      <path
        d="M87.2139 73.6689H63.6543V83.3269H87.2139V73.6689Z"
        fill="white"
      />
      <path
        d="M221.723 70.4382C213.886 96.1912 208.021 120.921 189.068 122.095C186.47 122.258 183.62 121.984 180.487 121.198C180.487 121.198 180.539 123.905 176.304 125.519C173.183 126.7 167.739 127.299 158.224 125.779C142.643 123.297 144.164 115.612 144.164 115.612C133.93 112.654 132.789 100.877 132.789 100.877C124.455 93.5686 127.907 78.4896 140.377 69.7297C152.844 60.9794 166.671 54.1402 221.723 70.4382Z"
        fill="#C7D7FE"
      />
      <path
        d="M196.461 72.9155C195.887 74.0523 194.859 74.9658 193.392 75.2539C193.162 74.9475 192.888 74.6555 192.555 74.3897C192.198 74.1045 191.838 73.8464 191.477 73.5999C189.352 75.6638 187.224 77.7063 185.572 80.1791C182.532 84.7292 175.29 80.2448 178.341 75.6773C179.599 73.7933 181.061 72.0794 182.609 70.4428C181.03 70.2185 179.363 70.0764 177.578 69.9401C158.468 68.4785 165.689 72.5588 144.638 82.3347C144.971 82.4691 145.312 82.6673 145.644 82.9254C146.821 83.8311 148.01 84.7205 149.203 85.605C145.837 88.1154 143.397 91.8332 142.475 95.9454C141.974 98.1881 143.157 100.553 145.449 101.183C147.587 101.771 150.183 100.463 150.686 98.2093C151.389 95.0677 153.209 92.7921 156.095 91.3576C156.243 91.2841 156.365 91.1817 156.499 91.0889C157.696 92.0294 158.874 92.9903 160.024 93.986C160.052 93.9502 160.073 93.9115 160.102 93.8758C160.422 93.3045 160.599 92.7351 160.654 92.1918C161.184 92.7399 161.6 93.3151 161.873 93.9212C161.911 94.0034 161.941 94.0836 161.976 94.1735C163.786 95.8014 165.794 97.116 167.711 98.6511C168.932 99.6255 169.989 100.737 171.161 101.697C171.684 101.79 167.685 103.959 168.319 103.959C177.155 103.959 180.756 111.198 178.004 115.242C180.25 117.363 180.583 120.986 180.508 121.203C183.632 121.984 186.475 122.259 189.067 122.096C190.896 118.889 191.386 114.682 190.607 110.864C191.755 110.165 193.047 109.552 194.534 109.087C201.841 106.784 208.148 100.542 211.163 92.9971C214.739 84.0728 205.46 74.3452 196.461 72.9155Z"
        fill="#1F235B"
      />
      <path
        d="M162.668 116.166C159.651 116.166 156.423 114.955 153.468 113.62C153.254 113.523 153.159 113.271 153.256 113.057C153.352 112.842 153.606 112.749 153.819 112.844C158.189 114.821 163.163 116.524 166.914 114.172C170.385 111.996 170.369 107.298 168.884 103.855C168.791 103.638 168.89 103.388 169.106 103.295C169.324 103.201 169.572 103.302 169.666 103.518C171.291 107.284 171.264 112.451 167.367 114.894C165.916 115.802 164.324 116.166 162.668 116.166Z"
        fill="#1F235B"
      />
      <path
        d="M140.142 106.369C140.065 106.369 139.986 106.349 139.916 106.304C137.204 104.598 134.789 102.932 132.531 101.213C132.344 101.071 132.308 100.804 132.45 100.617C132.593 100.43 132.859 100.394 133.047 100.537C135.283 102.24 137.679 103.891 140.368 105.584C140.567 105.708 140.627 105.971 140.502 106.17C140.422 106.298 140.284 106.369 140.142 106.369Z"
        fill="#1F235B"
      />
      <path
        d="M221.726 70.4414C232.249 73.556 239.06 75.5725 239.06 75.5725C240.599 87.6268 234.897 107.497 226.069 119.454L199.675 111.641L221.726 70.4414Z"
        fill="#C7D7FE"
      />
      <path
        d="M163.041 119.01C156.135 119.01 148.652 117.804 144.014 116.015C143.794 115.93 143.685 115.683 143.77 115.464C143.854 115.243 144.101 115.137 144.321 115.219C152.728 118.465 170.618 119.8 176.788 115.323C178.235 114.273 178.927 112.947 178.844 111.384C178.666 108.021 176.433 103.842 172.643 102.721C172.418 102.654 172.29 102.417 172.356 102.192C172.423 101.967 172.661 101.84 172.886 101.904C177.05 103.137 179.502 107.685 179.694 111.339C179.793 113.209 178.983 114.782 177.287 116.012C174.358 118.138 168.907 119.01 163.041 119.01Z"
        fill="#1F235B"
      />
      <path
        d="M170.218 123.843C170.196 123.843 170.176 123.841 170.155 123.839C170.104 123.832 168.932 123.647 168.251 122.646C167.779 121.952 167.665 121.059 167.913 119.993C167.966 119.764 168.196 119.621 168.424 119.674C168.653 119.727 168.796 119.957 168.742 120.186C168.552 121.006 168.622 121.671 168.951 122.159C169.417 122.852 170.274 122.995 170.282 122.996C170.514 123.032 170.673 123.249 170.638 123.481C170.604 123.693 170.424 123.843 170.218 123.843Z"
        fill="#1F235B"
      />
      <path
        d="M170.146 110.735C169.934 110.735 169.75 110.577 169.724 110.36C169.696 110.126 169.863 109.914 170.096 109.886L174.193 109.397C174.429 109.361 174.638 109.536 174.666 109.769C174.694 110.003 174.527 110.215 174.294 110.243L170.197 110.732C170.18 110.734 170.163 110.735 170.146 110.735Z"
        fill="#1F235B"
      />
      <path
        d="M143.291 94.3203H99.3809V99.4891H143.291V94.3203Z"
        fill="#FEC84B"
      />
      <path
        d="M108.074 96.2549V110.663H116.284V96.2549H108.074Z"
        fill="#FEC84B"
      />
      <path
        d="M102.158 96.255V115.351H110.368V96.255H102.158Z"
        fill="#FEC84B"
      />
      <path
        d="M154.935 112.12C146.545 112.12 139.72 105.294 139.72 96.9048C139.72 88.5151 146.545 81.6895 154.935 81.6895C163.325 81.6895 170.15 88.5151 170.15 96.9048C170.15 105.294 163.325 112.12 154.935 112.12ZM154.935 86.7983C149.363 86.7983 144.83 91.332 144.83 96.9038C144.83 102.476 149.363 107.009 154.935 107.009C160.507 107.009 165.041 102.476 165.041 96.9038C165.041 91.332 160.508 86.7983 154.935 86.7983Z"
        fill="#FEC84B"
      />
      <path
        d="M221.023 97.2136C220.547 96.7225 219.961 96.3339 219.279 96.1077L225.869 73.8483L194.169 64.4639C145.903 93.1961 139.349 96.1029 144.167 103.959C148.879 111.641 161.411 108.939 181.288 96.854C184.31 103.009 190.745 107.585 197.876 109.06C207.449 111.04 218.474 110.717 226.216 103.894C230.129 100.444 225.06 94.6944 221.023 97.2136Z"
        fill="#C7D7FE"
      />
      <path
        d="M149.403 104.055C149.237 104.055 149.079 103.957 149.01 103.795C148.919 103.578 149.02 103.329 149.237 103.237C153.386 101.482 155.732 99.5442 156.023 97.6321C156.214 96.3735 155.508 95.0579 153.927 93.722C153.747 93.5702 153.725 93.3015 153.877 93.1226C154.028 92.9428 154.296 92.9206 154.477 93.0724C156.3 94.6123 157.103 96.1899 156.865 97.7607C156.522 100.013 154.068 102.119 149.569 104.023C149.515 104.044 149.458 104.055 149.403 104.055Z"
        fill="#C7D7FE"
      />
      <path
        d="M169.563 87.9155C169.429 87.9155 169.299 87.8527 169.216 87.7367L166.73 84.2451C166.594 84.0537 166.639 83.7878 166.83 83.6515C167.022 83.5162 167.287 83.5607 167.423 83.7511L169.909 87.2427C170.045 87.4341 170.001 87.6999 169.809 87.8362C169.735 87.8904 169.649 87.9155 169.563 87.9155Z"
        fill="white"
      />
      <path
        d="M205.901 110.857C191.888 110.857 183.301 103.033 180.892 97.0126C180.805 96.7951 180.911 96.5467 181.129 96.4597C181.347 96.3736 181.595 96.479 181.683 96.6965C184.484 103.699 196.027 113.22 215.072 108.943C215.303 108.896 215.529 109.036 215.581 109.266C215.632 109.495 215.488 109.723 215.259 109.775C211.913 110.525 208.792 110.857 205.901 110.857Z"
        fill="white"
      />
      <path
        d="M98.8974 84.6699C98.822 84.6699 98.7447 84.6496 98.6751 84.6071C98.475 84.4843 98.4112 84.2224 98.5349 84.0213L101.185 79.6916C101.308 79.4905 101.57 79.4267 101.771 79.5505C101.971 79.6732 102.035 79.9352 101.911 80.1363L99.2608 84.466C99.1806 84.5974 99.0405 84.6699 98.8974 84.6699Z"
        fill="white"
      />
      <path
        d="M103.272 89.9886C103.165 89.9886 103.058 89.949 102.975 89.8678C102.807 89.7044 102.803 89.4347 102.967 89.2655L105.405 86.758C105.568 86.5898 105.838 86.585 106.007 86.7493C106.175 86.9127 106.179 87.1824 106.016 87.3515L103.578 89.8591C103.495 89.9451 103.383 89.9886 103.272 89.9886Z"
        fill="white"
      />
      <path
        d="M161.954 43.9654C161.857 43.9654 161.76 43.9325 161.679 43.8648C161.499 43.7131 161.477 43.4443 161.629 43.2645L165.588 38.581C165.74 38.4012 166.009 38.378 166.189 38.5308C166.369 38.6825 166.391 38.9513 166.239 39.1311L162.28 43.8155C162.195 43.9141 162.075 43.9654 161.954 43.9654Z"
        fill="#FEC84B"
      />
      <path
        d="M168.321 144.902C168.202 144.902 168.083 144.852 167.998 144.754L163.611 139.66C163.457 139.482 163.478 139.213 163.656 139.059C163.832 138.907 164.101 138.925 164.256 139.104L168.643 144.198C168.796 144.376 168.776 144.645 168.598 144.798C168.518 144.868 168.419 144.902 168.321 144.902Z"
        fill="#FEC84B"
      />
      <path
        d="M160.651 141.111C160.532 141.111 160.412 141.061 160.328 140.963L157.013 137.112C156.86 136.934 156.88 136.664 157.058 136.511C157.235 136.359 157.505 136.377 157.658 136.556L160.973 140.407C161.127 140.585 161.106 140.855 160.928 141.007C160.848 141.077 160.75 141.111 160.651 141.111Z"
        fill="#FEC84B"
      />
    </svg>
  );
}
