import classNames from "classnames";
import {
  Stop,
  StopEventStatus,
  StopSequenceStatus,
  TimelinessStatus,
  TripStatus,
} from "../../../types/trip";
import { allColors } from "../../../util/colors";
import { SvgDirection, SvgType } from "../../common/svg/types";
import CheckmarkCircleIcon from "../../common/svg/CheckmarkCircle";
import StopProgressBar from "../stop-progress-bar/StopProgressBar";
import Badge, { BadgeColor, BadgeSize } from "../../common/badge/Badge";
import TruckIcon from "../../common/svg/Truck";
import { useState } from "react";
import ChevronIcon from "../../common/svg/Chevron";
import GoogleIcon from "../../common/svg/Google";
import StopTimelinessBadge from "../stop-timeliness-badge/StopTimelinessBadge";
import {
  dayjs,
  getTimezoneCorrectedDate,
  getTimezoneText,
  isDayAfter,
} from "../../../util/date-util";
import StyledTooltip from "../../common/tooltip/StyledTooltip";

export type StopCardProps = {
  stop: Stop;
  isOrigin?: boolean;
  isFinalDestination?: boolean;
  classes?: string;
};

export default function StopCard(props: StopCardProps) {
  const { stop, isOrigin, classes } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const timezoneText = () => {
    return stop.timezone ? getTimezoneText(stop.timezone) : "UTC";
  };
  const getTime = (date: string) => {
    return `${getTimezoneCorrectedDate(date, stop?.timezone).format("h:mm A")} ${timezoneText()}`;
  };
  const getFullDateTime = (date: string) => {
    return getTimezoneCorrectedDate(date, stop?.timezone).format("LLLL");
  };
  const isStopTimeDayAfter = (date: string) => {
    return (
      stop.trip_scheduled_start &&
      isDayAfter(stop.trip_scheduled_start, date, stop.timezone)
    );
  };

  const timeBetweenEstimatedAndActualDeparture = () => {
    const estimated = stop.scheduled_departure;
    const actual = stop.departed_at;
    let difference = null;
    if (estimated && actual) {
      difference = Math.abs(dayjs(estimated).diff(dayjs(actual), "minute"));
      return difference;
    } else {
      return null;
    }
  };

  const timeBetweenEstimatedAndActualArrival = () => {
    const estimated = stop.scheduled_arrival;
    const actual = stop.arrived_at;
    let difference = null;
    if (estimated && actual) {
      difference = Math.abs(dayjs(estimated).diff(dayjs(actual), "minute"));
      return difference;
    } else {
      return null;
    }
  };

  const statusHeaderContent =
    stop.event_status === StopEventStatus.AVDeparted ? (
      <>
        Completed{" "}
        <CheckmarkCircleIcon
          type={SvgType.Solid}
          fill={allColors.white}
          width="12"
          height="12"
          classes="ml-2"
        />
      </>
    ) : stop.event_status !== StopEventStatus.AwaitingAV && stop.arrived_at ? (
      `Arrived at ${getTime(stop.arrived_at)}`
    ) : stop.event_status === StopEventStatus.AVArrived ? (
      "Arrived"
    ) : stop.sequence_status === StopSequenceStatus.Skipped &&
      stop.trip_status === TripStatus.InProgress ? (
      "Likely skipped"
    ) : stop.sequence_status === StopSequenceStatus.Skipped ? (
      "Skipped"
    ) : stop.trip_status === TripStatus.InProgress &&
      stop.ett &&
      stop.stops_away === 0 ? (
      <span>
        ETA{" "}
        <b className="ml-4">
          {stop.scheduled_arrival
            ? `${getTime(stop.scheduled_arrival)} ${isStopTimeDayAfter(stop.scheduled_arrival) ? " +1" : ""}`
            : "--"}
        </b>
      </span>
    ) : stop.trip_status === TripStatus.InProgress &&
      stop.stops_away &&
      stop.stops_away > 0 ? (
      `${stop.stops_away} stops away`
    ) : stop.trip_status === TripStatus.InProgress ? (
      "Arriving soon"
    ) : stop.trip_status === TripStatus.Scheduled ? (
      <span>
        ETA{" "}
        <b className="ml-4">
          {stop.scheduled_arrival
            ? `${getTime(stop.scheduled_arrival)} ${isStopTimeDayAfter(stop.scheduled_arrival) ? " +1" : ""}`
            : "--"}
        </b>
      </span>
    ) : stop.trip_status === TripStatus.Cancelled ? (
      "Trip Cancelled"
    ) : stop.trip_status === TripStatus.TrackingTimeout ? (
      "Trip timed out"
    ) : null;

  const statusInnerContent =
    // origin is departed & late or early
    stop.event_status === StopEventStatus.AVDeparted &&
    isOrigin &&
    (stop.departure_status === TimelinessStatus.Early ||
      stop.departure_status === TimelinessStatus.Late)
      ? `AV departed ${timeBetweenEstimatedAndActualDeparture()} min ${stop.departure_status === TimelinessStatus.Early ? "early" : "late"}`
      : // non-origin is departed & is late or early
        stop.event_status === StopEventStatus.AVDeparted &&
          !isOrigin &&
          (stop.departure_status === TimelinessStatus.Early ||
            stop.departure_status === TimelinessStatus.Late)
        ? `AV arrived ${timeBetweenEstimatedAndActualArrival()} min ${stop.departure_status === TimelinessStatus.Early ? "early" : "late"}`
        : // departed on time
          stop.event_status === StopEventStatus.AVDeparted
          ? `AV completed stop activity`
          : stop.event_status === StopEventStatus.AVArrived
            ? `Loading in progress`
            : stop.sequence_status === StopSequenceStatus.Skipped &&
                stop.trip_status === TripStatus.InProgress
              ? "Likely skipped"
              : stop.sequence_status === StopSequenceStatus.Skipped
                ? "Skipped"
                : stop.sequence_status === StopSequenceStatus.OutOfSequence
                  ? "Stop out of sequence"
                  : stop.sequence_status === StopSequenceStatus.Unplanned
                    ? "Stop unplanned"
                    : stop.trip_status === TripStatus.InProgress &&
                        stop.ett &&
                        stop.stops_away === 0
                      ? `Arriving in ${stop.ett} min`
                      : stop.trip_status === TripStatus.InProgress &&
                          stop.stops_away &&
                          stop.stops_away > 0
                        ? `AV is ${stop.stops_away} stops away`
                        : stop.trip_status === TripStatus.InProgress
                          ? "Arriving soon"
                          : stop.trip_status === TripStatus.Scheduled
                            ? "Upcoming trip"
                            : stop.trip_status === TripStatus.Cancelled
                              ? "Trip Cancelled"
                              : stop.trip_status === TripStatus.TrackingTimeout
                                ? "Trip timed out"
                                : null;

  return (
    <div
      className={classNames(
        "stop-card",
        {
          "stop-card--scheduled": stop.trip_status === TripStatus.Scheduled,
          "stop-card--skipped":
            stop.sequence_status === StopSequenceStatus.Skipped,
          "stop-card--trip-in-progress":
            stop.trip_status === TripStatus.InProgress,
          "stop-card--stop-in-progress":
            stop.trip_status === TripStatus.InProgress && stop.stops_away === 0,
          "stop-card--arrived": stop.event_status === StopEventStatus.AVArrived,
          "stop-card--departed":
            stop.event_status === StopEventStatus.AVDeparted,
          "stop-card--cancelled": stop.trip_status === TripStatus.Cancelled,
        },
        classes
      )}
    >
      <div className="stop-card__status-header">
        {statusHeaderContent}
        {stop.sequence_status === StopSequenceStatus.OutOfSequence && (
          <i className="ml-2">(Out-of-sequence)</i>
        )}
      </div>
      <div className="stop-card__content">
        <div className="flex items-start justify-between">
          <h2 className="stop-card__site-name text-lg font-semibold mr-3">
            <span className="mr-4">{stop.site.properties.name}</span>
            {stop.site.properties.description && (
              <Badge
                size={BadgeSize.Sm}
                color={BadgeColor.Gray}
                rounded={false}
                startIcon={<GoogleIcon classes="mr-2" />}
                classes="inline-flex align-text-top mt-px"
                onClick={() =>
                  window
                    .open(
                      `https://www.google.com/maps/search/?api=1&query=${stop.site.properties.description}`,
                      "_blank"
                    )
                    ?.focus()
                }
              >
                <span className="text-xs">Maps</span>
              </Badge>
            )}
          </h2>
          {((isOrigin && stop.departure_status) || stop.arrival_status) && (
            <StopTimelinessBadge type={stop.arrival_status} />
          )}
        </div>
        <StopProgressBar stop={stop} cardVariant classes="mt-8" />
        <div className="stop-card__inner-content flex flex-col">
          <div className="stop-card__inner-content-header flex justify-between items-start px-6 py-4">
            <span className="font-semibold">{statusInnerContent}</span>
            {stop.av_id && (
              <Badge
                size={BadgeSize.Sm}
                color={BadgeColor.Gray}
                rounded={false}
                startIcon={
                  <TruckIcon
                    stroke={allColors.black}
                    width="12"
                    height="12"
                    classes="mr-3"
                  />
                }
                classes="ml-4 whitespace-nowrap"
              >
                <span className="text-xs">AV# {stop.av_id}</span>
              </Badge>
            )}
          </div>
          <div className="stop-card__inner-content-table">
            <div className="stop-card__inner-content-table-row">
              <div>Scheduled</div>
              <div>
                {stop.scheduled_arrival ? (
                  <StyledTooltip
                    placement="top"
                    header={`${getFullDateTime(stop.scheduled_arrival)} ${timezoneText()}`}
                  >
                    <span>
                      <b>{`${getTime(stop.scheduled_arrival)}`}</b>{" "}
                      {isStopTimeDayAfter(stop.scheduled_arrival) && <b>+1</b>}
                    </span>
                  </StyledTooltip>
                ) : (
                  "--"
                )}
              </div>
            </div>
          </div>
          {isExpanded && (
            <div className="stop-card__inner-content-table">
              <div className="stop-card__inner-content-table-row">
                <div>Lane ID</div>
                <div>{stop.lane_id ? stop.lane_id : "--"}</div>
              </div>
              <div className="stop-card__inner-content-table-row">
                <div>AV Number</div>
                <div>{stop.av_id ? stop.av_id : "--"}</div>
              </div>
            </div>
          )}
          <button
            className="stop-card__inner-content-hide-show-button"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? "Hide details" : "View more details"}{" "}
            <ChevronIcon
              direction={isExpanded ? SvgDirection.Up : SvgDirection.Down}
              width="12"
              height="12"
              classes="ml-2"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
