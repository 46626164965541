import classNames from "classnames";
import { ConditionalWrapper } from "../../../util/util";
import ProfileImage from "../profile-image/ProfileImage";
import StyledTooltip from "../tooltip/StyledTooltip";
import { UserInfo } from "../../../types/common";

export type ProfileInfoProps = {
  userInfo?: UserInfo | null;
  dotColor?: string;
  classes?: string;
  hideImage?: true;
};

export default function ProfileInfo(props: ProfileInfoProps) {
  const { userInfo, dotColor, classes, hideImage }: ProfileInfoProps = props;

  return (
    <div className={classNames("profile-info", classes)}>
      {!hideImage && (
        <ProfileImage
          userInfo={userInfo}
          dotColor={dotColor}
          classes="profile-info__profile-image"
        />
      )}
      <div className="profile-info__text">
        <span className="profile-info__name">
          {userInfo?.first_name
            ? `${userInfo?.first_name} ${userInfo?.last_name}`
            : "User"}
        </span>
        {userInfo?.email && (
          <ConditionalWrapper
            condition={userInfo?.email.length > 20}
            wrapper={(children: any) => {
              return (
                <StyledTooltip header={userInfo?.email} placement={"top"}>
                  {children}
                </StyledTooltip>
              );
            }}
          >
            <span className="profile-info__email">{userInfo?.email}</span>
          </ConditionalWrapper>
        )}
      </div>
    </div>
  );
}
