import classNames from "classnames";
import {
  Stop,
  StopEventStatus,
  StopSequenceStatus,
  TripStatus,
} from "../../../../types/trip";
import { allColors } from "../../../../util/colors";
import React from "react";
import CircleIcon from "../../../common/svg/Circle";
import { SvgType } from "../../../common/svg/types";
import HexagonIcon from "../../../common/svg/Hexagon";
import AlertTriangleIcon from "../../../common/svg/AlertTriangle";
import MapPinCircleIcon from "../../../common/svg/MapPinCircle";
import CheckmarkCircleIcon from "../../../common/svg/CheckmarkCircle";
import CloseLgIcon from "../../../common/svg/CloseLg";
import SkipArrowIcon from "../../../common/svg/SkipArrow";

export type TripTimelineIconProps = {
  stop: Stop;
  isOrigin?: boolean;
  isFinalDestination?: boolean;
  isLastDeparted?: boolean;
  isInTransit?: boolean;
  isActiveStop?: boolean;
  isItemOpen?: boolean;
  isPreviousStopSkipped?: boolean;
  timelineIconType: "stop" | "between";
  classes?: string;
};

export default function TripTimelineIcon(props: TripTimelineIconProps) {
  const {
    stop,
    isOrigin,
    isFinalDestination,
    isLastDeparted,
    isInTransit,
    isActiveStop,
    isItemOpen,
    isPreviousStopSkipped,
    timelineIconType,
    classes,
  } = props;

  const stopIcon = () => {
    if (stop.sequence_status === StopSequenceStatus.Skipped) {
      return (
        <div className="bg-gray-200 w-10 h-10 flex items-center justify-center rounded-full">
          <SkipArrowIcon width="14" height="14" fill={allColors.gray[700]} />
        </div>
      );
    } else if (stop.sequence_status === StopSequenceStatus.OutOfSequence) {
      return (
        <AlertTriangleIcon
          type={SvgType.Solid}
          width="20"
          height="20"
          fill={allColors.gray[700]}
        />
      );
    } else if (stop.trip_status === TripStatus.Cancelled && isActiveStop) {
      return (
        <div className="bg-error-200 w-10 h-10 flex items-center justify-center rounded-full">
          <CloseLgIcon width="16" height="16" stroke={allColors.error[600]} />
        </div>
      );
    } else if (isOrigin) {
      return (
        <HexagonIcon
          type={
            stop.event_status === StopEventStatus.AVDeparted
              ? SvgType.Solid
              : SvgType.Line
          }
          width="20"
          height="20"
        />
      );
    } else if (isFinalDestination) {
      return (
        <HexagonIcon
          type={
            stop.event_status !== StopEventStatus.AwaitingAV
              ? SvgType.Solid
              : SvgType.Line
          }
          width="20"
          height="20"
        />
      );
    } else if (stop.event_status === StopEventStatus.AVArrived) {
      return (
        <MapPinCircleIcon
          width="24"
          height="24"
          fill={allColors.primary[100]}
          innerFill={allColors.primary[700]}
        />
      );
    } else if (stop.event_status === StopEventStatus.AVDeparted) {
      return (
        <CheckmarkCircleIcon
          type={SvgType.Solid}
          width="20"
          height="20"
          fill={allColors.gray[700]}
        />
      );
    } else {
      return (
        <div className="bg-gray-700 w-10 h-10 flex items-center justify-center rounded-full">
          <CircleIcon
            type={SvgType.Solid}
            width="18"
            height="18"
            fill={allColors.white}
          />
        </div>
      );
    }
  };

  return (
    <div
      className={classNames(
        "trip-timeline-icon",
        `trip-timeline-icon--${timelineIconType}`,
        {
          "trip-timeline-icon--active": isActiveStop && !isFinalDestination,
          "trip-timeline-icon--arrived":
            stop.event_status === StopEventStatus.AVArrived,
          "trip-timeline-icon--departed":
            stop.event_status === StopEventStatus.AVDeparted,
          "trip-timeline-icon--last-departed": isLastDeparted,
          "trip-timeline-icon--in-transit": isInTransit,
          "trip-timeline-icon--cancelled":
            stop.trip_status === TripStatus.Cancelled,
          "trip-timeline-icon--completed":
            stop.trip_status === TripStatus.Completed,
          "trip-timeline-icon--table-open": isItemOpen,
          "trip-timeline-icon--skipped":
            stop.sequence_status === StopSequenceStatus.Skipped,
          "trip-timeline-icon--previous-stop-skipped": isPreviousStopSkipped,
        },
        classes
      )}
    >
      {timelineIconType === "between" && (
        <React.Fragment>
          <div
            className={classNames(
              "trip-timeline-icon__line trip-timeline-icon__line--top",
              {
                "trip-timeline-icon__line--dashed":
                  (stop.event_status !== StopEventStatus.AVDeparted &&
                    !isActiveStop &&
                    stop.sequence_status !== StopSequenceStatus.Skipped) ||
                  isPreviousStopSkipped,
              }
            )}
          ></div>
          <div className="trip-timeline-icon__icon">
            {isActiveStop &&
            isInTransit &&
            stop.trip_status !== TripStatus.Cancelled ? (
              <CircleIcon
                type={SvgType.Solid}
                width="16"
                height="16"
                fill={allColors.primary[600]}
              />
            ) : (
              <CircleIcon
                type={SvgType.Solid}
                width="8"
                height="8"
                fill={allColors.black}
              />
            )}
          </div>
          <div
            className={classNames(
              "trip-timeline-icon__line trip-timeline-icon__line--bottom",
              {
                "trip-timeline-icon__line--dashed":
                  (stop.event_status !== StopEventStatus.AVDeparted &&
                    !isActiveStop) ||
                  (isActiveStop &&
                    isInTransit &&
                    stop.trip_status === TripStatus.Cancelled),
              }
            )}
          ></div>
        </React.Fragment>
      )}
      {timelineIconType === "stop" && (
        <React.Fragment>
          <div className="trip-timeline-icon__icon">{stopIcon()}</div>
          {!isFinalDestination && (
            <div
              className={classNames(
                "trip-timeline-icon__line trip-timeline-icon__line--bottom",
                {
                  "trip-timeline-icon__line--dashed":
                    stop.event_status !== StopEventStatus.AVDeparted,
                }
              )}
            ></div>
          )}
        </React.Fragment>
      )}
    </div>
  );
}
