import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps, SvgType } from "./types";

export default function PhoneCallIcon(props: SvgProps) {
  const {
    type = SvgType.Line,
    width = "24",
    height = "24",
    fill = allColors.black,
    stroke = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__phone-call", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      {type === SvgType.Solid ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0685 5.80859C13.1743 5.26653 13.6995 4.91284 14.2415 5.01859C15.4136 5.24727 16.4908 5.8205 17.3352 6.66492C18.1796 7.50933 18.7528 8.58651 18.9815 9.75859C19.0873 10.3007 18.7336 10.8258 18.1915 10.9316C17.6495 11.0373 17.1243 10.6836 17.0185 10.1416C16.8661 9.36019 16.4839 8.64207 15.921 8.07913C15.358 7.51619 14.6399 7.13403 13.8585 6.98158C13.3165 6.87582 12.9628 6.35066 13.0685 5.80859Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0561 1.88968C13.1171 1.34077 13.6115 0.945223 14.1604 1.0062C16.4152 1.25669 18.5178 2.2664 20.1229 3.86954C21.7281 5.47269 22.7404 7.57399 22.9938 9.82842C23.0554 10.3773 22.6605 10.8722 22.1117 10.9338C21.5629 10.9955 21.068 10.6006 21.0063 10.0518C20.8036 8.2482 19.9937 6.56717 18.7096 5.28465C17.4255 4.00213 15.7434 3.19436 13.9396 2.99397C13.3907 2.93299 12.9952 2.43858 13.0561 1.88968Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.33792 17.7522C3.90401 15.3183 2.13419 12.2874 1.1936 8.97814C1.0461 8.45912 0.93171 8.05665 0.930066 7.50476C0.928189 6.87399 1.1337 6.08375 1.44263 5.5338C1.97391 4.58803 3.11467 3.37598 4.1 2.87761C4.95134 2.447 5.95674 2.447 6.80808 2.87761C7.64373 3.30028 8.6023 4.27868 9.11122 5.06143C9.75771 6.05577 9.75771 7.33764 9.11122 8.33197C8.93798 8.59842 8.69105 8.84493 8.40439 9.13111C8.31513 9.22022 8.21688 9.28461 8.28212 9.4205C8.92995 10.7698 9.81343 12.0354 10.9341 13.156C12.0548 14.2767 13.3204 15.1602 14.6697 15.808C14.8094 15.8751 14.8666 15.7784 14.9591 15.6858C15.2452 15.3991 15.4917 15.1522 15.7582 14.9789C16.7525 14.3325 18.0344 14.3325 19.0287 14.9789C19.8115 15.4879 20.7899 16.4464 21.2126 17.2821C21.6432 18.1334 21.6432 19.1388 21.2126 19.9902C20.7179 20.9681 19.5158 22.1086 18.5564 22.6475C18.0064 22.9565 17.2162 23.162 16.5854 23.1601C16.0335 23.1585 15.631 23.0441 15.112 22.8966C11.8028 21.9561 8.7718 20.1861 6.33792 17.7522Z"
            fill={fill}
          />
        </>
      ) : (
        <path
          d="M14.0497 6C15.0264 6.19057 15.924 6.66826 16.6277 7.37194C17.3314 8.07561 17.8091 8.97326 17.9997 9.95M14.0497 2C16.0789 2.22544 17.9713 3.13417 19.4159 4.57701C20.8606 6.01984 21.7717 7.91101 21.9997 9.94M10.2266 13.8631C9.02506 12.6615 8.07627 11.3028 7.38028 9.85323C7.32041 9.72854 7.29048 9.66619 7.26748 9.5873C7.18576 9.30695 7.24446 8.96269 7.41447 8.72526C7.46231 8.65845 7.51947 8.60129 7.63378 8.48698C7.98338 8.13737 8.15819 7.96257 8.27247 7.78679C8.70347 7.1239 8.70347 6.26932 8.27247 5.60643C8.15819 5.43065 7.98338 5.25585 7.63378 4.90624L7.43891 4.71137C6.90747 4.17993 6.64174 3.91421 6.35636 3.76987C5.7888 3.4828 5.11854 3.4828 4.55098 3.76987C4.2656 3.91421 3.99987 4.17993 3.46843 4.71137L3.3108 4.86901C2.78117 5.39863 2.51636 5.66344 2.31411 6.02348C2.08969 6.42298 1.92833 7.04347 1.9297 7.5017C1.93092 7.91464 2.01103 8.19687 2.17124 8.76131C3.03221 11.7947 4.65668 14.6571 7.04466 17.045C9.43264 19.433 12.295 21.0575 15.3284 21.9185C15.8928 22.0787 16.1751 22.1588 16.588 22.16C17.0462 22.1614 17.6667 22 18.0662 21.7756C18.4263 21.5733 18.6911 21.3085 19.2207 20.7789L19.3783 20.6213C19.9098 20.0898 20.1755 19.8241 20.3198 19.5387C20.6069 18.9712 20.6069 18.3009 20.3198 17.7333C20.1755 17.448 19.9098 17.1822 19.3783 16.6508L19.1835 16.4559C18.8339 16.1063 18.6591 15.9315 18.4833 15.8172C17.8204 15.3862 16.9658 15.3862 16.3029 15.8172C16.1271 15.9315 15.9523 16.1063 15.6027 16.4559C15.4884 16.5702 15.4313 16.6274 15.3644 16.6752C15.127 16.8453 14.7828 16.904 14.5024 16.8222C14.4235 16.7992 14.3612 16.7693 14.2365 16.7094C12.7869 16.0134 11.4282 15.0646 10.2266 13.8631Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}
