import classNames from "classnames";
import FilterFunnelIcon from "../svg/FilterFunnel";
import { allColors } from "../../../util/colors";

export type NoResultsProps = {
  classes?: string;
};

export default function NoResults(props: NoResultsProps) {
  const { classes }: NoResultsProps = props;
  return (
    <div
      className={classNames(
        "no-results flex flex-col items-center justify-center",
        classes
      )}
    >
      <FilterFunnelIcon width="48" height="48" stroke={allColors.gray[600]} />
      <p className="text-xl font-semibold my-4">No search results found</p>
      <p className="text-lg">Please try again with a different search query</p>
    </div>
  );
}
