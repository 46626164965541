import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps, SvgType } from "./types";

export default function MessageChatQuestionIcon(props: SvgProps) {
  const {
    type = SvgType.Line,
    width = "24",
    height = "24",
    fill = allColors.black,
    stroke = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__message-chat-question", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      {type === SvgType.Solid ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 2C7.25334 2 3.00005 6.25329 3.00005 11.5C3.00005 12.56 3.17404 13.5814 3.4958 14.536C3.55239 14.7039 3.58571 14.8033 3.60736 14.8766L3.61444 14.9012L3.61069 14.909C3.58499 14.962 3.54679 15.0332 3.47533 15.1655L1.82345 18.223C1.72107 18.4124 1.61425 18.6101 1.54078 18.7819C1.46776 18.9527 1.34948 19.2661 1.40768 19.6376C1.4759 20.073 1.73218 20.4564 2.10846 20.6859C2.42947 20.8818 2.76426 20.8923 2.95 20.8901C3.13688 20.8879 3.3603 20.8648 3.57447 20.8426L8.72943 20.3097C8.81199 20.3012 8.85515 20.2968 8.88677 20.2944L8.89084 20.2941L8.90321 20.2984C8.94206 20.3124 8.99475 20.3326 9.0857 20.3677C10.1466 20.7764 11.2984 21 12.5 21C17.7468 21 22 16.7467 22 11.5C22 6.25329 17.7468 2 12.5 2ZM11.9884 8.67211C12.2426 8.52274 12.5414 8.46814 12.832 8.51797C13.1225 8.56781 13.3861 8.71888 13.5759 8.94441C13.7658 9.16994 13.8697 9.45539 13.8693 9.75019V9.75168C13.8693 9.97025 13.6921 10.2714 13.1904 10.6059C12.97 10.7528 12.7425 10.8673 12.5661 10.9457C12.4793 10.9842 12.4084 11.0125 12.3616 11.0304C12.3382 11.0393 12.3211 11.0455 12.3112 11.049L12.302 11.0522L12.3036 11.0517C11.7804 11.2268 11.4977 11.7927 11.6723 12.3162C11.8452 12.835 12.4216 13.1201 12.9384 12.9483C12.9838 12.933 13.0288 12.9164 13.0735 12.8994C13.1496 12.8704 13.2544 12.8284 13.3784 12.7733C13.6236 12.6643 13.9581 12.4978 14.2998 12.27C14.9222 11.855 15.8691 11.0322 15.8693 9.75212C15.8702 8.98537 15.5998 8.24301 15.106 7.65641C14.612 7.06954 13.9262 6.67645 13.1701 6.54676C12.414 6.41707 11.6364 6.55916 10.9751 6.94784C10.3137 7.33653 9.81126 7.94674 9.55669 8.67039C9.37342 9.19138 9.6472 9.7623 10.1682 9.94557C10.6892 10.1288 11.2601 9.85507 11.4434 9.33408C11.5412 9.05598 11.7343 8.82148 11.9884 8.67211ZM12.6499 14C12.0976 14 11.6499 14.4477 11.6499 15C11.6499 15.5523 12.0976 16 12.6499 16H12.6599C13.2122 16 13.6599 15.5523 13.6599 15C13.6599 14.4477 13.2122 14 12.6599 14H12.6499Z"
          fill={fill}
        />
      ) : (
        <path
          d="M10.4996 9.00224C10.6758 8.50136 11.0236 8.079 11.4814 7.80998C11.9391 7.54095 12.4773 7.4426 13.0006 7.53237C13.524 7.62213 13.9986 7.89421 14.3406 8.30041C14.6825 8.70661 14.8697 9.22072 14.8689 9.75168C14.8689 11.2506 12.6205 12 12.6205 12M12.6495 15H12.6595M12.4996 20C17.194 20 20.9996 16.1944 20.9996 11.5C20.9996 6.80558 17.194 3 12.4996 3C7.8052 3 3.99962 6.80558 3.99962 11.5C3.99962 12.45 4.15547 13.3636 4.443 14.2166C4.55119 14.5376 4.60529 14.6981 4.61505 14.8214C4.62469 14.9432 4.6174 15.0286 4.58728 15.1469C4.55677 15.2668 4.48942 15.3915 4.35472 15.6408L2.71906 18.6684C2.48575 19.1002 2.36909 19.3161 2.3952 19.4828C2.41794 19.6279 2.50337 19.7557 2.6288 19.8322C2.7728 19.9201 3.01692 19.8948 3.50517 19.8444L8.62619 19.315C8.78127 19.299 8.85881 19.291 8.92949 19.2937C8.999 19.2963 9.04807 19.3029 9.11586 19.3185C9.18478 19.3344 9.27145 19.3678 9.44478 19.4345C10.3928 19.7998 11.4228 20 12.4996 20Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}
