import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SettingsPage from "./pages/settings/SettingsPage";
import UserProvider from "./context/UserContext";
import OverlayProvider from "./context/OverlayContext";
import SignUpPage from "./pages/signed-out/sign-up/SignUpPage";
import SignInPage from "./pages/signed-out/sign-in/SignInPage";
import LandingPage from "./pages/signed-out/landing-page/LandingPage";
import ResetPasswordPage from "./pages/signed-out/reset-password/ResetPasswordPage";
import { ProtectedRoute } from "./components/templates/ProtectedRoute";
import PageNotFound from "./pages/page-not-found/PageNotFound";
import { useSmBreakpoint } from "./util/breakpoints";
import ResetPasswordRedirect from "./pages/signed-out/reset-password/ResetPasswordRedirect";
import LiveTrackingPage from "./pages/live-tracking/LiveTrackingPage";

import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import theme from "./util/theme";
import { BrowserRouter } from "react-router-dom";
// import LogRocket from "logrocket";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import LiveTrackingRedirect from "./pages/live-tracking/LiveTrackingRedirect";

dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const [inputMethod, setInputMethod] = useState<"mouse" | "keyboard">("mouse");
  const isSm = useSmBreakpoint();

  useEffect(() => {
    // This function sets the user's input method for the entire page and adds a data attribute to the container div.
    // This data attribute is then used to allow different focus states for mouse and keyboard navigation.
    function handleKeyDown() {
      setInputMethod("keyboard");
    }
    function handleMouseClick() {
      setInputMethod("mouse");
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleMouseClick);

    // Clean up event listeners
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleMouseClick);
    };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DndProvider backend={HTML5Backend}>
              <div className="App" id="App" data-input-method={inputMethod}>
                <OverlayProvider>
                  <UserProvider>
                    <Routes>
                      <Route
                        index
                        element={
                          <Navigate to={isSm ? "/login" : "/landing"} replace />
                        }
                      />
                      <Route path="/landing" element={<LandingPage />} />
                      <Route path="/login" element={<SignInPage />} />
                      <Route path="/sign-up" element={<SignUpPage />} />
                      {/* Redirect from old /account/password-reset/ url to the new one */}
                      <Route
                        path="/account/password-reset/:signature/*"
                        element={<ResetPasswordRedirect />}
                      />
                      <Route
                        path="/reset-password/:signature?"
                        element={<ResetPasswordPage />}
                      />
                      <Route path="/" element={<ProtectedRoute />}>
                        <Route
                          path="live-tracking/:tab?"
                          element={<LiveTrackingPage />}
                        />
                        <Route
                          path="settings/:tab?"
                          element={<SettingsPage />}
                        />

                        {/* Redirect from old /trips/ url to new live-tracking url. */}
                        <Route
                          path="trips/details/:id?/*"
                          element={<LiveTrackingRedirect />}
                        />
                        <Route
                          path="trips"
                          element={<Navigate to={"/live-tracking/"} replace />}
                        />
                      </Route>
                      <Route path="*" element={<PageNotFound />} />
                    </Routes>
                  </UserProvider>
                </OverlayProvider>
              </div>
            </DndProvider>
          </LocalizationProvider>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
