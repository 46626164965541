import { SvgProps } from "../../../components/common/svg/types";

export default function ResetPasswordKeyIcon(props: SvgProps) {
  const { width = "266", height = "178" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 266 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M151.792 115.98C151.586 115.475 151.322 114.905 151.021 114.295C153.166 109.771 152.57 103.98 149.309 100.122C146.71 97.044 143.081 95.5189 139.296 95.4444C133.343 86.7063 127.605 78.8664 127.605 78.8664C127.605 78.8664 132.769 64.8243 130.168 60.0673C127.724 55.5976 122.942 56.9551 119.302 62.9383C118.252 61.4088 116.264 60.2597 112.511 60.9965C109.217 61.6429 107.405 66.039 106.234 71.9141C105.686 71.9336 105.091 71.9974 104.432 72.1269C97.6468 73.4577 97.1449 90.6795 95.5906 104.021C93.5158 118.972 113.887 127.271 119.866 137.127C119.866 137.127 156.396 133.777 157.346 126.815C158.296 119.853 155.538 125.169 151.792 115.98Z"
        fill="#C7D7FE"
      />
      <path
        d="M120.257 78.6119C122.38 82.0841 127.255 84.7184 130.293 82.5833C128.644 80.2842 127.606 78.8664 127.606 78.8664C127.606 78.8664 132.769 64.8242 130.169 60.0673C127.724 55.5967 122.941 56.9533 119.301 62.9374C120.447 67.5295 120.51 75.7772 120.257 78.6119Z"
        fill="#1F235B"
      />
      <path
        d="M157.54 150.771C146.249 154.227 133.252 154.227 118.549 150.771V123.41H157.54V150.771Z"
        fill="#C7D7FE"
      />
      <path
        d="M115.966 107.754C115.878 107.754 115.79 107.721 115.721 107.655L108.52 100.708C108.437 100.628 108.398 100.513 108.417 100.399C110.112 89.8465 111.65 77.2416 108.483 73.3979C107.879 72.6655 107.14 72.2949 106.223 72.2639C106.029 72.2577 105.877 72.0945 105.883 71.9003C105.889 71.7053 106.063 71.5519 106.247 71.5599C107.362 71.5971 108.297 72.0644 109.027 72.9502C112.336 76.9668 110.953 89.0183 109.142 100.329L116.212 107.148C116.352 107.284 116.356 107.506 116.221 107.646C116.151 107.717 116.058 107.754 115.966 107.754Z"
        fill="#1F235B"
      />
      <path
        d="M124.566 98.9624C124.468 98.9624 124.369 98.9207 124.3 98.8409L117.904 91.4639C117.836 91.3858 117.806 91.2812 117.822 91.1792C117.901 90.6658 118.015 90.0026 118.151 89.2135C118.997 84.2898 120.571 75.1244 120.042 66.4918C120.03 66.2976 120.178 66.13 120.372 66.1185C120.578 66.1105 120.734 66.2541 120.745 66.4483C121.279 75.1625 119.696 84.3803 118.844 89.3323C118.724 90.0363 118.62 90.6392 118.543 91.1251L124.832 98.379C124.96 98.5261 124.944 98.7487 124.797 98.8764C124.73 98.934 124.648 98.9624 124.566 98.9624Z"
        fill="#1F235B"
      />
      <path
        d="M98.4455 83.4229C98.2833 83.4229 98.1379 83.3103 98.1015 83.1462C98.0598 82.9556 98.1804 82.7676 98.3702 82.7269C102.373 81.8517 102.94 74.3434 102.945 74.2681C102.96 74.0739 103.127 73.9231 103.321 73.9409C103.515 73.9542 103.662 74.1226 103.648 74.3159C103.625 74.6467 103.038 82.4272 98.5209 83.414C98.4961 83.4202 98.4704 83.4229 98.4455 83.4229Z"
        fill="white"
      />
      <path
        d="M114.088 109.896C113.996 109.896 113.904 109.859 113.835 109.788C113.7 109.648 113.703 109.426 113.843 109.29L129.021 94.6335C129.161 94.4988 129.385 94.5032 129.52 94.6424C129.654 94.7825 129.651 95.005 129.511 95.1407L114.333 109.797C114.265 109.863 114.177 109.896 114.088 109.896Z"
        fill="#1F235B"
      />
      <path
        d="M132.212 123.762C132.039 123.762 131.888 123.633 131.863 123.457C131.821 123.15 130.734 115.938 121.399 116.763C121.205 116.784 121.034 116.637 121.016 116.443C121 116.249 121.142 116.079 121.337 116.061C131.364 115.181 132.551 123.281 132.562 123.363C132.587 123.555 132.452 123.732 132.259 123.758C132.243 123.76 132.228 123.762 132.212 123.762Z"
        fill="#1F235B"
      />
      <path
        d="M143.877 67.8332H124.18V48.1377H143.877V67.8332ZM128.457 63.5559H139.598V52.415H128.457V63.5559Z"
        fill="#FEC84B"
      />
      <path
        d="M136.167 26.2598H131.89V50.2751H136.167V26.2598Z"
        fill="#FEC84B"
      />
      <path
        d="M134.566 29.1318H127.771V35.9264H134.566V29.1318Z"
        fill="#FEC84B"
      />
      <path
        d="M139.599 62.9619V63.556H133.885C133.541 64.9667 133.309 66.4013 133.222 67.8333H143.876V63.4185C142.447 63.2953 141.02 63.1463 139.599 62.9619Z"
        fill="#1F235B"
      />
      <path
        d="M146.605 105.27L149.74 83.354C149.74 83.354 136.109 75.171 135.153 66.8984C134.062 57.4456 143.502 61.2672 151.274 66.5854C159.047 71.9045 164.829 75.0282 166.492 86.5868C167.67 94.7681 168.911 124.228 154.306 132.363C139.701 140.499 146.605 105.27 146.605 105.27Z"
        fill="#C7D7FE"
      />
      <path
        d="M144.731 66.5149C142.722 66.5149 140.069 65.7196 137.755 62.3893C137.645 62.2297 137.684 62.0098 137.844 61.8989C138.004 61.7881 138.224 61.8271 138.334 61.9876C142.381 67.8121 147.332 65.3312 147.541 65.2231C147.713 65.1344 147.925 65.2 148.016 65.3729C148.105 65.5449 148.04 65.7577 147.867 65.8482C147.841 65.8615 146.551 66.5149 144.731 66.5149Z"
        fill="white"
      />
      <path
        d="M135.929 120.077C135.753 120.077 135.601 119.945 135.579 119.766C135.562 119.615 133.924 104.629 147.477 98.347C147.655 98.2654 147.863 98.3426 147.945 98.5181C148.026 98.6946 147.95 98.9038 147.774 98.9863C134.692 105.05 136.262 119.537 136.28 119.682C136.303 119.875 136.165 120.051 135.972 120.074C135.957 120.076 135.943 120.077 135.929 120.077Z"
        fill="#1F235B"
      />
      <path
        d="M154.774 85.8025C154.729 85.8025 154.683 85.7937 154.638 85.7751L149.603 83.6799C149.423 83.6054 149.339 83.3988 149.413 83.2188C149.488 83.0388 149.695 82.9546 149.874 83.029L154.909 85.1251C155.089 85.1996 155.173 85.4062 155.099 85.5862C155.043 85.721 154.912 85.8025 154.774 85.8025Z"
        fill="white"
      />
      <path
        d="M165.935 137.485C165.868 137.485 165.801 137.467 165.74 137.427C165.578 137.319 165.534 137.1 165.641 136.938L168.066 133.273C168.173 133.11 168.391 133.065 168.555 133.173C168.717 133.281 168.761 133.5 168.654 133.662L166.229 137.327C166.161 137.429 166.048 137.485 165.935 137.485Z"
        fill="#3538CD"
      />
      <path
        d="M141.925 28.2992C141.829 28.2992 141.731 28.2593 141.662 28.1804C141.532 28.035 141.546 27.8124 141.691 27.6829L145.49 24.3101C145.636 24.1806 145.858 24.193 145.988 24.3393C146.117 24.4847 146.104 24.7073 145.959 24.8367L142.159 28.2096C142.092 28.2699 142.009 28.2992 141.925 28.2992Z"
        fill="#3538CD"
      />
      <path
        d="M138.55 22.9725C138.487 22.9725 138.424 22.9557 138.366 22.9211C138.2 22.82 138.148 22.6028 138.249 22.437L142.317 15.7763C142.419 15.6105 142.636 15.5573 142.801 15.6593C142.967 15.7604 143.02 15.9776 142.918 16.1434L138.85 22.8041C138.784 22.9131 138.669 22.9725 138.55 22.9725Z"
        fill="#3538CD"
      />
      <path
        d="M121.677 32.29C121.604 32.29 121.53 32.267 121.467 32.22L117.96 29.6061C117.803 29.4899 117.772 29.2691 117.888 29.1131C118.005 28.9579 118.225 28.9251 118.381 29.0413L121.888 31.6552C122.044 31.7713 122.076 31.9921 121.96 32.1482C121.891 32.2413 121.786 32.29 121.677 32.29Z"
        fill="#3538CD"
      />
      <path
        d="M99.3519 130.911C99.2615 130.911 99.1719 130.876 99.1027 130.808L96.1253 127.831C96.0588 127.764 96.0225 127.676 96.0225 127.582C96.0225 127.488 96.0597 127.399 96.1253 127.332L99.1027 124.355C99.2402 124.218 99.4636 124.218 99.601 124.355L102.578 127.332C102.716 127.47 102.716 127.693 102.578 127.831L99.601 130.808C99.5328 130.876 99.4423 130.911 99.3519 130.911ZM96.8728 127.582L99.3519 130.061L101.831 127.582L99.3519 125.102L96.8728 127.582Z"
        fill="#3538CD"
      />
    </svg>
  );
}
