import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps } from "./types";

export default function SortArrowsIcon(props: SvgProps) {
  const {
    width = "24",
    height = "24",
    stroke = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__sort-arrows", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17 4V20M17 20L13 16M17 20L21 16M7 20V4M7 4L3 8M7 4L11 8"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
