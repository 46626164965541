import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import StyledTabs, { TabType } from "../../components/common/tabs/StyledTabs";
import React, { useEffect, useMemo, useState } from "react";
import { useSmBreakpoint } from "../../util/breakpoints";
import { Formik } from "formik";
import * as Yup from "yup";
import Snackbar, {
  SnackbarType,
} from "../../components/common/snackbar/Snackbar";
import StyledInput from "../../components/form/input/StyledInput";
import StyledButton from "../../components/common/button/StyledButton";
import SaveIcon from "../../components/common/svg/Save";
import { allColors } from "../../util/colors";
import { useUserContext } from "../../context/UserContext";
import MailIcon from "../../components/common/svg/Mail";
import ProfileImage from "../../components/common/profile-image/ProfileImage";
import { useLocalStorage } from "../../util/local-storage";
import EyeHideIcon from "../../components/common/svg/EyeHide";
import EyeShowIcon from "../../components/common/svg/EyeShow";
import { SvgType } from "../../components/common/svg/types";
import CheckmarkCircleIcon from "../../components/common/svg/CheckmarkCircle";
import {
  FetchUserJobsResponseBody,
  UpdateUserInfoRequestBody,
  fetchUserJobs,
  updateUserInfo,
} from "../../api/auth";
import { AutocompleteItem } from "../../components/form/autocomplete/types";
import { JobCategoryType } from "../../types/common";
import SingleAutocomplete from "../../components/form/autocomplete/SingleAutocomplete";
import LoadingSpinner from "../../components/common/loading-spinner/LoadingSpinner";
import TimezoneFilter, {
  timezoneOptionsWithBrowserTimezone,
} from "../../components/form/autocomplete/timezone-filter/TimezoneFilter";
import { dayjs } from "../../util/date-util";

export enum SettingsTab {
  Account = "account",
  Password = "password",
}
export type SettingsPageProps = {
  classes?: string;
};

export default function SettingsPage(props: SettingsPageProps) {
  const { classes } = props;
  const { tab } = useParams();
  const navigate = useNavigate();
  const { userInfo } = useUserContext();
  const [localStorageValue, setLocalStorageValue] = useLocalStorage();
  const isSm = useSmBreakpoint();

  const [formErrorMessage, setFormErrorMessage] = useState<string | null>(null);
  const [formSuccessMessage, setFormSuccessMessage] = useState<string | null>(
    null
  );
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [rawJobInfo, setRawJobInfo] =
    useState<FetchUserJobsResponseBody | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [clearedFieldsIndex, setClearedFieldsIndex] = useState<number>(0);

  useEffect(() => {
    fetchUserJobs()
      .then((resp) => {
        // Save response to sort later
        setRawJobInfo(resp);
      })
      .catch((error) => console.error(error));
  }, []);

  // Dismisses the success snackbar after a specified time
  useEffect(() => {
    if (formSuccessMessage) {
      setTimeout(() => {
        setFormSuccessMessage(null);
      }, 3000);
    }
  }, [formSuccessMessage]);

  // Dismisses the error snackbar after a specified time
  useEffect(() => {
    if (formErrorMessage) {
      setTimeout(() => {
        setFormErrorMessage(null);
      }, 3000);
    }
  }, [formErrorMessage]);

  useEffect(() => {
    // If job_category is set, make it the selected category
    if (userInfo?.profile?.job_category && !selectedCategory) {
      setSelectedCategory(userInfo.profile?.job_category);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const jobCategoryOptions: AutocompleteItem[] = useMemo(() => {
    if (!userInfo?.email) {
      return [];
    }
    const isInternalUser = userInfo?.email.includes("gatik.ai");
    const filteredJobCategories = rawJobInfo?.user_job_categories.filter(
      (category) =>
        isInternalUser
          ? category.type === JobCategoryType.Internal
          : category.type === JobCategoryType.External
    );
    const sortedJobCategories =
      filteredJobCategories &&
      filteredJobCategories.sort((a, b) => (a.order > b.order ? 1 : -1));

    if (sortedJobCategories) {
      return sortedJobCategories.map((category) => ({
        label: category.name,
        value: category.id,
        data: category.id,
      }));
    } else {
      return [];
    }
  }, [userInfo, rawJobInfo]);

  const jobFunctionOptions: AutocompleteItem[] = useMemo(() => {
    const filteredJobs = rawJobInfo?.user_jobs.filter(
      (job) => job.category === selectedCategory
    );
    const sortedJobs =
      filteredJobs && filteredJobs.sort((a, b) => (a.order > b.order ? 1 : -1));
    if (sortedJobs) {
      return sortedJobs.map((job) => ({
        label: job.name,
        value: job.id,
        data: job.id,
      }));
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, rawJobInfo]);

  const jobSeniorityOptions: AutocompleteItem[] = useMemo(() => {
    const sortedSeniorityLevels = rawJobInfo?.user_job_seniorities.sort(
      (a, b) => (a.order > b.order ? 1 : -1)
    );
    if (sortedSeniorityLevels) {
      return sortedSeniorityLevels.map((seniorityLevel) => ({
        label: seniorityLevel.name,
        value: seniorityLevel.id,
        data: seniorityLevel.id,
      }));
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, rawJobInfo]);

  const changeTab = (tab: SettingsTab) => {
    navigate(`/settings/${tab}`);
    return null;
  };

  useEffect(() => {
    // if tab is not a valid value, change back to account tab
    if (tab !== SettingsTab.Account && tab !== SettingsTab.Password) {
      changeTab(SettingsTab.Account);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const NameSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
  });
  const RoleSchema = Yup.object().shape({
    // For now, role values are not required.
    // jobCategory: Yup.string().required("Required"),
    // jobFunction: Yup.string().required("Required"),
    // seniority: Yup.string().required("Required"),
  });
  const PasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(9, "Must be at least 9 characters.")
      .matches(/(?=.*\W)/, "Must contain at least 1 symbol.")
      .matches(/(?=.*[A-Z])/, "Must contain at least 1 uppercase.")
      .matches(/(?=.*[a-z])/, "Must contain at least 1 lowercase.")
      .matches(/(?=.*\d)/, "Must contain at least 1 number.")
      .required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Passwords don't match")
      .required("Required"),
  });

  // const detailedTimezoneText = (timezone: string | undefined) => {
  //   const timezoneShortText = getTimezoneText(timezone);
  //   const offsetText = dayjs(
  //     getTimezoneCorrectedDate(new Date().toString(), timezone)
  //   ).format("Z");
  //   return (
  //     <div className="text-gray-500">
  //       <span className="text-gray-800 font-medium mr-2">
  //         {timezoneShortText}{" "}
  //       </span>
  //       UTC{offsetText}
  //     </div>
  //   );
  // };

  const PasswordCriteriaText = (
    text: string,
    criteria: boolean | undefined | null,
    testId?: string
  ) => {
    return (
      <span
        className={classNames("sign-up-page__password-rule", {
          "sign-up-page__password-rule--success": criteria,
        })}
        data-testid={testId}
      >
        <CheckmarkCircleIcon
          type={SvgType.Solid}
          width="16"
          height="16"
          fill={criteria ? allColors.success[600] : allColors.gray[500]}
          classes="mr-2 mb-px"
        />
        {text}
      </span>
    );
  };

  return (
    <main
      className={classNames(
        "page__content settings-page",
        {
          "settings-page--account": tab === SettingsTab.Account,
          "settings-page--password": tab === SettingsTab.Password,
        },
        classes
      )}
    >
      <h1 className="text-display-xs sm:text-display-sm font-semibold">
        Settings
      </h1>
      <StyledTabs
        tabType={TabType.Underline}
        labels={["Account"]}
        // TODO: Re-enable password tab once BE work is finished.
        // labels={["Account", "Password"]}
        extraClasses="mt-8 mb-8 sm:mb-12 border-b border-b-gray-100"
        value={tab === SettingsTab.Password ? 1 : 0}
        onChange={(_, newValue) => {
          if (newValue === 0) {
            changeTab(SettingsTab.Account);
          }
          if (newValue === 1) {
            changeTab(SettingsTab.Password);
          }
        }}
      />
      {tab === SettingsTab.Account && (
        <React.Fragment>
          <h2 className="text-lg sm:text-xl font-semibold">Personal info</h2>
          <p className="text-sm text-gray-600 mt-2 mb-2 sm:mb-8">
            Update your personal details here.
          </p>
          {!rawJobInfo ? (
            <LoadingSpinner centered classes="my-60" />
          ) : (
            <Formik
              initialValues={{
                firstName: userInfo?.first_name,
                lastName: userInfo?.last_name,
                jobCategory: jobCategoryOptions.find(
                  (job) => job.value === userInfo?.profile?.job_category
                )?.value,
                jobFunction: jobFunctionOptions.find(
                  (job) => job.value === userInfo?.profile?.job_name
                )?.value,
                seniority: jobSeniorityOptions.find(
                  (job) => job.value === userInfo?.profile?.job_seniority
                )?.value,
                timezone:
                  timezoneOptionsWithBrowserTimezone.find(
                    (tz) => tz.value === userInfo?.timezone
                  )?.value || "browserDefault",
              }}
              validationSchema={NameSchema.concat(RoleSchema)}
              onSubmit={async (values, { setSubmitting }) => {
                let updatedUserInfo: UpdateUserInfoRequestBody = {
                  first_name: values.firstName,
                  last_name: values.lastName,
                  // Unset user timezone if user selects "browserDefault"
                };
                if (values.timezone === "browserDefault") {
                  updatedUserInfo = {
                    ...updatedUserInfo,
                    timezone: "unset",
                  };
                } else {
                  updatedUserInfo = {
                    ...updatedUserInfo,
                    timezone: values.timezone,
                  };
                }
                // If both job function and seniority are selected, update all profile fields.
                if (values.jobFunction && values.seniority) {
                  updatedUserInfo = {
                    ...updatedUserInfo,
                    profile: {
                      job_category: values.jobCategory,
                      job_name: values.jobFunction,
                      job_seniority: values.seniority,
                    },
                  };
                }
                await updateUserInfo(updatedUserInfo)
                  .then((response) => {
                    setSubmitting(false);
                    if (localStorageValue) {
                      const parsedUserInfo = JSON.parse(localStorageValue);
                      if (!response.timezone) {
                        setLocalStorageValue({
                          ...parsedUserInfo,
                          ...response,
                          // If no user timezone is set, use browser timezone.
                          timezone: dayjs.tz.guess(),
                        });
                      } else {
                        setLocalStorageValue({
                          ...parsedUserInfo,
                          ...response,
                        });
                      }
                    }
                    setFormSuccessMessage("Profile successfully updated.");
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    console.error(error);
                    setFormErrorMessage(
                      "Something went wrong when updating your profile."
                    );
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                let isErrored =
                  isSubmitting ||
                  typeof errors.firstName === "string" ||
                  typeof errors.lastName === "string" ||
                  !values.firstName ||
                  !values.lastName ||
                  values.firstName === "" ||
                  values.lastName === "" ||
                  !values.timezone;
                return (
                  <form
                    onSubmit={handleSubmit}
                    className="settings-page__form"
                    data-testid="settings-page-form"
                  >
                    <div className="settings-page__form-row">
                      {isSm && (
                        <div className="settings-page__form-label">Name</div>
                      )}
                      <div className="settings-page__form-item-container settings-page__form-item-container--two-children">
                        <div className="flex flex-col">
                          <StyledInput
                            type="text"
                            name="firstName"
                            label={!isSm ? "First Name" : undefined}
                            placeholder="Enter first name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.firstName}
                            error={
                              touched.firstName ? errors.firstName : undefined
                            }
                            className="settings-page__form-item"
                            labelClasses="settings-page__form-label"
                          />
                        </div>
                        <div className="flex flex-col">
                          <StyledInput
                            type="text"
                            name="lastName"
                            label={!isSm ? "Last Name" : undefined}
                            placeholder="Enter last name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                            error={
                              touched.lastName ? errors.lastName : undefined
                            }
                            className="settings-page__form-item"
                            labelClasses="settings-page__form-label"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="settings-page__form-row">
                      <div className="settings-page__form-label">
                        Email Address
                      </div>
                      <div className="settings-page__form-item-container">
                        <div className="settings-page__form-item flex">
                          <MailIcon
                            width="20"
                            height="20"
                            stroke={allColors.gray[400]}
                            classes="mr-4"
                          />{" "}
                          {userInfo?.email}
                        </div>
                      </div>
                    </div>
                    <div className="settings-page__form-row">
                      <div className="settings-page__form-label">
                        Job Category
                      </div>
                      <div className="settings-page__form-item-container">
                        <div className="settings-page__form-item flex text-gray-500">
                          <div className="flex flex-col w-full">
                            <SingleAutocomplete
                              extraClasses="w-full"
                              inputClasses="settings-page__form-select-input-root"
                              label="Please make a selection"
                              options={jobCategoryOptions}
                              onBlur={handleBlur}
                              onChange={(_, child) => {
                                if (child?.value) {
                                  setSelectedCategory(child?.value);
                                  setFieldValue("jobCategory", child.value);
                                  setClearedFieldsIndex(clearedFieldsIndex + 1);
                                  setFieldValue("jobFunction", null);
                                  setFieldValue("seniority", null);
                                }
                              }}
                              isOptionEqualToValue={(option, value) =>
                                value?.value
                                  ? option.value === value.value
                                  : false
                              }
                              value={jobCategoryOptions.find(
                                (job) => job.value === values.jobCategory
                              )}
                              hideClearButton
                            />
                            <p className="input__error">{errors.jobCategory}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="settings-page__form-row">
                      <div className="settings-page__form-label">
                        Job Function
                      </div>
                      <div className="settings-page__form-item-container">
                        <div className="settings-page__form-item flex text-gray-500">
                          <div className="flex flex-col w-full">
                            <SingleAutocomplete
                              extraClasses="w-full"
                              inputClasses="settings-page__form-select-input-root"
                              key={`jobFunction${clearedFieldsIndex}`}
                              label="Please make a selection"
                              options={jobFunctionOptions}
                              onBlur={handleBlur}
                              onChange={(_, child) => {
                                setFieldValue("jobFunction", child?.value);
                              }}
                              isDisabled={!values.jobCategory}
                              isOptionEqualToValue={(option, value) =>
                                value?.value
                                  ? option.value === value.value
                                  : false
                              }
                              value={
                                jobFunctionOptions.find(
                                  (job) => job.value === values.jobFunction
                                ) || undefined
                              }
                              hideClearButton
                            />
                            <p className="input__error">{errors.jobFunction}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="settings-page__form-row">
                      <div className="settings-page__form-label">Seniority</div>
                      <div className="settings-page__form-item-container">
                        <div className="settings-page__form-item flex text-gray-500">
                          <div className="flex flex-col w-full">
                            <SingleAutocomplete
                              extraClasses="w-full"
                              inputClasses="settings-page__form-select-input-root"
                              key={`jobSeniority${clearedFieldsIndex}`}
                              label="Please make a selection"
                              options={jobSeniorityOptions}
                              onChange={(_, child) => {
                                setFieldValue("seniority", child?.value);
                              }}
                              onBlur={handleBlur}
                              isDisabled={!values.jobCategory}
                              isOptionEqualToValue={(option, value) =>
                                value?.value
                                  ? option.value === value.value
                                  : false
                              }
                              value={jobSeniorityOptions.find(
                                (job) => job.value === values.seniority
                              )}
                              hideClearButton
                            />
                            <p className="input__error">{errors.seniority}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="settings-page__form-row">
                      <div className="settings-page__form-label">
                        Organization
                      </div>
                      <div className="settings-page__form-item-container">
                        <div className="settings-page__form-item flex items-center text-gray-500">
                          {userInfo?.customer_name ? (
                            <React.Fragment>
                              <ProfileImage
                                userInfo={userInfo}
                                classes="settings-page__organization-image mr-4"
                              />{" "}
                              {userInfo?.customer_name}
                            </React.Fragment>
                          ) : (
                            <span>No organization assigned</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="settings-page__form-row">
                      <div className="settings-page__form-label">
                        My Timezone
                      </div>
                      <div className="settings-page__form-item-container">
                        <div className="settings-page__form-item flex items-center text-gray-500">
                          <div className="flex flex-col w-full">
                            <TimezoneFilter
                              label="My timezone"
                              onChange={(_, child) => {
                                setFieldValue("timezone", child?.value);
                              }}
                              value={timezoneOptionsWithBrowserTimezone.find(
                                (tz) => tz.value === values.timezone
                              )}
                              extraClasses="w-full"
                              inputClasses="settings-page__form-select-input-root"
                            />
                            <p className="input__error">{errors.seniority}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="settings-page__form-button-container">
                      <StyledButton
                        color="primary"
                        isSubmit
                        disabled={isErrored}
                        classes="settings-page__form-button"
                      >
                        Save{" "}
                        <SaveIcon
                          width="20"
                          height="20"
                          stroke={
                            isErrored ? allColors.gray[400] : allColors.white
                          }
                          classes="ml-4"
                        />
                      </StyledButton>
                    </div>
                  </form>
                );
              }}
            </Formik>
          )}
        </React.Fragment>
      )}
      {tab === SettingsTab.Password && (
        <React.Fragment>
          <h2 className="text-lg sm:text-xl font-semibold">
            Password settings
          </h2>
          <p className="text-sm text-gray-600 mt-2 mb-2 sm:mb-8">
            Change your password.
          </p>
          <Formik
            initialValues={{
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={PasswordSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              setTimeout(() => {
                console.log("TODO: Actually update password");
                setSubmitting(false);
                setFormSuccessMessage("Password successfully updated.");

                // setFormErrorMessage(
                //   "Something went wrong when updating your password."
                // );
              }, 1000);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => {
              let isErrored =
                isSubmitting ||
                typeof errors.currentPassword === "string" ||
                typeof errors.newPassword === "string" ||
                typeof errors.confirmPassword === "string" ||
                values.currentPassword === "" ||
                values.newPassword === "" ||
                values.confirmPassword === "";
              return (
                <form onSubmit={handleSubmit} className="settings-page__form">
                  <div className="settings-page__form-row">
                    <div className="settings-page__form-label">
                      Email address
                    </div>
                    <div className="settings-page__form-item-container">
                      <div className="settings-page__form-item flex">
                        <MailIcon
                          width="20"
                          height="20"
                          stroke={allColors.gray[400]}
                          classes="mr-4"
                        />{" "}
                        {userInfo?.email}
                      </div>
                    </div>
                  </div>
                  <div className="settings-page__form-row settings-page__form-row--no-line">
                    <div className="settings-page__form-label">
                      Current password
                    </div>
                    <div className="settings-page__form-item-container">
                      <div className="settings-page__form-item flex">
                        <StyledInput
                          type={showPassword ? "text" : "password"}
                          name="currentPassword"
                          placeholder="Current password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.currentPassword}
                          className="settings-page__password-input"
                          error={
                            touched.currentPassword &&
                            errors.currentPassword === "Required"
                              ? errors.currentPassword
                              : undefined
                          }
                          endIcon={
                            <button
                              type="button"
                              className="ml-4"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <span className="sr-only">
                                {showPassword
                                  ? "Hide password"
                                  : "Show password"}
                              </span>
                              {!showPassword ? (
                                <EyeHideIcon
                                  type={SvgType.Solid}
                                  fill={allColors.grayLightMode[500]}
                                />
                              ) : (
                                <EyeShowIcon
                                  type={SvgType.Solid}
                                  fill={allColors.grayLightMode[500]}
                                />
                              )}
                            </button>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="settings-page__form-row settings-page__form-row--no-line">
                    <div className="settings-page__form-label">
                      New password
                    </div>
                    <div className="settings-page__form-item-container">
                      <div className="settings-page__form-item flex flex-col">
                        <StyledInput
                          type={showPassword ? "text" : "password"}
                          name="newPassword"
                          placeholder="New password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.newPassword}
                          className="settings-page__password-input mb-4"
                          error={
                            touched.newPassword &&
                            errors.newPassword === "Required"
                              ? errors.newPassword
                              : undefined
                          }
                          endIcon={
                            <button
                              type="button"
                              className="ml-4"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <span className="sr-only">
                                {showPassword
                                  ? "Hide password"
                                  : "Show password"}
                              </span>
                              {!showPassword ? (
                                <EyeHideIcon
                                  type={SvgType.Solid}
                                  fill={allColors.grayLightMode[500]}
                                />
                              ) : (
                                <EyeShowIcon
                                  type={SvgType.Solid}
                                  fill={allColors.grayLightMode[500]}
                                />
                              )}
                            </button>
                          }
                        />
                        <div className="flex flex-wrap gap-2">
                          {PasswordCriteriaText(
                            "9 characters",
                            values.newPassword.length >= 9,
                            "error-characters"
                          )}
                          {PasswordCriteriaText(
                            "1 symbol",
                            /(?=.*\W)/.test(values.newPassword),
                            "error-symbol"
                          )}
                          {PasswordCriteriaText(
                            "1 uppercase",
                            /(?=.*[A-Z])/.test(values.newPassword),
                            "error-uppercase"
                          )}
                          {PasswordCriteriaText(
                            "1 lowercase",
                            /(?=.*[a-z])/.test(values.newPassword),
                            "error-lowercase"
                          )}
                          {PasswordCriteriaText(
                            "1 number",
                            /(?=.*\d)/.test(values.newPassword),
                            "error-number"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="settings-page__form-row settings-page__form-row--no-line">
                    <div className="settings-page__form-label">
                      Retype new password
                    </div>
                    <div className="settings-page__form-item-container">
                      <div className="settings-page__form-item flex flex-col">
                        <StyledInput
                          type={showPassword ? "text" : "password"}
                          name="confirmPassword"
                          placeholder="Retype new password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirmPassword}
                          className="settings-page__password-input"
                          error={
                            touched.confirmPassword && errors.confirmPassword
                              ? errors.confirmPassword
                              : undefined
                          }
                          endIcon={
                            <button
                              type="button"
                              className="ml-4"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <span className="sr-only">
                                {showPassword
                                  ? "Hide password"
                                  : "Show password"}
                              </span>
                              {!showPassword ? (
                                <EyeHideIcon
                                  type={SvgType.Solid}
                                  fill={allColors.grayLightMode[500]}
                                />
                              ) : (
                                <EyeShowIcon
                                  type={SvgType.Solid}
                                  fill={allColors.grayLightMode[500]}
                                />
                              )}
                            </button>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="settings-page__form-button-container">
                    <StyledButton
                      color="primary"
                      isSubmit
                      disabled={isErrored}
                      classes="settings-page__form-button"
                    >
                      Save{" "}
                      <SaveIcon
                        width="20"
                        height="20"
                        stroke={
                          isErrored ? allColors.gray[400] : allColors.white
                        }
                        classes="ml-4"
                      />
                    </StyledButton>
                  </div>
                </form>
              );
            }}
          </Formik>
        </React.Fragment>
      )}
      {formErrorMessage && (
        <Snackbar
          type={SnackbarType.Error}
          body={formErrorMessage}
          onDismiss={() => setFormErrorMessage(null)}
          autoDismiss
          autoDismissDelay="2500ms"
        />
      )}
      {formSuccessMessage && (
        <Snackbar
          type={SnackbarType.Success}
          body={formSuccessMessage}
          onDismiss={() => setFormSuccessMessage(null)}
          autoDismiss
          autoDismissDelay="2500ms"
        />
      )}
    </main>
  );
}
