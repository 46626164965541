import classNames from "classnames";
import {
  TripStatus,
  StopEventStatus,
  Stop,
  StopSequenceStatus,
} from "../../../types/trip";
import AlertTriangleIcon from "../../common/svg/AlertTriangle";
import { allColors } from "../../../util/colors";
import StyledTooltip from "../../common/tooltip/StyledTooltip";

export interface TripProgressItemProps {
  tripStatus: TripStatus;
  stop: Stop;
  inProgress?: boolean;
  inProgressAnimationSpeed?: number;
  classes?: string;
}

export default function TripProgressItem(props: TripProgressItemProps) {
  const { tripStatus, stop, inProgress, inProgressAnimationSpeed, classes } =
    props;

  return (
    <StyledTooltip
      header={
        tripStatus === TripStatus.Scheduled ||
        tripStatus === TripStatus.Completed
          ? undefined
          : stop.site.properties.name
      }
      placement={"top"}
      containerClasses="trip-progress-item__tooltip-container"
    >
      <div
        className={classNames(
          "trip-progress-item",
          {
            "trip-progress-item--in-progress": inProgress,
            "trip-progress-item--departed":
              stop.event_status === StopEventStatus.AVDeparted,
            "trip-progress-item--skipped":
              stop.sequence_status === StopSequenceStatus.Skipped,
            "trip-progress-item--cancelled":
              tripStatus === TripStatus.Cancelled,
            "trip-progress-item--completed":
              tripStatus === TripStatus.Completed,
            "trip-progress-item--scheduled":
              tripStatus === TripStatus.Scheduled,
            "trip-progress-item--timed-out":
              tripStatus === TripStatus.TrackingTimeout,
          },
          classes
        )}
        style={{ animationDuration: `${inProgressAnimationSpeed || 500}s` }}
      >
        {tripStatus === TripStatus.TrackingTimeout && inProgress && (
          <div className="trip-progress-item__alert-icon-container">
            <AlertTriangleIcon
              fill={allColors.gray[800]}
              width="18"
              height="18"
            />
          </div>
        )}
        {stop.sequence_status === StopSequenceStatus.Skipped && (
          <div className="trip-progress-item__skipped-slash"></div>
        )}
      </div>
    </StyledTooltip>
  );
}
