export enum StopsSortMethod {
  SiteNameAsc = "site_name",
  SiteNameDesc = "-site_name",
  TripNameAsc = "trip_name",
  TripNameDesc = "-trip_name",
  AvIdAsc = "av_id",
  AvIdDesc = "-av_id",
  StopProgressAsc = "stop_progress",
  StopProgressDesc = "-stop_progress",
  ArrivedAtAsc = "arrived_at",
  ArrivedAtDesc = "-arrived_at",
  DepartedAtAsc = "departed_at",
  DepartedAtDesc = "-departed_at",
}
