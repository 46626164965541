import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps, SvgType } from "./types";
import React from "react";

export default function EyeShowIcon(props: SvgProps) {
  const {
    type = SvgType.Solid,
    width = "24",
    height = "24",
    stroke = allColors.black,
    fill = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__eye-show", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      {type === SvgType.Solid ? (
        <React.Fragment>
          <path
            d="M10 12C10 10.8954 10.8955 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8955 14 10 13.1046 10 12Z"
            fill={fill}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 4C9.13837 4 6.80538 5.26472 5.07678 6.70743C3.35053 8.14818 2.167 9.81429 1.57426 10.7528L1.55017 10.7908C1.43255 10.976 1.27984 11.2164 1.20263 11.5532C1.1403 11.8251 1.1403 12.1749 1.20263 12.4468C1.27984 12.7836 1.43255 13.024 1.55017 13.2092L1.57426 13.2472C2.167 14.1857 3.35053 15.8518 5.07678 17.2926C6.80538 18.7353 9.13837 20 12 20C14.8617 20 17.1947 18.7353 18.9233 17.2926C20.6495 15.8518 21.8331 14.1857 22.4258 13.2472L22.4499 13.2092C22.5675 13.024 22.7202 12.7837 22.7974 12.4468C22.8598 12.1749 22.8598 11.8251 22.7974 11.5532C22.7202 11.2163 22.5675 10.976 22.4499 10.7908L22.4258 10.7528C21.8331 9.81429 20.6495 8.14818 18.9233 6.70743C17.1947 5.26472 14.8617 4 12 4ZM12 8C9.79089 8 8.00003 9.79086 8.00003 12C8.00003 14.2091 9.79089 16 12 16C14.2092 16 16 14.2091 16 12C16 9.79086 14.2092 8 12 8Z"
            fill={fill}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <path
            d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z"
            stroke={stroke}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z"
            stroke={stroke}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </React.Fragment>
      )}
    </svg>
  );
}
