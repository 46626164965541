import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps, SvgType } from "./types";

export default function FilterFunnelIcon(props: SvgProps) {
  const {
    type = SvgType.Line,
    width = "24",
    height = "24",
    fill = allColors.black,
    stroke = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__filter-funnel", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      {type === SvgType.Solid ? (
        <path
          d="M4.53342 2C4.5484 2 4.56344 2 4.57853 2L19.4666 2C19.995 1.99998 20.4583 1.99995 20.8217 2.03263C21.1705 2.06399 21.649 2.1376 22.0368 2.45778C22.5184 2.85544 22.7866 3.45528 22.7618 4.07936C22.7419 4.58184 22.4776 4.98756 22.2685 5.26837C22.0505 5.56102 21.7416 5.90621 21.3892 6.30003L15.6528 12.7112C15.5721 12.8015 15.5321 12.8465 15.5043 12.8804L15.5022 12.8829L15.5021 12.8862C15.5002 12.9299 15.5 12.9901 15.5 13.1113V18.4584C15.5 18.4693 15.5001 18.4821 15.5003 18.4964C15.5017 18.6339 15.5048 18.9193 15.4054 19.1858C15.3218 19.4099 15.1858 19.6108 15.0088 19.7716C14.7983 19.9629 14.5321 20.0661 14.4039 20.1158C14.3905 20.121 14.3787 20.1256 14.3685 20.1296L10.9406 21.5008C10.7817 21.5644 10.6061 21.6347 10.4511 21.681C10.2844 21.7307 10.0106 21.7957 9.69481 21.7301C9.30779 21.6497 8.96815 21.4197 8.74975 21.0903C8.57152 20.8214 8.53021 20.543 8.51449 20.3698C8.49988 20.2088 8.49993 20.0196 8.49999 19.8484L8.49999 13.1113C8.49999 12.9901 8.49978 12.9299 8.49792 12.8862L8.49777 12.8829L8.49572 12.8804C8.46793 12.8465 8.42792 12.8015 8.34714 12.7112L2.6409 6.33367C2.63083 6.32242 2.6208 6.31121 2.61081 6.30004C2.25841 5.90622 1.94952 5.56103 1.73152 5.26837C1.52234 4.98756 1.25811 4.58184 1.23815 4.07936C1.21337 3.45528 1.48156 2.85544 1.96318 2.45778C2.35095 2.1376 2.8295 2.06399 3.17825 2.03263C3.54172 1.99995 4.00493 1.99998 4.53342 2Z"
          fill={fill}
        />
      ) : (
        <path
          d="M3.38589 5.66687C2.62955 4.82155 2.25138 4.39889 2.23712 4.03968C2.22473 3.72764 2.35882 3.42772 2.59963 3.22889C2.87684 3 3.44399 3 4.57828 3H19.4212C20.5555 3 21.1227 3 21.3999 3.22889C21.6407 3.42772 21.7748 3.72764 21.7624 4.03968C21.7481 4.39889 21.3699 4.82155 20.6136 5.66687L14.9074 12.0444C14.7566 12.2129 14.6812 12.2972 14.6275 12.3931C14.5798 12.4781 14.5448 12.5697 14.5236 12.6648C14.4997 12.7721 14.4997 12.8852 14.4997 13.1113V18.4584C14.4997 18.6539 14.4997 18.7517 14.4682 18.8363C14.4403 18.911 14.395 18.9779 14.336 19.0315C14.2692 19.0922 14.1784 19.1285 13.9969 19.2012L10.5969 20.5612C10.2293 20.7082 10.0455 20.7817 9.89802 20.751C9.76901 20.7242 9.6558 20.6476 9.583 20.5377C9.49975 20.4122 9.49975 20.2142 9.49975 19.8184V13.1113C9.49975 12.8852 9.49975 12.7721 9.47587 12.6648C9.45469 12.5697 9.41971 12.4781 9.37204 12.3931C9.31828 12.2972 9.2429 12.2129 9.09213 12.0444L3.38589 5.66687Z"
          stroke={stroke}
          strokeWidth="2"
        />
      )}
    </svg>
  );
}
