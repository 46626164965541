// These are used in tailwind.config.js to add all brand colors to tailwind to be used in helper classes
// Ensure that if colors change, they are replaced here and in styles/colors.scss

export const allColors = {
  white: "#FFFFFF",
  black: "#0C091A",
  primary: {
    25: "#F5F8FF",
    50: "#EEF4FF",
    100: "#E0EAFF",
    200: "#C7D7FE",
    300: "#A4BCFD",
    400: "#8098F9",
    500: "#6172F3",
    600: "#444CE7",
    700: "#3538CD",
    800: "#2D31A6",
    900: "#2D3282",
    950: "#1F235B",
  },
  brand: {
    25: "#F9F8FF",
    50: "#F5F3FF",
    100: "#F4EBFF",
    200: "#DED3F8",
    300: "#CDBCF4",
    400: "#BDA6F1",
    500: "#9B7AE9",
    600: "#7A4DE2",
    700: "#5921DB",
    800: "#471AAF",
    900: "#351483",
    950: "#260C64",
  },
  grayLightMode: {
    25: "#FAFAFA",
    50: "#F5F5F6",
    100: "#F0F1F1",
    200: "#ECECED",
    300: "#CECFD2",
    400: "#94969C",
    500: "#85888E",
    600: "#61646C",
    700: "#333741",
    800: "#1F242F",
    900: "#161B26",
    950: "#0C111D",
  },
  gray: {
    25: "#FCFCFD",
    50: "#F8FAFC",
    100: "#EEF2F6",
    200: "#E3E8EF",
    300: "#CDD5DF",
    400: "#9AA4B2",
    500: "#697586",
    600: "#4B5565",
    700: "#364152",
    800: "#202939",
    900: "#121926",
    950: "#0D121C",
  },
  error: {
    25: "#FFFBFA",
    50: "#FEF3F2",
    100: "#FEE4E2",
    200: "#FECDCA",
    300: "#FDA29B",
    400: "#F97066",
    500: "#F04438",
    600: "#D92D20",
    700: "#B42318",
    800: "#912018",
    900: "#7A271A",
    950: "#55160C",
  },
  warning: {
    25: "#FFFCF5",
    50: "#FFFAEB",
    100: "#FEF0C7",
    200: "#FEDF89",
    300: "#FEC84B",
    400: "#FDB022",
    500: "#F79009",
    600: "#DC6802",
    700: "#B54708",
    800: "#93370D",
    900: "#7A2E0E",
    950: "#4E1D09",
  },
  success: {
    25: "#F6FEF9",
    50: "#ECFDF3",
    100: "#DCFAE6",
    200: "#ABEFC6",
    300: "#75E0A7",
    400: "#47CD89",
    500: "#17B26A",
    600: "#079455",
    700: "#067647",
    800: "#085D3A",
    900: "#074D31",
    950: "#053321",
  },
};
