import classNames from "classnames";
import FilterControl from "../../components/filter-control/FilterControl";
import { useMdBreakpoint } from "../../util/breakpoints";
import { tripStatusOptions } from "../../components/form/autocomplete/trip-status-filter/TripStatusFilter";
import { timelinessOptions } from "../../components/form/autocomplete/timeliness-filter/TimelinessFilter";
import {
  FilterControlProps,
  FilterControlOptionGroupProps,
} from "../../components/filter-control/types";
import { TripsSortMethod } from "../../api/trip";

export interface TripsFilterModalProps extends FilterControlProps {}

const sortOptionParams: Omit<
  FilterControlOptionGroupProps,
  "options" | "label" | "name" | "selectedValues" | "onChangeValues"
> = {
  category: "sortBy",
  singleSelect: true,
};

export const tripsFilters: Omit<
  FilterControlOptionGroupProps,
  "selectedValues" | "onChangeValues"
>[] = [
  {
    label: "Status",
    name: "trip_status",
    category: "filter",
    options: tripStatusOptions,
  },
  {
    label: "Timeliness",
    name: "trip_timeliness",
    category: "filter",
    options: timelinessOptions,
  },
  {
    label: "Trip Name",
    name: "name",
    category: "sortBy",
    singleSelect: true,
    options: [
      { label: "Ascending", value: TripsSortMethod.TripNameAsc },
      { label: "Descending", value: TripsSortMethod.TripNameDesc },
    ],
    ...sortOptionParams,
  },
  {
    label: "Lane ID",
    name: "external_id",
    category: "sortBy",
    singleSelect: true,
    options: [
      { label: "Ascending", value: TripsSortMethod.LaneIdAsc },
      { label: "Descending", value: TripsSortMethod.LaneIdDesc },
    ],
    ...sortOptionParams,
  },
  {
    label: "AV#",
    name: "long_av_id",
    category: "sortBy",
    singleSelect: true,
    options: [
      { label: "Ascending", value: TripsSortMethod.AvIdAsc },
      { label: "Descending", value: TripsSortMethod.AvIdDesc },
    ],
    ...sortOptionParams,
  },
  // {
  //   label: "Trip Progress",
  //   name: "trip_progress",
  //   category: "sortBy",
  //   singleSelect: true,
  //   options: [
  //     { label: "Ascending", value: TripsSortMethod.TripProgressAsc },
  //     { label: "Descending", value: TripsSortMethod.TripProgressDesc },
  //   ],
  //   ...sortOptionParams,
  // },
];

export default function TripsFilterModal(props: TripsFilterModalProps) {
  const { ...otherProps } = props;

  const isMd = useMdBreakpoint();

  return (
    <FilterControl
      {...otherProps}
      filters={tripsFilters}
      categories={[
        {
          label: "Filters",
          value: "filter",
        },
        {
          label: "Sort By",
          value: "sortBy",
        },
      ]}
      classes={classNames("live-tracking__filter-control", {
        "live-tracking__filter-control--desktop": isMd,
        "live-tracking__filter-control--mobile": !isMd,
      })}
    />
  );
}
