import { Checkbox, CheckboxProps } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";
import { allColors } from "../../../util/colors";
import CheckmarkSmIcon from "../../common/svg/CheckmarkSm";
import MinusSmIcon from "../../common/svg/MinusSm";

export enum CheckboxSize {
  Sm = "small",
  Md = "medium",
}

export interface StyledCheckboxProps extends CheckboxProps {
  checkboxSize?: CheckboxSize;
}

export default function StyledCheckbox(props: StyledCheckboxProps) {
  const {
    checkboxSize = CheckboxSize.Sm,
    checked,
    onChange,
    ...otherProps
  } = props;

  const [focusRingActive, setFocusRingActive] = useState<boolean>(false);
  const showFocusRing = !otherProps.disabled;

  const triggerFocusRing = () => {
    if (!focusRingActive) {
      setFocusRingActive(true);
    } else {
      // toggle active class off and then back on
      setFocusRingActive(false);
      setTimeout(() => {
        setFocusRingActive(true);
      }, 5);
    }
  };

  return (
    <Checkbox
      {...otherProps}
      classes={{
        root: classNames("checkbox", {
          "focus-ring": showFocusRing,
          "focus-ring--active": showFocusRing && focusRingActive,
        }),
        checked: "checkbox--checked",
        disabled: "checkbox--disabled",
        indeterminate: "checkbox--indeterminate",
        sizeSmall: "checkbox--sm",
        sizeMedium: "checkbox--md",
      }}
      icon={<CheckmarkSmIcon stroke={allColors.white} />}
      checkedIcon={<CheckmarkSmIcon stroke={allColors.white} />}
      indeterminateIcon={<MinusSmIcon stroke={allColors.white} />}
      size={checkboxSize}
      disableRipple
      checked={checked}
      onChange={(e) => {
        triggerFocusRing();
        if (onChange) {
          onChange(e, checked && checked !== undefined ? checked : false);
        }
      }}
    />
  );
}
