import classNames from "classnames";

export type OverlayProps = {
  onClick?: () => void;
  srText?: string;
  isActive: boolean;
  content?: JSX.Element | null;
  classes?: string;
  buttonClasses?: string;
};

export default function Dot(props: OverlayProps) {
  const { onClick, srText, isActive, content, buttonClasses, classes } = props;

  return (
    <div
      className={classNames(
        "overlay",
        { "overlay--active": isActive },
        classes
      )}
    >
      {onClick && (
        <button
          className={classNames("overlay__button", buttonClasses)}
          onClick={onClick}
          tabIndex={-1}
        >
          <span className="sr-only">{srText ? srText : "Close"}</span>
        </button>
      )}
      <div className="overlay__content">{content}</div>
    </div>
  );
}
