import {
  Stop,
  StopEventStatus,
  StopSequenceStatus,
  TimelinessStatus,
  TripStatus,
} from "../../types/trip";

const scheduledStop: Omit<
  Stop,
  "site.properties.name" | "total_distance" | "travelled_distance"
> = {
  av_id: "G3074",
  long_av_id: "G3074-I23-FD",
  trip_status: TripStatus.InProgress,
  sequence_status: StopSequenceStatus.InSequence,
  event_status: StopEventStatus.AwaitingAV,
  id: "mock-stop-id",
  trip_id: "mock-trip-id",
  customer_id: "mock-customer-id",
  lane_id: "Route 1",
  trip_scheduled_start: "2024-06-24T08:00:00Z",
  arrived_at: null,
  departed_at: null,
  scheduled_arrival: "2024-06-24T13:00:00Z",
  scheduled_departure: "2024-06-24T14:55:00Z",
  arrival_status: TimelinessStatus.Early,
  departure_status: TimelinessStatus.Early,
  eta: "2024-06-24T13:45:30Z",
  ett: 25,
  travel_time: 25,
  service_time: 10.5,
  stops_away: 3,
  timezone: "America/New_York",
  trip_name: "11101R_03052024",
  site: {
    id: "test-id",
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-94.135715, 36.199974],
          [-94.135715, 36.199974],
        ],
      ],
    },
    properties: {
      customer_id: "da3f4fd2-f3a9-4ed2-8600-0dc6d77b0265",
      name: "Transportation Terminal",
      dock: "n/a",
      description: "671 W Randall Wobbe Ln, Springdale, AR 72764",
      location: "fdd88893-6bbf-4952-9a10-3a3fcca6c9b4",
      geofence_radius_meters: 0,
    },
  },
};
const arrivedStop: Omit<Stop, "site.properties.name" | "total_distance"> = {
  av_id: "UNASSIGNED",
  trip_status: TripStatus.InProgress,
  sequence_status: StopSequenceStatus.InSequence,
  event_status: StopEventStatus.AVArrived,
  travelled_distance: 0,
  id: "mock-stop-id",
  trip_id: "mock-trip-id",
  customer_id: "mock-customer-id",
  arrived_at: "2024-06-24T13:05:30Z",
  departed_at: null,
  scheduled_arrival: "2024-06-24T13:00:00Z",
  scheduled_departure: "2024-06-24T14:55:00Z",
  arrival_status: TimelinessStatus.OnTime,
  departure_status: TimelinessStatus.OnTime,
  eta: "2024-06-24T13:00:00Z",
  ett: 0,
  travel_time: 25,
  service_time: 10.5,
  timezone: "CST6CDT",
  trip_name: "11101R_03052024",
  site: {
    id: "test-id",
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-94.135715, 36.199974],
          [-94.135715, 36.199974],
        ],
      ],
    },
    properties: {
      customer_id: "da3f4fd2-f3a9-4ed2-8600-0dc6d77b0265",
      name: "Transportation Terminal",
      dock: "n/a",
      description: "671 W Randall Wobbe Ln, Springdale, AR 72764",
      location: "fdd88893-6bbf-4952-9a10-3a3fcca6c9b4",
      geofence_radius_meters: 0,
    },
  },
};
const departedStop: Omit<Stop, "site.properties.name" | "total_distance"> = {
  av_id: "G1024",
  trip_status: TripStatus.InProgress,
  sequence_status: StopSequenceStatus.InSequence,
  event_status: StopEventStatus.AVDeparted,
  travelled_distance: 0,
  id: "mock-stop-id",
  trip_id: "mock-trip-id",
  customer_id: "mock-customer-id",
  arrived_at: "2024-06-24T13:05:30Z",
  departed_at: "2024-06-24T14:50:00Z",
  scheduled_arrival: "2024-06-24T13:00:00Z",
  scheduled_departure: "2024-06-24T14:55:00Z",
  arrival_status: TimelinessStatus.Late,
  departure_status: TimelinessStatus.Late,
  timezone: "America/Chicago",
  trip_name: "11101R_03052024",
  ett: 25,
  travel_time: 25,
  service_time: 10.5,
  site: {
    id: "test-id",
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [
        [
          [-94.135715, 36.199974],
          [-94.135715, 36.199974],
        ],
      ],
    },
    properties: {
      customer_id: "da3f4fd2-f3a9-4ed2-8600-0dc6d77b0265",
      name: "Transportation Terminal",
      dock: "n/a",
      description: "671 W Randall Wobbe Ln, Springdale, AR 72764",
      location: "fdd88893-6bbf-4952-9a10-3a3fcca6c9b4",
      geofence_radius_meters: 0,
    },
  },
};

// *****************************
//   Origin & Final Destination
// *****************************

export const scheduledOrigin: Stop = {
  ...scheduledStop,
  is_origin: true,
  trip_name: "98238-072524",
  trip_status: TripStatus.Scheduled,
  total_distance: null,
  travelled_distance: null,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Sherman PEC",
    },
  },
};
export const scheduledShuttleRunOrigin: Stop = {
  ...scheduledStop,
  is_origin: true,
  trip_name: "98238-072524",
  total_distance: 10,
  travelled_distance: 2,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Sherman PEC",
    },
  },
};
export const departedOrigin: Stop = {
  ...departedStop,
  is_origin: true,
  trip_name: "98238-072524",
  total_distance: null,
  travelled_distance: null,
  site: {
    ...departedStop.site,
    properties: {
      ...departedStop.site.properties,
      name: "Sherman PEC",
    },
  },
};
export const departedShuttleRunOrigin: Stop = {
  ...departedStop,
  is_origin: true,
  trip_name: "98238-072524",
  total_distance: 10,
  travelled_distance: 10,
  site: {
    ...departedStop.site,
    properties: {
      ...departedStop.site.properties,
      name: "Sherman PEC",
    },
  },
};
export const scheduledFD: Stop = {
  ...scheduledStop,
  is_final_destination: true,
  trip_name: "Route 10-072524",
  total_distance: 10,
  travelled_distance: 0,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Sherman PEC",
    },
  },
};
export const scheduledDayAfterFD: Stop = {
  ...scheduledStop,
  is_final_destination: true,
  trip_name: "Route 10-072524",
  total_distance: 10,
  travelled_distance: 0,
  trip_scheduled_start: "2024-06-23T12:55:30Z",
  scheduled_arrival: "2024-06-24T06:20:30Z",
  eta: "2024-06-24T06:20:30Z",
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Sherman PEC",
    },
  },
};
export const arrivedFD: Stop = {
  ...arrivedStop,
  is_final_destination: true,
  trip_name: "Route 10-072524",
  total_distance: 10,
  travelled_distance: 10,
  site: {
    ...arrivedStop.site,
    properties: {
      ...arrivedStop.site.properties,
      name: "Sherman PEC",
    },
  },
};

// *****************************
//       Scheduled Stops
// *****************************

// Normal
export const scheduledStop1: Stop = {
  ...scheduledStop,
  trip_name: "Route 1-072524",
  total_distance: 10.75,
  travelled_distance: 0,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Kroger Southeast #45",
    },
  },
};
export const scheduledFutureStopStop1: Stop = {
  ...scheduledStop,
  trip_name: "Route 1-072524",
  trip_status: TripStatus.Scheduled,
  scheduled_arrival: null,
  total_distance: 10.75,
  travelled_distance: null,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "East Frisco Carrier Annex 4011954  75035",
    },
  },
};
export const scheduledStop1EnRouteNoProgress: Stop = {
  ...scheduledStop,
  trip_name: "Route 1-072524",
  ett: 25,
  total_distance: 10,
  travelled_distance: 0,
  elapsed_travel_time: 1,
  stops_away: 0,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Kroger Southeast #45",
    },
  },
};
export const scheduledStop1EnRouteHalfway: Stop = {
  ...scheduledStop,
  trip_name: "Route 1-072524",
  ett: 13,
  total_distance: 10,
  travelled_distance: 25.3333,
  elapsed_travel_time: 12,
  stops_away: 0,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Kroger Southeast #45",
    },
  },
};
export const scheduledStop2: Stop = {
  ...scheduledStop,
  trip_name: "Route 12-072524",
  total_distance: 5.2,
  travelled_distance: 0,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Walmart SC #0185",
    },
  },
};
export const scheduledStop3: Stop = {
  ...scheduledStop,
  trip_name: "Route 12-072524",
  total_distance: 5.3333333333,
  travelled_distance: 0,
  elapsed_travel_time: 5,
  stops_away: 6,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Sam's Club #6350",
    },
  },
};
export const scheduledStop4: Stop = {
  ...scheduledStop,
  trip_name: "Route 12-072524",
  total_distance: 12,
  travelled_distance: 0,
  elapsed_travel_time: 5,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Kroger #824",
    },
  },
};
export const scheduledStop5: Stop = {
  ...scheduledStop,
  trip_name: "Route 12-072524",
  total_distance: 7.9,
  travelled_distance: null,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Albertsons #226",
    },
  },
};
export const scheduledStop6: Stop = {
  ...scheduledStop,
  trip_name: "Route 12-072524",
  total_distance: 2,
  travelled_distance: 0,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Brookshire's #131",
    },
  },
};
export const scheduledStop7: Stop = {
  ...scheduledStop,
  trip_name: "Route 12-072524",
  total_distance: 5.21,
  travelled_distance: 0,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Tom Thumb #2557",
    },
  },
};
export const scheduledStop8: Stop = {
  ...scheduledStop,
  trip_name: "Route 12-072524",
  total_distance: 15.81,
  travelled_distance: 0,
  trip_scheduled_start: "2024-06-23T12:55:30Z",
  scheduled_arrival: "2024-06-24T03:20:30Z",
  eta: "2024-06-24T05:55:30Z",
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Walmart #2557",
    },
  },
};

// Cancelled
export const cancelledTripOrigin: Stop = {
  ...departedOrigin,
  trip_name: "Route 7-072524",
  trip_status: TripStatus.Cancelled,
  site: {
    ...departedOrigin.site,
    properties: {
      ...departedOrigin.site.properties,
      name: "Southeast BW26821 76140",
    },
  },
};
export const cancelledTripFD: Stop = {
  ...scheduledFD,
  trip_name: "Route 7-072524",
  trip_status: TripStatus.Cancelled,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Southeast BW26821 76140",
    },
  },
};
export const scheduledCancelledStop1: Stop = {
  ...scheduledStop,
  trip_name: "Route 7-072524",
  trip_status: TripStatus.Cancelled,
  total_distance: 15.81,
  travelled_distance: 0,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Tom Thumb #2557",
    },
  },
};
export const scheduledCancelledStop2: Stop = {
  ...scheduledStop,
  trip_name: "Route 7-072524",
  trip_status: TripStatus.Cancelled,
  total_distance: 5.2,
  travelled_distance: 0,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Walmart SC #0185",
    },
  },
};
export const scheduledCancelledStop3: Stop = {
  ...scheduledStop,
  trip_name: "Route 7-072524",
  trip_status: TripStatus.Cancelled,
  total_distance: 5.3333333333,
  travelled_distance: null,
  stops_away: 6,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Brookshire's #131",
    },
  },
};

// Deleted
export const scheduledDeletedStop1: Stop = {
  ...scheduledStop,
  trip_name: "Route 7-072524",
  total_distance: 15.81,
  travelled_distance: null,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Tom Thumb #2557",
    },
  },
};

// Skipped
export const scheduledSkippedStop1: Stop = {
  ...scheduledStop,
  trip_name: "Route 9-072524",
  sequence_status: StopSequenceStatus.Skipped,
  total_distance: null,
  travelled_distance: null,
  eta: null,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Safeway #2557",
    },
  },
};
export const scheduledSkippedStop2: Stop = {
  ...scheduledStop,
  trip_name: "Route 9-072524",
  sequence_status: StopSequenceStatus.Skipped,
  total_distance: null,
  travelled_distance: null,
  eta: null,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Walgreens #1337",
    },
  },
};
export const scheduledSkippedStop3: Stop = {
  ...scheduledStop,
  trip_name: "Route 9-072524",
  sequence_status: StopSequenceStatus.Skipped,
  total_distance: null,
  travelled_distance: null,
  eta: null,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "Kroger #9123",
    },
  },
};
export const scheduledSkippedStop4: Stop = {
  ...scheduledStop,
  trip_name: "Route 9-072524",
  sequence_status: StopSequenceStatus.Skipped,
  total_distance: null,
  travelled_distance: null,
  eta: null,
  site: {
    ...scheduledStop.site,
    properties: {
      ...scheduledStop.site.properties,
      name: "CVS #9098",
    },
  },
};

// *****************************
//        Arrived Stops
// *****************************

// Normal
export const arrivedStop1: Stop = {
  ...arrivedStop,
  trip_name: "Route 9-072524",
  total_distance: 10.75,
  elapsed_travel_time: 25,
  travelled_distance: 25,
  site: {
    ...arrivedStop.site,
    properties: {
      ...arrivedStop.site.properties,
      name: "Lake Worth BW24392 76135",
    },
  },
};
export const arrivedStop2: Stop = {
  ...arrivedStop,
  trip_name: "Route 9-072524",
  total_distance: 5.2,
  elapsed_travel_time: 20,
  travelled_distance: 5.2,
  site: {
    ...arrivedStop.site,
    properties: {
      ...arrivedStop.site.properties,
      name: "Northwest Dallas SW24961 75220",
    },
  },
};
export const arrivedStop3: Stop = {
  ...arrivedStop,
  trip_name: "Route 9-072524",
  total_distance: 5.3333333333,
  travelled_distance: 5,
  site: {
    ...arrivedStop.site,
    properties: {
      ...arrivedStop.site.properties,
      name: "Bent Tree SW22922 75287",
    },
  },
};
export const arrivedStop4: Stop = {
  ...arrivedStop,
  trip_name: "Route 9-072524",
  arrived_at: null,
  total_distance: 12,
  elapsed_travel_time: 30,
  travelled_distance: 35.3333333333,
  site: {
    ...arrivedStop.site,
    properties: {
      ...arrivedStop.site.properties,
      name: "Southeast BW26821 76140",
    },
  },
};
export const arrivedStop5: Stop = {
  ...arrivedStop,
  trip_name: "Route 9-072524",
  total_distance: 7.9,
  travelled_distance: 7.3333333333,
  site: {
    ...arrivedStop.site,
    properties: {
      ...arrivedStop.site.properties,
      name: "Albertsons #226",
    },
  },
};
export const arrivedStop6: Stop = {
  ...arrivedStop,
  trip_name: "Route 9-072524",
  total_distance: 2,
  travelled_distance: 2,
  site: {
    ...arrivedStop.site,
    properties: {
      ...arrivedStop.site.properties,
      name: "Brookshire's #131",
    },
  },
};
export const arrivedStop7: Stop = {
  ...arrivedStop,
  trip_name: "Route 9-072524",
  total_distance: 5.21,
  travelled_distance: 5.21,
  site: {
    ...arrivedStop.site,
    properties: {
      ...arrivedStop.site.properties,
      name: "Tom Thumb #2557",
    },
  },
};
export const arrivedStop8: Stop = {
  ...arrivedStop,
  trip_name: "Route 9-072524",
  total_distance: 5.81,
  travelled_distance: 6,
  site: {
    ...arrivedStop.site,
    properties: {
      ...arrivedStop.site.properties,
      name: "Walmart #2557",
    },
  },
};
export const arrivedFinalDestination: Stop = {
  ...arrivedStop,
  trip_name: "Route 9-072524",
  total_distance: 5.81,
  travelled_distance: 10,
  is_final_destination: true,
  site: {
    ...arrivedStop.site,
    properties: {
      ...arrivedStop.site.properties,
      name: "Walmart #2557",
    },
  },
};

// Out of sequence
export const arrivedOutOfSequenceStop1: Stop = {
  ...arrivedStop,
  trip_name: "Route 10-072524",
  sequence_status: StopSequenceStatus.OutOfSequence,
  total_distance: null,
  travelled_distance: null,
  site: {
    ...arrivedStop.site,
    properties: {
      ...arrivedStop.site.properties,
      name: "Tom Thumb #2557",
    },
  },
};
export const arrivedOutOfSequenceStop2: Stop = {
  ...arrivedStop,
  trip_name: "Route 10-072524",
  sequence_status: StopSequenceStatus.OutOfSequence,
  total_distance: null,
  travelled_distance: null,
  site: {
    ...arrivedStop.site,
    properties: {
      ...arrivedStop.site.properties,
      name: "West Bank #2005",
    },
  },
};

// Unplanned
export const arrivedUnplannedStop1: Stop = {
  ...arrivedStop,
  trip_name: "Route 10-072524",
  sequence_status: StopSequenceStatus.Unplanned,
  total_distance: null,
  travelled_distance: null,
  site: {
    ...arrivedStop.site,
    properties: {
      ...arrivedStop.site.properties,
      name: "Tom Thumb #2557",
    },
  },
};

// *****************************
//        Departed Stops
// *****************************

// Normal
export const departedStop1: Stop = {
  ...departedStop,
  trip_name: "Route 2-072524",
  total_distance: 10.75,
  elapsed_travel_time: 25,
  travelled_distance: 25,
  arrived_at: "2024-06-24T16:05:30Z",
  departed_at: "2024-06-24T17:50:00Z",
  scheduled_arrival: "2024-06-24T16:00:00Z",
  scheduled_departure: "2024-06-24T17:55:00Z",
  site: {
    ...departedStop.site,
    properties: {
      ...departedStop.site.properties,
      name: "Lake Worth BW24392 76135",
    },
  },
};
export const departedStop2: Stop = {
  ...departedStop,
  trip_name: "Route 2-072524",
  total_distance: 5.2,
  elapsed_travel_time: 22,
  travelled_distance: 3,
  site: {
    ...departedStop.site,
    properties: {
      ...departedStop.site.properties,
      name: "Northwest Dallas SW24961 75220",
    },
  },
};
export const departedStop3: Stop = {
  ...departedStop,
  trip_name: "Route 2-072524",
  total_distance: 5.3333333333,
  travelled_distance: 10,
  site: {
    ...departedStop.site,
    properties: {
      ...departedStop.site.properties,
      name: "Bent Tree SW22922 75287",
    },
  },
};
export const departedStop4: Stop = {
  ...departedStop,
  trip_name: "Route 2-072524",
  arrived_at: null,
  total_distance: 12,
  travelled_distance: 12,
  site: {
    ...departedStop.site,
    properties: {
      ...departedStop.site.properties,
      name: "Southeast BW26821 76140",
    },
  },
};
export const departedStop5: Stop = {
  ...departedStop,
  trip_name: "Route 2-072524",
  trip_status: TripStatus.Completed,
  total_distance: 7.9,
  elapsed_travel_time: 19,
  travelled_distance: 7.9,
  site: {
    ...departedStop.site,
    properties: {
      ...departedStop.site.properties,
      name: "Albertsons #226",
    },
  },
};
export const departedStop6: Stop = {
  ...departedStop,
  trip_name: "Route 2-072524",
  total_distance: 2,
  elapsed_travel_time: 6,
  travelled_distance: 2,
  site: {
    ...departedStop.site,
    properties: {
      ...departedStop.site.properties,
      name: "Brookshire's #131",
    },
  },
};
export const departedStop7: Stop = {
  ...departedStop,
  trip_name: "Route 2-072524",
  trip_scheduled_start: "2024-06-23T12:55:30Z",
  scheduled_arrival: "2024-06-24T03:20:30Z",
  scheduled_departure: "2024-06-24T04:00:30Z",
  arrived_at: "2024-06-24T05:01:30Z",
  // actual departure time is day after, UI should show "+1"
  departed_at: "2024-06-24T05:11:30Z",
  total_distance: 5.21,
  travelled_distance: 5.21,
  site: {
    ...departedStop.site,
    properties: {
      ...departedStop.site.properties,
      name: "Tom Thumb #2557",
    },
  },
};
export const departedStop8: Stop = {
  ...departedStop,
  trip_name: "Route 2-072524",
  total_distance: 5.81,
  travelled_distance: 5,
  site: {
    ...departedStop.site,
    properties: {
      ...departedStop.site.properties,
      name: "Walmart #2557",
    },
  },
};

// Out of sequence
export const departedOutOfSequenceStop1: Stop = {
  ...departedStop,
  id: "MockOutOfSequenceId1",
  trip_name: "Route 2-072524",
  sequence_status: StopSequenceStatus.OutOfSequence,
  total_distance: null,
  travelled_distance: null,
  site: {
    ...departedStop.site,
    properties: {
      ...departedStop.site.properties,
      name: "Tom Thumb #2557",
    },
  },
};
export const departedOutOfSequenceStop2: Stop = {
  ...departedStop,
  id: "MockOutOfSequenceId2",
  trip_name: "Route 10-072524",
  sequence_status: StopSequenceStatus.OutOfSequence,
  total_distance: null,
  travelled_distance: null,
  site: {
    ...departedStop.site,
    properties: {
      ...departedStop.site.properties,
      name: "West Bank #2005",
    },
  },
};

// Unplanned
export const departedUnplannedStop1: Stop = {
  ...departedStop,
  trip_name: "Route 2-072524",
  sequence_status: StopSequenceStatus.Unplanned,
  total_distance: null,
  travelled_distance: null,
  site: {
    ...departedStop.site,
    properties: {
      ...departedStop.site.properties,
      name: "Tom Thumb #2557",
    },
  },
};
