import {
  MegaSearchResultItem,
  MegaSearchResultItemType,
  MegaSearchResultMatchType,
} from "../../components/mega-search/types";

export const ResultsFor307Text: MegaSearchResultItem[] = [
  {
    name: "30711R_09092024",
    type: MegaSearchResultItemType.Trip,
  },
  {
    name: "90307X_17092024",
    type: MegaSearchResultItemType.Trip,
  },
  {
    name: "Target #43070",
    type: MegaSearchResultItemType.Site,
  },
  {
    name: "Amazon #10307",
    type: MegaSearchResultItemType.Site,
  },
  {
    name: "Safeway 419980 30710",
    type: MegaSearchResultItemType.Site,
  },
  {
    name: "G3070",
    type: MegaSearchResultItemType.Av,
  },
  {
    name: "G3071",
    type: MegaSearchResultItemType.Av,
  },
  {
    name: "G3072",
    type: MegaSearchResultItemType.Av,
  },
  {
    name: "G3073",
    type: MegaSearchResultItemType.Av,
  },
  {
    name: "G3074",
    type: MegaSearchResultItemType.Av,
  },
];

export const ResultsFor307Trips: MegaSearchResultItem[] = [
  {
    name: "Route 1-09092024",
    type: MegaSearchResultItemType.Trip,
    matchText: "G5307",
    matchType: MegaSearchResultMatchType.AvName,
  },
  {
    name: "Route 2-09092024",
    type: MegaSearchResultItemType.Trip,
    matchText: "Target #43070",
    matchType: MegaSearchResultMatchType.SiteName,
  },
  {
    name: "Route 3-09092024",
    type: MegaSearchResultItemType.Trip,
    matchText: "Amazon #10307",
    matchType: MegaSearchResultMatchType.SiteName,
  },
];

export const ResultsFor307Stops: MegaSearchResultItem[] = [
  {
    name: "Walgreens #45050",
    type: MegaSearchResultItemType.Site,
    matchText: "30711R_03052024",
    matchType: MegaSearchResultMatchType.TripName,
  },
  {
    name: "Distribution Center #100",
    type: MegaSearchResultItemType.Site,
    matchText: "30711R_03052024",
    matchType: MegaSearchResultMatchType.TripName,
  },
  {
    name: "CVS #20010",
    type: MegaSearchResultItemType.Site,
    matchText: "30711R_03052024",
    matchType: MegaSearchResultMatchType.TripName,
  },
  {
    name: "Mission District Market",
    type: MegaSearchResultItemType.Site,
    matchText: "30711R_03052024",
    matchType: MegaSearchResultMatchType.TripName,
  },
  {
    name: "West Sunset Grocery",
    type: MegaSearchResultItemType.Site,
    matchText: "30711R_03052024",
    matchType: MegaSearchResultMatchType.TripName,
  },
  {
    name: "Richmond Food4Less",
    type: MegaSearchResultItemType.Site,
    matchText: "30711R_03052024",
    matchType: MegaSearchResultMatchType.TripName,
  },
];
