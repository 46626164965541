import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps } from "./types";

export default function CheckmarkSmIcon(props: SvgProps) {
  const {
    width = "12",
    height = "12",
    stroke = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__checkmark-sm", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M10 3L4.5 8.5L2 6"
        stroke={stroke}
        strokeWidth="1.6666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
