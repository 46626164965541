import { AutocompleteItem, SingleAutocompleteProps } from "../types";
import classNames from "classnames";
import SingleAutocomplete from "../SingleAutocomplete";
import ClockIcon from "../../../common/svg/Clock";
import { allColors } from "../../../../util/colors";
import { dayjs } from "../../../../util/date-util";

export type TimezoneFilterProps = Omit<SingleAutocompleteProps, "options">;

export type TimezoneValue = {
  label: string;
  offset: string;
  value: string;
};

const formatLongLabel = (text: string, offset: string) => {
  return (
    <div className="text-md">
      <span className="font-medium text-gray-900 mr-2">{text}</span>
      <span className="font-regular text-gray-600">GMT{offset}</span>
    </div>
  );
};
export const timezoneOptionIcon: React.ReactNode = (
  <ClockIcon
    classes="mr-4"
    width="20"
    height="20"
    stroke={allColors.gray[500]}
  />
);

const timezoneValues: TimezoneValue[] = [
  { label: "Pacific Time (PT)", offset: "-08:00", value: "PST8PDT" },
  { label: "Mountain Time (MT)", offset: "-07:00", value: "MST7MDT" },
  { label: "Central Time (CT)", offset: "-06:00", value: "CST6CDT" },
  { label: "Eastern Time (ET)", offset: "-05:00", value: "EST5EDT" },
  // {
  //   label: "Indian Standard Time (IST)",
  //   offset: "+05:30",
  //   value: "Asia/Kolkata",
  // },
  // { label: "Universal Standard Time (UTC)", offset: "-00:00", value: "UTC" },
];

// Returns browser timezone in IANA format, i.e. "America/Los_Angeles", "Asia/Kolkata"
const browserTimezoneValue = dayjs.tz.guess();
// Returns timezone offset, i.e. "-07:00", "+05:30"
const browserTimezoneOffset = dayjs().format("Z");
// Returns short timezone, i.e. "PDT", "IST"
const browserTimezoneShortText = new Date()
  .toLocaleTimeString("en-us", {
    timeZone: undefined,
    timeZoneName: "short",
  })
  .split(" ")[2];
const browserTimezoneOption: AutocompleteItem = {
  label: `Browser default (${browserTimezoneShortText}) GMT${browserTimezoneOffset}`,
  extendedLabel: formatLongLabel(
    `Set to browser default (${browserTimezoneShortText})`,
    `${browserTimezoneOffset}`
  ),
  value: "browserDefault",
  data: browserTimezoneValue,
  startIcon: timezoneOptionIcon,
};
export const timezoneOptions: AutocompleteItem[] = timezoneValues.map((tz) => {
  return {
    label: `${tz.label} GMT${tz.offset}`,
    extendedLabel: formatLongLabel(tz.label, tz.offset),
    value: tz.value,
    startIcon: timezoneOptionIcon,
  };
});

export const timezoneOptionsWithBrowserTimezone: AutocompleteItem[] = [
  browserTimezoneOption,
  ...timezoneOptions,
];

const TimezoneFilter = (props: TimezoneFilterProps) => {
  const { value, label, ...otherProps } = props;

  return (
    <SingleAutocomplete
      {...otherProps}
      disableClearable={true}
      value={value}
      label={label ? label : "Timezone"}
      options={timezoneOptionsWithBrowserTimezone}
      startIcon={
        <ClockIcon
          classes="ml-2"
          width="20"
          height="20"
          stroke={allColors.gray[500]}
        />
      }
      extraClasses={classNames("timezone-filter", otherProps.extraClasses)}
    />
  );
};

export default TimezoneFilter;
