import classNames from "classnames";
import { allColors } from "../../../util/colors";
import CloseLgIcon from "../svg/CloseLg";

export type BannerProps = {
  color?: "primary" | "secondary";
  startIcon?: string | React.ReactElement;
  header?: JSX.Element | string;
  body?: JSX.Element | string;
  bottomContent?: JSX.Element;
  rightContent?: JSX.Element;
  onClose?: () => void;
  classes?: string;
};

export default function Banner(props: BannerProps) {
  const {
    color = "primary",
    startIcon,
    header,
    body,
    bottomContent,
    rightContent,
    onClose,
    classes,
  }: BannerProps = props;

  return (
    <div className={classNames(`banner banner--${color}`, classes)}>
      <div className="banner__left-section">
        {startIcon && <div className="banner__icon">{startIcon}</div>}
        <div className="banner__middle-content">
          {(header || onClose) && (
            <div className="banner__top-section">
              {header && <div className="banner__header">{header}</div>}
              {onClose && (
                <button className="banner__close-button" onClick={onClose}>
                  <CloseLgIcon stroke={allColors.gray[400]} />
                  <span className="sr-only">Close banner</span>
                </button>
              )}
            </div>
          )}
          {body && <div className="banner__body">{body}</div>}
          {bottomContent && (
            <div className="banner__bottom-section">{bottomContent}</div>
          )}
        </div>
      </div>
      {rightContent && (
        <div className="banner__right-section">{rightContent}</div>
      )}
    </div>
  );
}
