import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { ConditionalWrapper } from "../../../util/util";

export enum LogoColor {
  Light = "light",
  Dark = "dark",
}

export enum LogoType {
  Symbol = "symbol",
  Full = "full",
}

export enum LogoSize {
  Sm = "sm",
  Md = "md",
  Lg = "lg",
  Xl = "xl",
}

export type LogoProps = {
  size?: LogoSize;
  logoColor?: LogoColor;
  textColor?: LogoColor;
  type?: LogoType;
  hasBackground?: boolean;
  includeOmni?: boolean;
  classes?: string;
};

export type LogoDimensions = {
  width: string;
  height: string;
  viewBox: string;
};

export default function Logo(props: LogoProps) {
  const {
    type = LogoType.Symbol,
    size = LogoSize.Md,
    logoColor = LogoColor.Dark,
    textColor = LogoColor.Dark,
    hasBackground,
    includeOmni,
    classes,
  }: LogoProps = props;

  if (type === LogoType.Symbol) {
    return (
      <ConditionalWrapper
        condition={hasBackground}
        wrapper={(children: any) => {
          return (
            <div
              className={classNames(
                "logo__wrapper",
                `logo__wrapper--${type}`,
                `logo__wrapper--${size}`,
                "flex items-center justify-center",
                { "bg-brand-700": logoColor === LogoColor.Light },
                { "bg-white": logoColor === LogoColor.Dark }
              )}
            >
              {children}
            </div>
          );
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="82"
          height="71"
          viewBox="0 0 82 71"
          fill="none"
          className={classNames(
            "logo",
            `logo--${size}`,
            `logo--${type}`,
            { "logo--withBackground": hasBackground },
            classes
          )}
        >
          <path
            d="M81.3452 30.2963H65.8222C61.9087 30.2963 58.1196 31.5924 55.0569 33.974L31.8837 52.0113C28.2254 54.859 23.6902 56.4041 19.0111 56.4041H0V40.7101H15.523C19.4365 40.7101 23.2256 39.4139 26.2883 37.0324L49.4615 18.9951C53.1198 16.1474 57.655 14.6022 62.3341 14.6022H81.3452V30.2963Z"
            fill={
              logoColor === LogoColor.Dark
                ? allColors.brand[700]
                : allColors.white
            }
          />
          <path
            d="M51.6539 10.7522L23.1274 32.9844C21.0333 34.6125 18.4352 35.5064 15.7586 35.5064H0.00654067V19.8123H11.086C13.7626 19.8123 16.3672 18.9248 18.4614 17.2903L40.6399 0L51.6539 10.7522Z"
            fill={
              logoColor === LogoColor.Dark
                ? allColors.brand[700]
                : allColors.white
            }
          />
          <path
            d="M29.7175 60.2478L58.244 38.0157C60.3381 36.3875 62.9362 35.4936 65.6128 35.4936H81.3649V51.1877H70.2854C67.6088 51.1877 65.0042 52.0752 62.91 53.7097L40.7315 71L29.7175 60.2478Z"
            fill={
              logoColor === LogoColor.Dark
                ? allColors.brand[700]
                : allColors.white
            }
          />
        </svg>
      </ConditionalWrapper>
    );
  } else if (type === LogoType.Full) {
    return (
      <div className="flex items-center flex-nowrap">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="288"
          height="73"
          viewBox="0 0 288 73"
          fill="none"
          className={classNames(
            "logo",
            `logo--${size}`,
            `logo--${type}`,
            { "logo--withBackground": hasBackground },
            classes
          )}
        >
          <path
            d="M81.2577 31.1817H65.7514C61.8422 31.1817 58.0571 32.4947 54.9977 34.9072L31.8494 53.1789C28.1951 56.0636 23.6648 57.6288 18.9906 57.6288H0V41.7308H15.5063C19.4156 41.7308 23.2006 40.4178 26.26 38.0053L49.4084 19.7336C53.0627 16.849 57.593 15.2837 62.2671 15.2837H81.2577V31.1817Z"
            fill={
              logoColor === LogoColor.Dark
                ? allColors.brand[700]
                : allColors.white
            }
          />
          <path
            d="M51.5983 11.3836L23.1026 33.9047C21.0106 35.554 18.4154 36.4595 15.7416 36.4595H0.00653364V20.5615H11.0741C13.7478 20.5615 16.3496 19.6625 18.4415 18.0067L40.5962 0.49176L51.5983 11.3836Z"
            fill={
              logoColor === LogoColor.Dark
                ? allColors.brand[700]
                : allColors.white
            }
          />
          <path
            d="M29.6855 61.5225L58.1813 39.0014C60.2732 37.3521 62.8685 36.4466 65.5422 36.4466H81.2773V52.3446H70.2098C67.5361 52.3446 64.9343 53.2436 62.8424 54.8994L40.6877 72.4143L29.6855 61.5225Z"
            fill={
              logoColor === LogoColor.Dark
                ? allColors.brand[700]
                : allColors.white
            }
          />
          <path
            d="M190.299 28.5364C187.841 25.4965 184.258 23.4785 179.146 23.4785C168.843 23.4785 161.11 31.8091 161.11 42.8821C161.11 53.9551 168.837 62.2857 179.146 62.2857C184.258 62.2857 187.834 60.2677 190.299 57.2666V61.7553H200.105V24.4681H190.299V28.5364ZM180.99 53.0366C175.106 53.0366 171.38 48.5091 171.38 42.8821C171.38 37.2551 175.113 32.7276 180.99 32.7276C186.716 32.7276 190.599 37.061 190.599 42.8821C190.606 48.6967 186.723 53.0366 180.99 53.0366Z"
            fill={
              textColor === LogoColor.Dark ? allColors.black : allColors.white
            }
          />
          <path
            d="M216.709 11.3966H206.674V47.0215C206.674 56.6069 211.368 61.7424 220.631 61.7424C225.9 61.7424 227.803 61.7424 227.803 61.7424V52.4998C227.803 52.4998 224.704 52.4998 222.285 52.4998C219.206 52.4998 216.709 50.7858 216.709 46.226V33.2902H227.803V24.4616H216.709V11.3966Z"
            fill={
              textColor === LogoColor.Dark ? allColors.black : allColors.white
            }
          />
          <path
            d="M272.583 41.8149L287.265 24.4681H274.962L264.464 37.5914H260.927V11.3966H250.892V61.7488H260.927V46.4135H264.313L275.348 61.7488H287.69L272.583 41.8149Z"
            fill={
              textColor === LogoColor.Dark ? allColors.black : allColors.white
            }
          />
          <path
            d="M244.368 24.4681H234.333V61.7553H244.368V24.4681Z"
            fill={
              textColor === LogoColor.Dark ? allColors.black : allColors.white
            }
          />
          <path
            d="M244.368 11.3966H234.333V20.568H244.368V11.3966Z"
            fill={
              textColor === LogoColor.Dark ? allColors.black : allColors.white
            }
          />
          <path
            d="M127.607 41.8084H146.323C145.421 45.5921 143.538 48.5738 140.668 50.7535C137.798 52.9331 134.353 54.0262 130.313 54.0262C125.325 54.0262 121.128 52.3446 117.729 48.9813C114.33 45.618 112.63 41.4462 112.63 36.453C112.63 31.5181 114.277 27.3657 117.565 24.0024C120.86 20.6391 124.894 18.9575 129.672 18.9575C137.053 18.9575 142.361 22.0297 145.597 28.1742L155.311 24.785C153.455 19.6366 150.186 15.6977 145.519 12.9683C140.845 10.2388 135.562 8.86764 129.672 8.86764C121.815 8.86764 115.179 11.5324 109.76 16.8684C104.347 22.1979 101.634 28.724 101.634 36.4466C101.634 44.2209 104.367 50.7599 109.839 56.07C115.304 61.3737 122.024 64.0255 129.986 64.0255C137.844 64.0255 144.322 61.4254 149.415 56.2253C154.514 51.0251 157.063 44.4861 157.063 36.6083C157.063 35.3988 156.985 33.8788 156.822 32.0355H127.607V41.8084Z"
            fill={
              textColor === LogoColor.Dark ? allColors.black : allColors.white
            }
          />
        </svg>
        {includeOmni && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="199"
            height="55"
            viewBox="0 0 199 55"
            fill="none"
            className={`logo__omni-text logo__omni-text--${size}`}
          >
            <path
              d="M195.033 0.398438C196.779 0.398438 198.048 1.7472 198.048 3.41332C198.048 5.07944 196.779 6.34887 195.033 6.34887C193.209 6.34887 191.939 5.07944 191.939 3.41332C191.939 1.7472 193.209 0.398438 195.033 0.398438ZM196.938 15.2348V54.0316H193.129V15.2348H196.938Z"
              fill={
                textColor === LogoColor.Dark ? allColors.black : allColors.white
              }
            />
            <path
              d="M162.351 14.2828C171.713 14.2828 177.981 21.0266 177.981 30.944V54.0316H174.093V31.4993C174.093 23.3274 169.174 17.9324 161.875 17.9324C152.593 17.9324 146.404 24.2002 146.404 34.5936V54.0316H142.516V15.2348H146.404V23.4861C149.419 17.6944 154.973 14.2828 162.351 14.2828Z"
              fill={
                textColor === LogoColor.Dark ? allColors.black : allColors.white
              }
            />
            <path
              d="M114.007 14.2828C122.179 14.2828 128.13 19.9158 128.13 28.4051V54.0316H124.242V28.9605C124.242 22.296 119.72 17.9324 113.134 17.9324C105.042 17.9324 99.7262 23.8828 99.7262 33.6415V54.0316H95.8386V28.9605C95.8386 22.296 91.3162 17.9324 84.7311 17.9324C76.7972 17.9324 71.4815 23.8828 71.4815 33.6415V54.0316H67.5939V15.2348H71.4815V22.8514C74.0997 17.5357 79.0187 14.2828 85.6038 14.2828C92.1096 14.2828 97.1873 17.7737 99.0121 23.4068C101.789 17.7737 107.105 14.2828 114.007 14.2828Z"
              fill={
                textColor === LogoColor.Dark ? allColors.black : allColors.white
              }
            />
            <path
              d="M27.881 0.00177002C43.1141 0.00177002 55.0149 12.1406 55.0149 27.4531C55.0149 42.8448 43.1141 54.9044 27.881 54.9044C12.5686 54.9044 0.667725 42.8448 0.667725 27.4531C0.667725 12.1406 12.5686 0.00177002 27.881 0.00177002ZM27.881 51.1754C40.8926 51.1754 51.048 40.8614 51.048 27.4531C51.048 14.1241 40.8926 3.73071 27.881 3.73071C14.7901 3.73071 4.63468 14.1241 4.63468 27.4531C4.63468 40.8614 14.7901 51.1754 27.881 51.1754Z"
              fill={
                textColor === LogoColor.Dark ? allColors.black : allColors.white
              }
            />
          </svg>
        )}
      </div>
    );
  } else {
    return null;
  }
}
