import classNames from "classnames";
import Dot from "../dot";
import { ConditionalWrapper } from "../../../util/util";
import { SvgType } from "../svg/types";
import UserIcon from "../svg/User";
import { allColors } from "../../../util/colors";
import { UserInfo } from "../../../types/common";

export type ProfileImageProps = {
  userInfo?: UserInfo | null;
  onClick?: () => void;
  dotColor?: string;
  classes?: string;
};

export default function ProfileImage(props: ProfileImageProps) {
  const { userInfo, onClick, dotColor, classes }: ProfileImageProps = props;

  return (
    <div
      className={classNames(
        "profile-image",
        { "profile-image--no-image": !userInfo?.avatar },
        classes
      )}
    >
      <ConditionalWrapper
        condition={typeof onClick !== "undefined"}
        wrapper={(children: any) => {
          return (
            <button className="profile-image__button" onClick={onClick}>
              {children}
            </button>
          );
        }}
      >
        <div className="profile-image__img-wrapper">
          {userInfo?.avatar ? (
            <img
              src={userInfo?.avatar}
              alt={
                userInfo?.first_name
                  ? `${userInfo?.first_name} ${userInfo?.last_name} Avatar`
                  : "User Avatar"
              }
            />
          ) : (
            <UserIcon
              type={SvgType.Solid}
              width="40"
              height="40"
              fill={allColors.white}
              classes="profile-image__icon mt-2"
            />
          )}
        </div>
        {dotColor && <Dot classes="profile-image__dot" color={dotColor} />}
      </ConditionalWrapper>
    </div>
  );
}
