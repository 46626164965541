import { AutocompleteItem, SingleAutocompleteProps } from "../types";
import classNames from "classnames";
import SingleAutocomplete from "../SingleAutocomplete";

export type DayFilterProps = Omit<SingleAutocompleteProps, "options" | "label">;

export const dayOptions: AutocompleteItem[] = [
  { label: "Today", value: "today" },
  { label: "Yesterday", value: "yesterday" },
  { label: "Tomorrow", value: "tomorrow" },
];

const DayFilter = (props: DayFilterProps) => {
  const { value, ...otherProps } = props;

  return (
    <SingleAutocomplete
      {...otherProps}
      disableClearable={true}
      value={value}
      label="Date"
      options={dayOptions}
      extraClasses={classNames("trip-status-filter", otherProps.extraClasses)}
    />
  );
};

export default DayFilter;
