import React from "react";
import classNames from "classnames";
import { useEffect, useState } from "react";
import CopyIcon from "../../components/common/svg/Copy";
import StyledButton from "../../components/common/button/StyledButton";
import { allColors } from "../../util/colors";
import { useSmBreakpoint } from "../../util/breakpoints";
import Logo, { LogoSize, LogoType } from "../../components/common/logo/Logo";
import StyledTooltip from "../../components/common/tooltip/StyledTooltip";
import { useNavigate } from "react-router";

export type SignedOutContainerProps = {
  innerContentBorderVariant?: boolean;
  headerButtonType?: "sign_in" | "sign_up";
  children: any;
  classes?: string;
};

export default function SignedOutContainer(props: SignedOutContainerProps) {
  const {
    innerContentBorderVariant,
    headerButtonType,
    children,
    classes,
  }: SignedOutContainerProps = props;

  const isSm = useSmBreakpoint();
  const navigate = useNavigate();

  const [justCopiedEmail, setJustCopiedEmail] = useState<boolean>(false);

  useEffect(() => {
    if (justCopiedEmail) {
      // "Email copied to clipboard!" text stays on the screen for the duration of this setTimeout
      setTimeout(() => {
        setJustCopiedEmail(false);
      }, 1500);
    }
  }, [justCopiedEmail]);

  const SignedOutHeader = () => {
    return (
      <header className="signed-out-container__header mb-12 sm:mb-none">
        <Logo type={LogoType.Full} includeOmni size={LogoSize.Sm} />
        {headerButtonType === "sign_up" && (
          <StyledButton
            size="sm"
            color="primary"
            outlined
            onClick={() => navigate("/sign-up")}
          >
            Sign up
          </StyledButton>
        )}
        {headerButtonType === "sign_in" && (
          <StyledButton
            size="sm"
            color="primary"
            outlined
            onClick={() => navigate("/login")}
          >
            Log in
          </StyledButton>
        )}
      </header>
    );
  };

  const SignedOutFooter = () => {
    return (
      <footer className="signed-out-container__footer">
        <span className="text-gray-600 font-medium">&copy; Gatik</span>
        <StyledTooltip
          open={justCopiedEmail}
          placement={"top"}
          body={`Email address copied to clipboard!`}
        >
          <StyledButton
            size="sm"
            color="secondary"
            textOnly
            onClick={() => {
              navigator.clipboard.writeText("omni@gatik.ai");
              setJustCopiedEmail(true);
            }}
            classes="signed-out-container__copy-email text-gray-600"
          >
            <CopyIcon
              width="16"
              height="16"
              stroke={allColors.gray[600]}
              classes="mr-2"
            />
            omni@gatik.ai
          </StyledButton>
        </StyledTooltip>
      </footer>
    );
  };

  return (
    <React.Fragment>
      {!isSm && SignedOutHeader()}
      <div className={classNames("signed-out-container", classes)}>
        <div
          className={classNames("signed-out-container__inner-section", {
            "signed-out-container__inner-section--with-border":
              innerContentBorderVariant,
          })}
        >
          {isSm && !innerContentBorderVariant && SignedOutHeader()}
          {children}
        </div>
        {SignedOutFooter()}
      </div>
    </React.Fragment>
  );
}
