import React from "react";
import classNames from "classnames";
import StyledButton from "../../components/common/button/StyledButton";
import SignedOutTemplate from "../../components/templates/SignedOutTemplate";
import SignedOutContainer from "../signed-out/SignedOutContainer";
import { useNavigate } from "react-router";
import { useSmBreakpoint } from "../../util/breakpoints";
import { useLocalStorage } from "../../util/local-storage";
import { UserInfo } from "../../types/common";
import SignUpErrorIcon from "../signed-out/sign-up/SignUpErrorIcon";

export type PageNotFoundProps = {
  classes?: string;
};

export default function PageNotFound(props: PageNotFoundProps) {
  const { classes }: PageNotFoundProps = props;

  const isSm = useSmBreakpoint();
  const navigate = useNavigate();
  const [localStorageValue] = useLocalStorage();

  const parsedUserInfo: UserInfo | null =
    typeof localStorageValue === "string"
      ? JSON.parse(localStorageValue)
      : null;

  const isAuth = typeof parsedUserInfo?.access_key === "string";

  return (
    <SignedOutTemplate blockRedirect>
      <SignedOutContainer
        innerContentBorderVariant={isSm}
        headerButtonType={isAuth ? undefined : "sign_in"}
      >
        <div className="sign-up-page__image-container">
          <SignUpErrorIcon />
        </div>
        <div className={classNames("page-not-found", classes)}>
          <div className="text-center mb-12">
            <h1 className="text-display-sm font-semibold mb-2 mt-12 sm:mt-none">
              404: Page not found
            </h1>
            <p className="text-md text-gray-600 font-medium">
              We can't seem to find this page.
            </p>
          </div>
          <div className="text-center mt-2">
            {isAuth ? (
              <StyledButton
                size={"2xl"}
                color="primary"
                classes="mb-2 mt-8 w-full"
                onClick={() => navigate("/live-tracking")}
              >
                Go back to live tracking
              </StyledButton>
            ) : (
              <StyledButton
                size={isSm ? "md" : "2xl"}
                color="primary"
                classes="mb-6 mt-8 w-full"
                onClick={() => navigate("/login")}
              >
                Go to login page
              </StyledButton>
            )}
          </div>
        </div>
      </SignedOutContainer>
    </SignedOutTemplate>
  );
}
