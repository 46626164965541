import { SvgProps } from "../../../components/common/svg/types";

export default function SignUpErrorIcon(props: SvgProps) {
  const { width = "250", height = "168" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 250 168"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M133.179 122.571C133.085 122.571 132.994 122.529 132.935 122.451L114.481 98.5389L99.7929 106.318C99.6921 106.371 99.5729 106.366 99.4771 106.303C99.3829 106.241 99.3304 106.131 99.3404 106.019L100.793 89.0181L90.6779 82.1489C90.6071 82.0998 90.5588 82.0239 90.5463 81.9381C90.5338 81.8531 90.5571 81.7664 90.6113 81.6989L102.425 67.0506L100.273 56.7689C100.248 56.6473 100.297 56.5223 100.399 56.4514C100.502 56.3823 100.636 56.3781 100.74 56.4439L111.372 63.1798L140.016 43.2373C140.112 43.1706 140.236 43.1631 140.34 43.2189C140.441 43.2739 140.505 43.3823 140.502 43.4981L139.978 65.0589L162.051 63.2431C162.171 63.2314 162.289 63.2948 162.348 63.4023C162.406 63.5089 162.397 63.6398 162.323 63.7373L147.273 83.5814L149.518 104.604C149.528 104.701 149.492 104.797 149.42 104.863C149.35 104.929 149.251 104.957 149.154 104.94L134.845 102.247L133.486 122.282C133.477 122.411 133.39 122.52 133.268 122.557C133.239 122.567 133.209 122.571 133.179 122.571ZM114.566 97.8348C114.66 97.8348 114.751 97.8773 114.81 97.9548L132.925 121.427L134.251 101.859C134.257 101.771 134.3 101.69 134.37 101.636C134.44 101.581 134.53 101.559 134.617 101.576L148.86 104.256L146.645 83.5248C146.637 83.4473 146.659 83.3681 146.706 83.3056L161.412 63.9156L139.685 65.7031C139.598 65.7098 139.511 65.6798 139.448 65.6198C139.384 65.5598 139.349 65.4756 139.35 65.3881L139.869 44.0923L111.558 63.8048C111.455 63.8756 111.321 63.8798 111.215 63.8123L101.028 57.3581L103.06 67.0673C103.079 67.1581 103.056 67.2523 102.998 67.3248L91.3021 81.8256L101.29 88.6081C101.382 88.6706 101.433 88.7789 101.424 88.8906L100.004 105.507L114.422 97.8723C114.467 97.8464 114.517 97.8348 114.566 97.8348Z"
        fill="#FDB022"
      />
      <path
        d="M125.288 68.8538C125.219 68.8538 125.15 68.8305 125.092 68.7847C124.96 68.6763 124.94 68.4822 125.048 68.3497L129.07 63.418C129.177 63.2855 129.373 63.2672 129.505 63.3738C129.637 63.4822 129.656 63.6763 129.549 63.8088L125.527 68.7405C125.467 68.8147 125.378 68.8538 125.288 68.8538Z"
        fill="#FDB022"
      />
      <path
        d="M121.594 69.8297C121.534 69.8297 121.474 69.813 121.422 69.7763C121.28 69.6813 121.242 69.4888 121.338 69.348L123.691 65.863C123.787 65.7213 123.98 65.6838 124.119 65.7797C124.261 65.8747 124.298 66.0672 124.202 66.208L121.85 69.693C121.79 69.7822 121.693 69.8297 121.594 69.8297Z"
        fill="#FDB022"
      />
      <path
        d="M111.856 56.5478C111.726 56.5478 111.605 56.4653 111.562 56.3344L109.694 50.6294C109.641 50.4678 109.73 50.2928 109.893 50.2394C110.054 50.1878 110.228 50.2753 110.283 50.4378L112.15 56.1428C112.203 56.3044 112.114 56.4794 111.952 56.5328C111.919 56.5428 111.888 56.5478 111.856 56.5478Z"
        fill="#FDB022"
      />
      <path
        d="M124.184 97.8377C124.112 97.8377 124.038 97.8127 123.98 97.7602L121.15 95.2693C121.022 95.1568 121.009 94.9618 121.122 94.8335C121.235 94.7052 121.431 94.6927 121.558 94.806L124.388 97.2968C124.517 97.4093 124.529 97.6043 124.416 97.7327C124.355 97.8018 124.27 97.8377 124.184 97.8377Z"
        fill="#FDB022"
      />
      <path
        d="M56.6953 41.793C88.6053 63.6371 93.8278 70.9255 85.7661 81.5763C80.8478 88.0738 71.9836 98.2096 68.5569 86.2771C65.1303 74.3446 53.7803 57.8338 53.7803 57.8338L56.6953 41.793Z"
        fill="#A4BCFD"
      />
      <path
        d="M77.806 67.805C75.7802 64.9408 72.2627 64.3941 69.8335 61.8533C69.0177 61 67.7502 60.1991 66.8877 60.4341C66.1327 63.1858 67.3393 65.9125 68.856 68.5208C69.8793 70.2816 69.6735 72.6025 68.2277 73.23C69.4468 76.0175 70.6768 78.8 71.9302 81.5716C73.4052 84.835 75.466 85.2716 74.9727 82.26C74.6435 80.2483 78.6043 75.9033 78.9502 72.985C79.1727 71.115 78.9102 69.3658 77.806 67.805Z"
        fill="#2D3282"
      />
      <path
        d="M25.8236 61.8533C21.2269 53.3074 22.5119 35.5324 22.5119 35.5324C33.8628 30.8208 49.4961 30.1783 59.7761 35.5324V61.8533H25.8236Z"
        fill="#A4BCFD"
      />
      <path
        d="M87.8163 74.8273H84.818C84.6471 74.8273 84.5088 74.689 84.5088 74.5181C84.5088 74.3473 84.6471 74.209 84.818 74.209H87.8163C87.9871 74.209 88.1255 74.3473 88.1255 74.5181C88.1255 74.689 87.9871 74.8273 87.8163 74.8273Z"
        fill="white"
      />
      <path
        d="M77.8282 131.678H74.7374C74.7374 124.239 68.6857 118.187 61.2466 118.187H44.774C35.6307 118.187 28.1924 110.749 28.1924 101.605C28.1924 97.1771 29.9165 93.013 33.049 89.8805L41.5599 81.3705C41.8499 81.0805 42.2424 80.918 42.6524 80.918H95.7641V84.0088H43.2932L35.2349 92.0663C32.6874 94.6146 31.2832 98.003 31.2832 101.605C31.2832 109.045 37.3349 115.096 44.774 115.096H61.2466C70.3899 115.097 77.8282 122.535 77.8282 131.678Z"
        fill="#2D3282"
      />
      <path
        d="M120.135 75.332H109.243V80.1945H120.135V75.332Z"
        fill="#FCA5A5"
      />
      <path
        d="M120.135 84.7344H109.243V89.5969H120.135V84.7344Z"
        fill="#FCA5A5"
      />
      <path
        d="M110.922 94.7641H100.733C95.8114 94.7641 91.8223 90.7749 91.8223 85.8532V79.0749C91.8223 74.1532 95.8114 70.1641 100.733 70.1641H110.923V94.7641H110.922Z"
        fill="#F04438"
      />
      <path
        d="M25.8232 61.8538C41.4232 93.9879 47.4307 93.8038 54.1057 92.7279C54.1057 92.7279 59.7791 95.7771 66.7882 93.0471C66.7882 93.0471 73.0199 97.8963 78.1357 94.1829C84.1257 89.8354 63.6524 59.3496 63.6524 59.3496L25.8232 61.8538Z"
        fill="#A4BCFD"
      />
      <path
        d="M79.4889 88.9962C79.3572 88.9962 79.2364 88.912 79.1947 88.7804C77.3305 82.8854 68.9214 68.4854 68.8364 68.3412C65.3414 63.2154 66.3672 60.3562 66.4139 60.2379C66.4739 60.0779 66.6539 59.9987 66.8122 60.0595C66.9722 60.1195 67.0522 60.2987 66.9905 60.4579C66.9814 60.4829 66.0564 63.1679 69.3589 68.0112C69.7172 68.6229 77.8997 82.6329 79.7839 88.5945C79.8355 88.757 79.7455 88.9304 79.5822 88.982C79.5505 88.992 79.5189 88.9962 79.4889 88.9962Z"
        fill="#2D3282"
      />
      <path
        d="M68.3021 94.3917C68.2112 94.3917 68.1196 94.3509 68.0587 94.2734C68.0071 94.2084 62.7937 87.5451 53.5204 73.4659C53.4271 73.3226 53.4662 73.1317 53.6087 73.0376C53.7504 72.9434 53.9429 72.9826 54.0371 73.1259C63.2929 87.1801 68.4929 93.8259 68.5446 93.8917C68.6496 94.0259 68.6271 94.2201 68.4921 94.3259C68.4362 94.3701 68.3696 94.3917 68.3021 94.3917Z"
        fill="#2D3282"
      />
      <path
        d="M54.1053 93.0364C54.0345 93.0364 53.9628 93.0123 53.9053 92.9631C53.7245 92.8089 49.3761 89.0298 40.8003 75.5606C40.7086 75.4164 40.7511 75.2256 40.8953 75.1339C41.0386 75.0431 41.2303 75.0848 41.322 75.2289C49.832 88.5956 54.2628 92.4564 54.3061 92.4939C54.4361 92.6048 54.452 92.7998 54.3403 92.9289C54.2786 92.9998 54.192 93.0364 54.1053 93.0364Z"
        fill="#2D3282"
      />
      <path
        d="M51.1278 70.5949C51.0486 70.5949 50.9695 70.5649 50.9095 70.504L49.1453 68.7399C49.0245 68.619 49.0245 68.4232 49.1453 68.3032C49.2661 68.1832 49.462 68.1824 49.582 68.3032L51.3461 70.0674C51.467 70.1882 51.467 70.384 51.3461 70.504C51.2861 70.5649 51.207 70.5949 51.1278 70.5949Z"
        fill="#2D3282"
      />
      <path
        d="M39.1348 73.6047C39.0548 73.6047 38.9757 73.5747 38.9157 73.5139L37.3282 71.9139C37.2073 71.7922 37.209 71.5972 37.329 71.4772C37.4507 71.3556 37.6465 71.3572 37.7657 71.4781L39.3532 73.0772C39.474 73.1989 39.4723 73.3939 39.3523 73.5139C39.2923 73.5756 39.2132 73.6047 39.1348 73.6047Z"
        fill="#2D3282"
      />
      <path
        d="M188.412 39.6367C162.202 68.0692 159.604 71.8476 168.24 82.3792C173.408 88.6809 184.82 97.5325 185.489 85.1359C186.158 72.7392 193.524 54.1067 193.524 54.1067L188.412 39.6367Z"
        fill="#A4BCFD"
      />
      <path
        d="M172.621 74.5635C174.338 70.9502 178.406 69.401 180.684 66.0219C181.449 64.8869 182.767 63.6844 183.854 63.6994C185.431 66.4302 184.657 69.6719 183.487 72.8727C182.696 75.0335 183.512 77.4544 185.392 77.7277C184.618 81.0377 183.832 84.346 183.014 87.6494C182.052 91.5377 179.697 92.5702 179.549 89.2185C179.449 86.9802 173.332 82.4527 172.524 80.4077C171.764 78.4852 171.685 76.5327 172.621 74.5635Z"
        fill="#2D3282"
      />
      <path
        d="M220.156 51.9414C221.451 42.7239 220.731 34.4064 220.731 34.4064C209.372 31.0005 198.369 30.7414 187.811 34.4064V51.9414L194.641 65.4339L220.156 51.9414Z"
        fill="#A4BCFD"
      />
      <path
        d="M167.157 74.1212C167.136 74.1212 167.115 74.1195 167.094 74.1145L164.608 73.6004C164.44 73.5662 164.333 73.402 164.368 73.2354C164.402 73.0679 164.57 72.9587 164.733 72.9954L167.219 73.5095C167.386 73.5437 167.494 73.7079 167.459 73.8745C167.428 74.0212 167.3 74.1212 167.157 74.1212Z"
        fill="white"
      />
      <path
        d="M231.367 131.68H228.276V100.598C228.276 91.4516 220.834 84.0108 211.687 84.0108H157.227V80.9199H211.687C222.538 80.9199 231.366 89.7474 231.366 100.597V131.68H231.367Z"
        fill="#2D3282"
      />
      <path
        d="M151.577 94.7649H148.873V70.1641H151.577C156.499 70.1641 160.488 74.1532 160.488 79.0749V85.8532C160.488 90.7757 156.498 94.7649 151.577 94.7649Z"
        fill="#2D3282"
      />
      <path
        d="M149.366 70.1641H130.266V94.7649H149.366V70.1641Z"
        fill="#FCA5A5"
      />
      <path
        d="M139.816 75.332H134.904V80.1945H139.816V75.332Z"
        fill="#2D3282"
      />
      <path
        d="M139.816 84.7344H134.904V89.5969H139.816V84.7344Z"
        fill="#2D3282"
      />
      <path
        d="M177.924 59.3486C175.918 85.4428 174.237 93.3811 178.14 96.277C180.841 98.2803 186.755 98.0986 189.324 94.052C189.324 94.052 198.032 97.7695 201.404 92.727C201.404 92.727 212.263 95.4086 214.96 80.1936C217.658 64.9786 220.123 51.9395 220.123 51.9395L177.924 59.3486Z"
        fill="#A4BCFD"
      />
      <path
        d="M175.651 89.9038C175.644 89.9038 175.637 89.9038 175.631 89.903C175.46 89.8913 175.332 89.7446 175.342 89.5746C175.357 89.3463 176.833 66.7571 176.787 61.8546C176.785 61.6838 176.922 61.5446 177.092 61.543C177.093 61.543 177.094 61.543 177.096 61.543C177.266 61.543 177.403 61.6796 177.405 61.8488C177.452 66.7746 175.974 89.3871 175.959 89.6146C175.948 89.778 175.812 89.9038 175.651 89.9038Z"
        fill="#2D3282"
      />
      <path
        d="M189.325 94.3619C189.313 94.3619 189.3 94.3611 189.288 94.3594C189.119 94.3394 188.998 94.1852 189.018 94.0161C190.1 84.9419 190.889 71.8094 190.896 71.6777C190.906 71.5077 191.057 71.3736 191.223 71.3877C191.394 71.3977 191.524 71.5444 191.513 71.7144C191.505 71.8469 190.715 84.9977 189.631 94.0902C189.613 94.2469 189.479 94.3619 189.325 94.3619Z"
        fill="#2D3282"
      />
      <path
        d="M201.405 93.0359C201.384 93.0359 201.363 93.0342 201.342 93.0301C201.175 92.9959 201.067 92.8326 201.101 92.6651C202.622 85.2142 204.049 73.3767 204.064 73.2576C204.085 73.0876 204.245 72.9626 204.407 72.9876C204.577 73.0076 204.697 73.1617 204.677 73.3309C204.663 73.4501 203.232 85.3126 201.707 92.7876C201.677 92.9351 201.548 93.0359 201.405 93.0359Z"
        fill="#2D3282"
      />
      <path
        d="M191.206 68.8292C191.171 68.8292 191.135 68.8234 191.1 68.8109C190.939 68.7525 190.857 68.5759 190.915 68.415L192.013 65.3984C192.071 65.2375 192.248 65.1567 192.409 65.2134C192.569 65.2717 192.652 65.4484 192.594 65.6092L191.496 68.6259C191.45 68.7517 191.332 68.8292 191.206 68.8292Z"
        fill="#2D3282"
      />
      <path
        d="M205.471 70.4707C205.3 70.4707 205.162 70.3323 205.162 70.1615V67.774C205.162 67.6032 205.3 67.4648 205.471 67.4648C205.642 67.4648 205.78 67.6032 205.78 67.774V70.1615C205.78 70.3323 205.642 70.4707 205.471 70.4707Z"
        fill="#2D3282"
      />
    </svg>
  );
}
