import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps, SvgType } from "./types";

export default function CircleIcon(props: SvgProps) {
  const {
    type = SvgType.Line,
    width = "24",
    height = "24",
    stroke = allColors.black,
    fill = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__circle", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      {type === SvgType.Solid ? (
        <path
          d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1Z"
          fill={fill}
        />
      ) : (
        <path
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}
