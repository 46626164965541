import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgDirection, SvgProps } from "./types";

export default function ArrowIcon(props: SvgProps) {
  const {
    width = "24",
    height = "24",
    stroke = allColors.black,
    classes,
    direction = SvgDirection.Up,
  } = props;

  return (
    <svg
      className={classNames(
        classes,
        "icon icon__arrow",
        `icon__arrow--${direction}`
      )}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 20V4M12 4L6 10M12 4L18 10"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
