import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import TripTimelineGroup from "../trip-timeline-group/TripTimelineGroup";
import { Trip, TripStatus } from "../../../../types/trip";
import TripTimelineNoResults from "../trip-timeline-no-results/TripTimelineNoResults";
import CloseSmIcon from "../../../common/svg/CloseSm";
import RouteIcon from "../../../common/svg/Route";
import { allColors } from "../../../../util/colors";
import { SvgType } from "../../../common/svg/types";
import { dayjs } from "../../../../util/date-util";
import { useOverlayContext } from "../../../../context/OverlayContext";
import Banner from "../../../common/banner/Banner";
import StyledButton from "../../../common/button/StyledButton";
import CopyIcon from "../../../common/svg/Copy";
import StyledTooltip from "../../../common/tooltip/StyledTooltip";
import { useLocation, useSearchParams } from "react-router-dom";
import { fetchTripById } from "../../../../api/trip";
import LoadingSpinner from "../../../common/loading-spinner/LoadingSpinner";
import { POLLING_SPEED } from "../../../../util/constants";
import StyledTabs, { TabType } from "../../../common/tabs/StyledTabs";
import StyledSwitch, { SwitchSize } from "../../../form/switch/StyledSwitch";
import { useUserContext } from "../../../../context/UserContext";
import AlertCircleIcon from "../../../common/svg/AlertCircle";

export type TripTimelineDrawerProps = {
  trip?: Trip;
  tripId?: string;
  onClose?: () => void;
  isForcedOpen?: boolean;
  classes?: string;
};

export default function TripTimelineDrawer(props: TripTimelineDrawerProps) {
  const { trip, tripId, onClose, isForcedOpen, classes } = props;

  const { userInfo } = useUserContext();
  let location = useLocation();
  const [filterParams] = useSearchParams();

  const { isOverlayOpen } = useOverlayContext();

  const [currentTab, setCurrentTab] = useState<number>(0);
  // const [avView, setAvView] = useState<boolean>(false);
  const [myTimezoneActive, setMyTimezoneActive] = useState<boolean>(false);
  const [myTimezoneCounter, setMyTimezoneCounter] = useState<number>(0);
  const [isBannerDismissed, setIsBannerDismissed] = useState<boolean>(false);
  const [justCopiedTripUrl, setJustCopiedTripUrl] = useState<boolean>(false);
  const [fetchedTrip, setFetchedTrip] = useState<Trip | undefined>(undefined);
  const [isLoadingTrip, setIsLoadingTrip] = useState<boolean>(false);

  const shownTrip: Trip | undefined = fetchedTrip ? fetchedTrip : trip;

  const browserTimezoneShortText = new Date()
    .toLocaleTimeString("en-us", {
      timeZone: userInfo?.timezone || undefined,
      timeZoneName: "short",
    })
    .split(" ")[2];

  useEffect(() => {
    if (!trip) {
      setIsLoadingTrip(true);
    }
  }, [trip]);

  useEffect(() => {
    if (shownTrip) {
      // Refresh the trip data based on the global polling speed
      const refreshInterval = setTimeout(() => {
        fetchSingleTrip(shownTrip?.id);
      }, POLLING_SPEED);
      // Clear timeout when unmounting the component
      return () => clearTimeout(refreshInterval);
    }
  }, [shownTrip]);

  useEffect(() => {
    if (justCopiedTripUrl) {
      // "Trip URL copied to clipboard!" text stays on the screen for the duration of this setTimeout
      setTimeout(() => {
        setJustCopiedTripUrl(false);
      }, 1500);
    }
  }, [justCopiedTripUrl]);

  useEffect(() => {
    if (tripId && !isLoadingTrip) {
      fetchSingleTrip(tripId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tripId]);

  const fetchSingleTrip = async (tripId: string) => {
    await fetchTripById({ id: tripId })
      .then((resp) => {
        setIsLoadingTrip(false);
        setFetchedTrip(resp);
      })
      .catch((error) => {
        setIsLoadingTrip(false);
        console.error(error);
      });
  };

  const handleChangeTimezone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMyTimezoneActive(event.target.checked);
  };

  const estimatedTripDuration = useMemo(() => {
    const diffInMinutes = Math.abs(
      dayjs(shownTrip?.scheduled_start).diff(
        shownTrip?.scheduled_end,
        "minutes"
      )
    );
    const hours = Math.floor(diffInMinutes / 60);
    const minutes = diffInMinutes % 60;
    return `${hours}h ${minutes}m`;
  }, [shownTrip]);

  return (
    <div
      className={classNames(
        "trip-timeline-drawer",
        { "trip-timeline-drawer--hidden": !isOverlayOpen && !isForcedOpen },
        classes
      )}
    >
      {isLoadingTrip ? (
        <LoadingSpinner centered />
      ) : !shownTrip ? (
        <TripTimelineNoResults classes="mt-80" onClose={onClose} />
      ) : (
        <React.Fragment>
          <div className="trip-timeline-drawer__top-section text-gray-900">
            <div className="flex justify-between 2xs:h-24">
              <h2 className="flex items-center 2xs:self-end text-xl font-semibold">
                <RouteIcon
                  classes="mr-2"
                  type={SvgType.Solid}
                  fill={allColors.gray[600]}
                  width="20"
                  height="20"
                />
                {shownTrip.itinerary.name}
                <StyledTooltip
                  open={justCopiedTripUrl}
                  placement={"top"}
                  body={`Trip URL copied to clipboard!`}
                >
                  <StyledButton
                    size="sm"
                    color="secondary"
                    textOnly
                    onClick={() => {
                      // get current url without any filter params
                      const copyUrl = `${window.location.protocol}//${window.location.host}${location.pathname}?view_trip=${filterParams.get("view_trip")}`;
                      navigator.clipboard.writeText(copyUrl);
                      setJustCopiedTripUrl(true);
                    }}
                    classes="signed-out-container__copy-email text-gray-600"
                  >
                    <CopyIcon
                      width="16"
                      height="16"
                      stroke={allColors.gray[600]}
                      classes="mr-2"
                    />
                    <span className="sr-only">Copy trip URL</span>
                  </StyledButton>
                </StyledTooltip>
              </h2>
              <button className="self-start" onClick={onClose}>
                <span className="sr-only">Close trip timeline</span>
                <CloseSmIcon width="28" height="28" />
              </button>
            </div>
            <div className="trip-timeline-drawer__trip-subinfo">
              <div>
                AV# <span>{shownTrip.av.av_id}</span>
              </div>
              <div>
                Lane ID{" "}
                <span>
                  {shownTrip.template?.external_id
                    ? shownTrip.template?.external_id
                    : "--"}
                </span>
              </div>
            </div>
            <div className="trip-timeline-drawer__trip-info">
              <div className="trip-timeline-drawer__trip-info-cell">
                Estimated trip duration <span>{estimatedTripDuration}</span>
              </div>
              <div className="trip-timeline-drawer__trip-info-cell">
                Estimated trip distance{" "}
                <span>
                  {shownTrip.scheduled_trip_distance
                    ? shownTrip.scheduled_trip_distance
                    : "--"}
                </span>
              </div>
            </div>
          </div>

          <div className="trip-timeline-drawer__bottom-section">
            {shownTrip.status === TripStatus.TrackingTimeout &&
              !isBannerDismissed && (
                <Banner
                  color="secondary"
                  classes="trip-timeline-drawer__tracking-timeout-banner"
                  header="Trip has timed out"
                  body="Tracking info may be incomplete. For more information, please contact Gatik dispatch."
                  onClose={() => setIsBannerDismissed(true)}
                  bottomContent={
                    <div className="flex justify-end">
                      <StyledButton
                        color={"secondary"}
                        textOnly
                        classes="banner__text-button banner__text-button--right"
                        onClick={() => setIsBannerDismissed(true)}
                      >
                        Dismiss
                      </StyledButton>
                      <StyledButton
                        color={"primary"}
                        textOnly
                        classes="banner__text-button banner__text-button--right"
                        onClick={() =>
                          console.log("TODO: Setup call gatik modal")
                        }
                      >
                        Call Dispatch
                      </StyledButton>
                    </div>
                  }
                />
              )}
            {/* Re-add tabs and toggle once AV timelines are ready */}
            <div className="trip-timeline-drawer__options">
              <StyledTabs
                tabType={TabType.Underline}
                labels={["Trip Timeline"]}
                extraClasses="trip-timeline-drawer__tabs"
                value={currentTab}
                onChange={(_, newValue) => setCurrentTab(newValue)}
              />
              {/* <label
                className="flex items-center text-sm font-medium text-gray-700"
                htmlFor="av_view_toggle"
              >
                <StyledSwitch
                  id="avViewToggle"
                  extraClasses="mr-4"
                  checked={avView}
                  onChange={(_, checked) => setAvView(!avView)}
                  switchSize={SwitchSize.Sm}
                />{" "}
              </label> */}
              {userInfo?.timezone && (
                <label
                  className="flex items-center text-sm font-medium text-gray-700"
                  htmlFor="myTimezoneToggle"
                >
                  <StyledSwitch
                    // TODO: Key is used here to reset the switch to "Off" when an outside change to the value is made, because the MUI component does not detect the change by default. I believe this is an issue with MUI's component.
                    // The outside change comes from "Revert changes" button on the my timezone banner.
                    key={myTimezoneCounter}
                    id="myTimezoneToggle"
                    extraClasses="mr-4"
                    checked={myTimezoneActive}
                    onChange={handleChangeTimezone}
                    switchSize={SwitchSize.Sm}
                    inputProps={{ "aria-label": "controlled" }}
                  />{" "}
                  My Timezone ({browserTimezoneShortText})
                </label>
              )}
            </div>
            {myTimezoneActive && (
              <Banner
                color="secondary"
                startIcon={
                  <AlertCircleIcon
                    width="16"
                    height="16"
                    type={SvgType.Solid}
                    fill={allColors.primary[600]}
                  />
                }
                classes="trip-timeline-drawer__my-timezone-banner"
                body="Trip times have been converted to your preferred timezone."
                rightContent={
                  <div className="flex justify-end">
                    <StyledButton
                      color={"primary"}
                      textOnly
                      classes="trip-timeline-drawer__my-timezone-banner-button banner__text-button banner__text-button--right"
                      onClick={() => {
                        setMyTimezoneActive(false);
                        setMyTimezoneCounter(myTimezoneCounter + 1);
                      }}
                    >
                      Revert changes
                    </StyledButton>
                  </div>
                }
              />
            )}
            <div className="trip-timeline-drawer__group-container">
              <div className="trip-timeline-drawer__group-container-header">
                Trip {shownTrip.itinerary.name}
              </div>
              <div className="trip-timeline-drawer__group-container-content">
                <TripTimelineGroup
                  trip={shownTrip}
                  useMyTimezone={myTimezoneActive}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}
