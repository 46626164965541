import { Button } from "@mui/material";
import classNames from "classnames";
import { ReactElement, useEffect, useState } from "react";
import ChevronIcon from "../../common/svg/Chevron";
import { SvgDirection } from "../../common/svg/types";
import ActionMenu from "../../common/action-menu/ActionMenu";
import StyledTooltip from "../../common/tooltip/StyledTooltip";
import { useNavigate } from "react-router-dom";
import { useOverlayContext } from "../../../context/OverlayContext";

export type NavigationItemProps = {
  label: string;
  icon?: ReactElement<any, any>;
  linkUrl?: string;
  isExternalLink?: boolean;
  isMinimized?: boolean;
  childItems?: NavigationItemProps[];
  isOpenDefault?: boolean;
  currentPath?: string;
  hideLink?: boolean;
  onClick?: () => void;
  classes?: string;
};

export default function NavigationItem(props: NavigationItemProps) {
  const {
    label,
    icon,
    linkUrl,
    isExternalLink,
    isMinimized,
    childItems,
    isOpenDefault,
    currentPath,
    onClick,
    classes,
  }: NavigationItemProps = props;

  const navigate = useNavigate();

  const { isOverlayOpen, setIsOverlayOpen } = useOverlayContext();

  const [isOpen, setIsOpen] = useState<boolean>(isOpenDefault === true);
  const [focusRingActive, setFocusRingActive] = useState<boolean>(false);

  const isCurrentPath =
    currentPath && linkUrl && currentPath.includes(linkUrl.split("?")[0]);

  const containsCurrentPath =
    childItems &&
    childItems.find(
      (childItem) =>
        currentPath &&
        childItem.linkUrl &&
        currentPath === childItem.linkUrl.split("?")[0]
    );

  const navigateToPage = (url: string | undefined) => {
    if (url && isExternalLink) {
      window.location.href = url;
    } else if (url && url !== currentPath) {
      navigate(url);
      if (isOverlayOpen) {
        setIsOverlayOpen(false);
      }
    }
  };

  useEffect(() => {
    if (containsCurrentPath) {
      setIsOpen(true);
    }
  }, [currentPath, containsCurrentPath]);

  const triggerFocusRing = () => {
    if (!focusRingActive) {
      setFocusRingActive(true);
    } else {
      // toggle active class off and then back on
      setFocusRingActive(false);
      setTimeout(() => {
        setFocusRingActive(true);
      }, 5);
    }
  };

  if (isMinimized) {
    if (childItems) {
      return (
        <ActionMenu
          buttonId={`nav-item-${label}`}
          menuId={`nav-menu-${label}`}
          buttonContent={
            <StyledTooltip>{icon ? icon : <>{label}</>}</StyledTooltip>
          }
          items={childItems.map((item) => ({
            content: item.label,
            onClick: () => navigateToPage(item.linkUrl),
          }))}
          containerClasses="navigation-item__action-menu"
          buttonClasses={classNames(
            "navigation-item__action-menu-button focus-ring focus-ring--secondary focus-ring--without-shadow",
            {
              "navigation-item__action-menu-button--contains-current-path":
                containsCurrentPath,
            }
          )}
          buttonTooltip={{ header: label, placement: "right", children: <></> }}
        />
      );
    } else {
      return (
        <StyledTooltip header={label} placement={"right"}>
          <button
            className={classNames(
              "navigation-item__button focus-ring focus-ring--secondary focus-ring--without-shadow",
              {
                "navigation-item__button--current-path": isCurrentPath,
              }
            )}
            onClick={() => navigateToPage(linkUrl)}
          >
            {icon}
            <span className="sr-only">{label}</span>
          </button>
        </StyledTooltip>
      );
    }
  } else {
    return (
      <>
        <Button
          variant={"contained"}
          className={classNames(
            "navigation-item",
            "focus-ring focus-ring--secondary focus-ring--without-shadow",
            { "focus-ring--active": focusRingActive },
            {
              "navigation-item--has-children":
                childItems && childItems.length > 0,
              "navigation-item--open":
                isOpen && childItems && childItems.length > 0,
              "navigation-item--minimized": isMinimized,
            },
            {
              "navigation-item--contains-current-path": containsCurrentPath,
              "navigation-item--current-path": isCurrentPath,
            },
            classes
          )}
          onClick={() => {
            triggerFocusRing();
            setIsOpen(!isOpen);
            navigateToPage(linkUrl);
            if (onClick) {
              onClick();
            }
          }}
          disableFocusRipple
          disableRipple
          disableElevation
        >
          <div className="navigation-item__content">
            <div className="navigation-item__icon">{icon}</div>
            <div className="navigation-item__label">{label}</div>
          </div>
          {childItems && childItems.length > 0 && (
            <ChevronIcon
              direction={isOpen ? SvgDirection.Up : SvgDirection.Down}
            />
          )}
        </Button>
        {childItems &&
          childItems.length > 0 &&
          !isMinimized &&
          isOpen &&
          childItems.map((itemChild, indexChild) => (
            <NavigationItem
              key={indexChild}
              currentPath={currentPath}
              classes="navigation-item--child"
              {...itemChild}
            />
          ))}
      </>
    );
  }
}
