import classNames from "classnames";

export interface TripProgressItemsCondensedProps {
  stops: number;
  classes?: string;
}

export default function TripProgressItemsCondensed(
  props: TripProgressItemsCondensedProps
) {
  const { stops, classes } = props;

  return (
    <div className={classNames("trip-progress-items-condensed", classes)}>
      <div className="trip-progress-items-condensed__number-indicator">
        <span className="trip-progress-items-condensed__number-indicator-text">
          {stops}
        </span>
      </div>
    </div>
  );
}
