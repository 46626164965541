import classNames from "classnames";
import { useState } from "react";
import FilterControlOption from "./FilterControlOption";
import StyledRadioGroup, {
  RadioGroupOption,
} from "../form/radio-button/StyledRadioGroup";
import { RadioButtonSize } from "../form/radio-button/StyledRadioButton";
import { FilterControlOptionGroupProps, FilterControlValue } from "./types";

export default function FilterControlOptionGroup(
  props: FilterControlOptionGroupProps
) {
  const {
    name,
    options,
    singleSelect,
    selectedValues,
    onChangeValues,
  }: FilterControlOptionGroupProps = props;

  const [originalValue] = useState<FilterControlValue[]>(selectedValues);
  if (singleSelect) {
    const changeSingleValue = (value: FilterControlValue) => {
      onChangeValues([value]);
    };

    const radioButtonOptions: RadioGroupOption[] = options.map((option) => ({
      label: option.label,
      value: option.value,
    }));

    return (
      <StyledRadioGroup
        defaultValue={
          originalValue.length > 0 ? originalValue[0].value : undefined
        }
        // value={selectedValues.length > 0 ? selectedValues[0] : undefined}
        groupId={`${name}-radioGroup`}
        groupName={`${name}-radioGroup`}
        options={radioButtonOptions}
        radioButtonSize={RadioButtonSize.Sm}
        onChange={(_, value) =>
          changeSingleValue({ label: name, value: value })
        }
        classes="filter-control-option-group__radio-group"
        rootClasses="filter-control-option-group__radio-group-root"
        itemsClasses="filter-control-option-group__radio-group-items"
      />
    );
  } else {
    const changeMultiValues = (value: FilterControlValue) => {
      let updatedValues = selectedValues;
      const indexOfChangedValue = updatedValues.findIndex(
        (item) => item.value === value.value
      );
      if (indexOfChangedValue === -1) {
        updatedValues.push(value);
      } else {
        updatedValues.splice(indexOfChangedValue, 1);
      }
      onChangeValues(updatedValues);
    };
    return (
      <ul className={classNames("filter-control-option-group")}>
        {options.map((option) => {
          return (
            <FilterControlOption
              {...option}
              key={option.value}
              checked={
                selectedValues &&
                selectedValues.find((item) => {
                  return item.value.includes(option.value);
                })
                  ? true
                  : false
              }
              onChange={(value) => changeMultiValues(value)}
            />
          );
        })}
      </ul>
    );
  }
}
