import classNames from "classnames";
import AlertCircleIcon from "../svg/AlertCircle";
import HelpCircleIcon from "../svg/HelpCircle";
import CheckmarkCircleIcon from "../svg/CheckmarkCircle";
import { SvgType } from "../svg/types";
import { allColors } from "../../../util/colors";
import StyledButton from "../button/StyledButton";

export enum SnackbarType {
  Error = "Error",
  Warning = "Warning",
  Success = "Success",
  Info = "Info",
}
export type SnackbarProps = {
  type: SnackbarType;
  body: string;
  onDismiss?: () => void;
  autoDismiss?: boolean;
  autoDismissDelay?: string;
  classes?: string;
  containerClasses?: string;
};

export default function Snackbar(props: SnackbarProps) {
  const {
    type,
    body,
    onDismiss,
    autoDismiss,
    autoDismissDelay,
    classes,
    containerClasses,
  }: SnackbarProps = props;

  const genericSnackbarIconProps = {
    type: SvgType.Solid,
    width: "20",
    height: "20",
    classes: "snackbar__icon",
  };

  return (
    <div className={classNames("snackbar__container", containerClasses)}>
      <div
        className={classNames(
          "snackbar",
          { "snackbar--auto-dismiss": autoDismiss },
          classes
        )}
        style={autoDismissDelay ? { animationDelay: autoDismissDelay } : {}}
      >
        <div className="snackbar__icon-container">
          {type === SnackbarType.Error ? (
            <AlertCircleIcon
              {...genericSnackbarIconProps}
              fill={allColors.error[400]}
            />
          ) : type === SnackbarType.Warning ? (
            <HelpCircleIcon
              {...genericSnackbarIconProps}
              fill={allColors.warning[400]}
            />
          ) : type === SnackbarType.Success ? (
            <CheckmarkCircleIcon
              {...genericSnackbarIconProps}
              fill={allColors.success[400]}
            />
          ) : (
            <HelpCircleIcon
              {...genericSnackbarIconProps}
              fill={allColors.primary[400]}
            />
          )}
        </div>
        <p className="snackbar__body">{body}</p>
        <StyledButton
          onClick={onDismiss}
          classes="snackbar__button"
          color="secondary"
          textOnly
        >
          Dismiss
        </StyledButton>
      </div>
    </div>
  );
}
