import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps } from "./types";

export default function MinusSmIcon(props: SvgProps) {
  const {
    width = "12",
    height = "12",
    stroke = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__minus-sm", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M2.5 6H9.5"
        stroke={stroke}
        strokeWidth="1.66666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
