import classNames from "classnames";
import { Tooltip, TooltipProps } from "@mui/material";

export type StyledTooltipProps = Omit<TooltipProps, "title"> & {
  header?: string;
  body?: string;
  containerClasses?: string;
};

export default function StyledTooltip(props: StyledTooltipProps) {
  const { header, body, containerClasses, ...otherProps }: StyledTooltipProps =
    props;

  const tooltipContent: React.ReactNode | undefined =
    header || body ? (
      <>
        {header && <p className="tooltip__header font-semibold">{header}</p>}
        {body && (
          <p
            className={classNames(
              "tooltip__body",
              "font-medium text-gray-300",
              {
                "mt-2": header,
              }
            )}
          >
            {body}
          </p>
        )}
      </>
    ) : undefined;
  return (
    <Tooltip
      {...otherProps}
      classes={{
        popper: classNames("tooltip__popper", otherProps.classes?.popper),
        tooltip: classNames(
          "tooltip",
          "text-xs bg-black px-6 py-4",
          otherProps.classes?.tooltip
        ),
        arrow: classNames("tooltip__arrow", "text-black"),
      }}
      title={tooltipContent}
      children={
        <div
          className={classNames("tooltip__container inline", containerClasses)}
        >
          {otherProps.children}
        </div>
      }
    />
  );
}
