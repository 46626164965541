import { AxiosResponse } from "axios";
import omniAxios from "./axios";
import { JobCategory, JobSeniority, Job, UserInfo } from "../types/common";

export interface LoginRequestBody {
  email: string;
  password: string;
}
export interface LoginResponseBody {
  access: string;
  refresh: string;
}
export const login = async (payload: LoginRequestBody) => {
  return await omniAxios
    .post<
      LoginResponseBody,
      AxiosResponse<LoginResponseBody, LoginRequestBody>,
      LoginRequestBody
    >("/token/", payload)
    .then((resp) => resp.data);
};

export interface RefreshKeyRequestBody {
  refresh: string;
}
export interface RefreshKeyResponseBody {
  access: string;
}
export const refreshLogin = async (refreshToken?: string) => {
  if (refreshToken) {
    const authData = await omniAxios
      .post<
        RefreshKeyResponseBody,
        AxiosResponse<RefreshKeyResponseBody, RefreshKeyRequestBody>,
        RefreshKeyRequestBody
      >("/token/refresh/", { refresh: refreshToken })
      .then((resp) => {
        // update local storage with new access token
        const currentUserInfo: string | null = localStorage.getItem("user");
        if (currentUserInfo) {
          const parsedUserInfo: UserInfo = JSON.parse(currentUserInfo);
          const newParsedUserInfo = {
            ...parsedUserInfo,
            access_key: resp.data.access,
          };
          localStorage.setItem("user", JSON.stringify(newParsedUserInfo));
          // TODO: Remove this once Ops Portal is integrated into a single app.
          localStorage.setItem("access_token", authData.access);
        }
        return resp.data;
      });
    return authData;
  } else return null;
};

export interface FetchUserInfoResponseBody extends UserInfo {}

// get user's name and avatar
export const fetchUserInfo = async () => {
  return await omniAxios
    .get<FetchUserInfoResponseBody>("/users/me/")
    .then((resp) => resp.data);
};

export interface UpdateUserInfoResponseBody extends UserInfo {}
// Omitted parameters are not permitted to be updated
export interface UpdateUserInfoRequestBody
  extends Omit<
    UserInfo,
    "email" | "customer_name" | "admin_user" | "allowed_customers"
  > {}

export const updateUserInfo = async (userInfo: UpdateUserInfoRequestBody) => {
  return await omniAxios
    .patch<
      UpdateUserInfoResponseBody,
      AxiosResponse<UpdateUserInfoResponseBody, UpdateUserInfoRequestBody>,
      UpdateUserInfoRequestBody
    >("/users/me/", userInfo)
    .then((resp) => resp.data);
};

export interface FetchUserJobsResponseBody {
  user_job_categories: JobCategory[];
  user_job_seniorities: JobSeniority[];
  user_jobs: Job[];
}
export const fetchUserJobs = async () => {
  return await omniAxios
    .get<FetchUserJobsResponseBody>("/user-jobs/")
    .then((resp) => resp.data);
};

export interface ResetPasswordRequestBody {
  email: string;
}
export interface ResetPasswordResponseBody {
  detail: string;
}
export const resetPassword = async (payload: ResetPasswordRequestBody) => {
  return await omniAxios.post<
    ResetPasswordResponseBody,
    AxiosResponse<ResetPasswordResponseBody, ResetPasswordRequestBody>,
    ResetPasswordRequestBody
  >("/account/password-reset/", payload);
};

export interface ResetPasswordConfirmRequestBody {
  password: string;
  signature: string;
}
export interface ResetPasswordConfirmResponseBody {
  detail?: string;
  // error messages, i.e. "Must choose a different password"
  password?: string[];
  signature?: string[];
}
export const resetPasswordConfirm = async (
  payload: ResetPasswordConfirmRequestBody
) => {
  return await omniAxios.post<
    ResetPasswordConfirmResponseBody,
    AxiosResponse<
      ResetPasswordConfirmResponseBody,
      ResetPasswordConfirmRequestBody
    >,
    ResetPasswordConfirmRequestBody
  >("/account/password-reset/confirm/", payload);
};
