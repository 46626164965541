import classNames from "classnames";
import { useEffect, useState } from "react";
import SideNavigation, {
  SideNavigationProps,
} from "../side-navigation/SideNavigation";
import Logo, { LogoType } from "../../common/logo/Logo";
import HamburgerIcon from "../../common/svg/Hamburger";
import { allColors } from "../../../util/colors";
import { useOverlayContext } from "../../../context/OverlayContext";
import { OVERLAY_DELAY } from "../../../util/constants";
import StyledButton from "../../common/button/StyledButton";
import { useSearchParams } from "react-router-dom";
import CustomerServiceIcon from "../../common/svg/CustomerService";
import { useXsBreakpoint } from "../../../util/breakpoints";

export type MobileNavigationProps = {
  sideNavigation?: SideNavigationProps;
  currentPath?: string;
  classes?: string;
};

export default function MobileNavigation(props: MobileNavigationProps) {
  const { sideNavigation, currentPath, classes }: MobileNavigationProps = props;

  const [filterParams, setFilterParams] = useSearchParams();
  const { isOverlayOpen, setIsOverlayOpen, setOverlayContent } =
    useOverlayContext();

  const isXs = useXsBreakpoint();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenDelayed, setIsOpenDelayed] = useState<boolean>(false);

  const openMobileMenu = () => {
    setIsOpen(true);
    setIsOverlayOpen(true);
    setIsOpenDelayed(true);
    setOverlayContent(
      <SideNavigation
        {...sideNavigation}
        isMobileNavigation
        currentPath={currentPath}
        onCloseMobileNav={() => setIsOverlayOpen(false)}
      />
    );
  };
  const closeMobileMenu = () => {
    setIsOverlayOpen(false);
  };

  useEffect(() => {
    if (!isOverlayOpen) {
      setIsOpen(false);
      setTimeout(() => {
        setIsOpenDelayed(false);
      }, OVERLAY_DELAY);
    }
  }, [isOverlayOpen]);

  return (
    <div
      className={classNames(
        "mobile-navigation",
        { "mobile-navigation--open": isOpenDelayed },
        classes
      )}
    >
      <header className="mobile-navigation__header">
        <div className="mobile-navigation__left-content">
          <button
            className="mobile-navigation__hamburger-button"
            onClick={() => (isOpen ? closeMobileMenu() : openMobileMenu())}
          >
            <span className="sr-only">Open navigation menu</span>
            <HamburgerIcon stroke={allColors.gray[700]} />
          </button>
          <button
            className={classNames("mobile-navigation__logo-button", {
              "mobile-navigation__logo-button--hidden": !isOpen,
            })}
          >
            <Logo type={LogoType.Full} classes="mobile-navigation__logo" />
            <span className="sr-only">Gatik omni home</span>
          </button>
        </div>
        <div
          className={classNames("mobile-navigation__right-content", {
            "mobile-navigation__right-content--hidden": isOpen,
          })}
        >
          <StyledButton
            color={"secondary"}
            outlined
            size={"sm"}
            iconOnly={!isXs}
            onClick={() => {
              filterParams.set("modal", "support");
              setFilterParams(filterParams);
            }}
            classes="mobile-navigation__support-button"
          >
            <CustomerServiceIcon width="16" height="16" classes="mr-2" />{" "}
            <span>Support</span>
          </StyledButton>
        </div>
      </header>
    </div>
  );
}
