import classNames from "classnames";
import {
  StopEventStatus,
  StopSequenceStatus,
  Trip,
  TripStatus,
} from "../../../types/trip";
import { allColors } from "../../../util/colors";
import { SvgType } from "../../common/svg/types";
import CalendarIcon from "../../common/svg/Calendar";
import ClockFastForwardIcon from "../../common/svg/ClockFastForward";
import CloseCircleIcon from "../../common/svg/CloseCircle";
import CheckmarkCircleIcon from "../../common/svg/CheckmarkCircle";
import TripProgressBar from "../trip-progress-bar/TripProgressBar";
import AlertCircleIcon from "../../common/svg/AlertCircle";

export type TripCardProps = {
  trip: Trip;
  onClick?: () => void;
  classes?: string;
};

export default function TripCard(props: TripCardProps) {
  const { trip, onClick, classes } = props;

  // Returns all stops that are skipped
  const skippedStops = trip.itinerary.stops.filter(
    (stop) => stop.sequence_status === StopSequenceStatus.Skipped
  );
  // Returns the index of the first skipped stop
  const firstSkippedStop = trip.itinerary.stops.findIndex(
    (stop) => stop.sequence_status === StopSequenceStatus.Skipped
  );

  const statusHeaderContent =
    trip.status === TripStatus.InProgress ? (
      <>
        <ClockFastForwardIcon
          stroke={allColors.white}
          width="14"
          height="14"
          classes="mr-3"
        />{" "}
        In Progress
      </>
    ) : trip.status === TripStatus.Cancelled ? (
      <>
        <CloseCircleIcon
          type={SvgType.Solid}
          fill={allColors.white}
          width="14"
          height="14"
          classes="mr-3"
        />{" "}
        Cancelled
      </>
    ) : trip.status === TripStatus.Scheduled ? (
      <>
        <CalendarIcon
          type={SvgType.Solid}
          fill={allColors.gray[900]}
          width="14"
          height="14"
          classes="mr-3"
        />{" "}
        Scheduled
      </>
    ) : trip.status === TripStatus.Completed ? (
      <>
        <CheckmarkCircleIcon
          type={SvgType.Solid}
          fill={allColors.white}
          width="14"
          height="14"
          classes="mr-3"
        />{" "}
        Completed
      </>
    ) : trip.status === TripStatus.TrackingTimeout ? (
      <>
        <AlertCircleIcon
          stroke={allColors.gray[900]}
          width="14"
          height="14"
          classes="mr-3"
        />{" "}
        Tracking Timed Out
      </>
    ) : null;

  // This is the stop that is currently in progress
  const firstStopNotDeparted = trip.itinerary.stops.find(
    (stop) =>
      stop.event_status !== StopEventStatus.AVDeparted &&
      stop.sequence_status !== StopSequenceStatus.Skipped
  );
  // This is the index of the stop that is currently in progress
  const firstStopNotDepartedIndex = trip.itinerary.stops.findIndex(
    (stop) =>
      stop.event_status !== StopEventStatus.AVDeparted &&
      stop.sequence_status !== StopSequenceStatus.Skipped
  );

  return (
    <button
      className={classNames(
        "trip-card",
        {
          "trip-card--in-progress": trip.status === TripStatus.InProgress,
          "trip-card--cancelled": trip.status === TripStatus.Cancelled,
          "trip-card--completed": trip.status === TripStatus.Completed,
          "trip-card--scheduled": trip.status === TripStatus.Scheduled,
          "trip-card--timed-out": trip.status === TripStatus.TrackingTimeout,
        },
        classes
      )}
      onClick={() => onClick && onClick()}
    >
      <div className="trip-card__status-header">
        {statusHeaderContent}
        {skippedStops.length > 1 ? (
          <i className="ml-2">(Multiple stops skipped)</i>
        ) : skippedStops.length > 0 ? (
          <i className="ml-2">(Stop {firstSkippedStop} skipped)</i>
        ) : null}
      </div>
      <div className="trip-card__content">
        <h2 className="trip-card__trip-name text-lg font-semibold">
          Trip {trip.itinerary.name}
        </h2>
        <div className="trip-card__trip-info text-sm flex flex-row items-stretch">
          <div className="text-left">
            AV# <b>{trip.av.av_id}</b>
          </div>
          <div className="border-l pl-3 ml-3 text-left">
            Lane ID{" "}
            <b>
              {trip.template?.external_id ? trip.template.external_id : "--"}
            </b>
          </div>
        </div>
        <div className="trip-card__inner-container mt-6">
          <TripProgressBar
            tripStatus={trip.status}
            stops={trip.itinerary.stops}
          />
          <div className="flex flex-col text-sm mt-4">
            {/* If the origin is departed, show previous stop info */}
            {trip.itinerary.stops[0].event_status &&
              trip.itinerary.stops[0].event_status ===
                StopEventStatus.AVDeparted && (
                <div
                  className={classNames("flex flex-row pt-4", {
                    "py-4 border-b border-gray-200":
                      trip.status !== TripStatus.Completed,
                  })}
                >
                  <span className="trip-card__table-left">
                    {firstStopNotDeparted?.event_status ===
                    StopEventStatus.AVArrived
                      ? "Arrived at"
                      : "Departed"}
                  </span>
                  <span>
                    {firstStopNotDeparted?.event_status ===
                    StopEventStatus.AVArrived ? (
                      <b>
                        {
                          trip.itinerary.stops[firstStopNotDepartedIndex].site
                            .properties.name
                        }
                      </b>
                    ) : (
                      trip.itinerary.stops[firstStopNotDepartedIndex - 1].site
                        .properties.name
                    )}
                  </span>
                </div>
              )}
            {/* If the last stop in the trip hasn't arrived yet, show "Next location" info */}
            {trip.itinerary.stops[trip.itinerary.stops.length - 1]
              .event_status &&
              trip.itinerary.stops[trip.itinerary.stops.length - 1]
                .event_status === StopEventStatus.AwaitingAV && (
                <div className="flex flex-row pt-4">
                  <span className="trip-card__table-left">Next location</span>
                  <span>
                    {firstStopNotDeparted?.event_status ===
                    StopEventStatus.AVArrived ? (
                      trip.itinerary.stops[firstStopNotDepartedIndex + 1].site
                        .properties.name
                    ) : (
                      <b>
                        {
                          trip.itinerary.stops[firstStopNotDepartedIndex].site
                            .properties.name
                        }
                      </b>
                    )}
                  </span>
                </div>
              )}
          </div>
        </div>
      </div>
    </button>
  );
}
