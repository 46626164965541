import { createTheme } from "@mui/material";
import { allColors } from "./colors";

declare module "@mui/material/styles" {
  interface Palette {
    secondaryAction?: PaletteOptions["primary"];
    errorAction?: PaletteOptions["primary"];
  }
  interface PaletteOptions {
    secondaryAction?: PaletteOptions["primary"];
    errorAction?: PaletteOptions["primary"];
  }
}

declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    secondaryAction?: true;
    errorAction?: true;
  }
}

const theme = createTheme({
  components: {
    MuiButton: {}
  },
  palette: {
    primary: { main: allColors.primary[600] },
    background: { default: allColors.white },
    error: { main: allColors.error[600] },
  },
  typography: {
    fontFamily: "unset",
    button: {
      textTransform: "none",
    },
  },
});

export default theme;
