import { SvgProps } from "../../../components/common/svg/types";

export default function SignUpMailIcon(props: SvgProps) {
  const { width = "266", height = "178" } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 266 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M102.898 55.746C126.647 29.4527 142.762 28.6042 160.574 29.4527C178.385 30.3013 200.021 30.5549 200.021 30.5549C203.807 34.5945 216.227 54.4594 200.013 71.4374H179.657C179.657 71.4374 169.903 84.1602 157.605 73.5574C145.307 62.9546 102.898 55.746 102.898 55.746Z"
        fill="#C7D7FE"
      />
      <path
        d="M174.313 71.4329C177.313 68.6922 178.693 64.6951 181.645 61.9003C183.967 59.7014 187.062 58.233 188.959 55.597C191.338 52.2924 189.441 47.8883 185.234 48.1773C178.218 48.6588 170.659 54.6722 164.974 58.2144C159.628 61.5456 154.371 65.0134 149.111 68.4767C152.571 70.0603 155.522 71.7592 157.606 73.5556C158.917 74.6861 160.198 75.5382 161.443 76.1819C166.032 75.6756 170.815 74.6284 174.313 71.4329Z"
        fill="#1F235B"
      />
      <path
        d="M162.078 82.7479L139.547 64.2574L122.866 50.5725L106.186 64.2574L84.9151 81.7043L74.8621 89.9521H170.863L162.078 82.7479Z"
        fill="#1F235B"
      />
      <path
        d="M170.863 89.9165H74.8621V152.989H170.863V89.9165Z"
        fill="#3538CD"
      />
      <path
        d="M170.214 44.2944L162.078 98.7102L139.547 121.19L122.866 137.827L106.186 121.19L84.9152 99.979L93.1364 44.2944H170.214Z"
        fill="white"
      />
      <path
        d="M84.9151 100.411C84.8947 100.411 84.8726 100.41 84.8513 100.406C84.6154 100.372 84.4523 100.152 84.4878 99.916L92.7089 44.2315C92.74 44.0196 92.9217 43.8635 93.1354 43.8635H170.212C170.338 43.8635 170.456 43.9185 170.539 44.0134C170.62 44.1082 170.658 44.2342 170.639 44.3583L162.505 98.7749C162.469 99.0108 162.252 99.1739 162.014 99.1384C161.779 99.103 161.616 98.8831 161.651 98.6481L169.712 44.7271H93.5078L85.3407 100.044C85.3106 100.257 85.1262 100.411 84.9151 100.411Z"
        fill="#1F235B"
      />
      <path
        d="M122.866 138.258C122.756 138.258 122.646 138.215 122.561 138.132L74.5571 90.2569C74.3877 90.0885 74.3877 89.8154 74.5562 89.6469C74.7246 89.4775 74.9977 89.4784 75.1662 89.646L122.866 137.218L170.558 89.646C170.727 89.4775 170.999 89.4775 171.168 89.6469C171.336 89.8154 171.336 90.0885 171.167 90.2569L123.17 138.132C123.087 138.216 122.977 138.258 122.866 138.258Z"
        fill="white"
      />
      <path
        d="M139.851 120.885C139.683 120.717 139.411 120.717 139.242 120.885L122.866 137.219L106.491 120.885C106.323 120.717 106.051 120.717 105.882 120.885L74.621 152.51C74.5279 152.602 74.4862 152.726 74.4951 152.847C74.503 152.951 74.5474 153.051 74.629 153.128C74.6928 153.189 74.7699 153.226 74.8497 153.24C74.9836 153.264 75.1264 153.224 75.2301 153.12L106.186 121.8L122.562 138.133C122.731 138.301 123.003 138.301 123.171 138.133L139.547 121.8L170.383 153.057C170.484 153.159 170.621 153.199 170.752 153.183C170.848 153.171 170.942 153.127 171.015 153.05C171.073 152.989 171.11 152.917 171.127 152.84C171.158 152.697 171.118 152.542 171.007 152.432L139.851 120.885Z"
        fill="white"
      />
      <path
        d="M144.202 97.7598H134.155C133.943 97.7598 133.763 97.6056 133.73 97.3954C133.716 97.3209 133.352 95.4696 130.229 94.95C125.344 94.1369 120.798 97.6322 120.752 97.6685C120.676 97.7279 120.582 97.7598 120.486 97.7598H97.3542C97.1157 97.7598 96.9224 97.5665 96.9224 97.328C96.9224 97.0895 97.1157 96.8962 97.3542 96.8962H120.341C121.133 96.3101 125.505 93.2884 130.37 94.0988C133.307 94.5873 134.214 96.1727 134.479 96.8962H144.201C144.44 96.8962 144.633 97.0895 144.633 97.328C144.633 97.5665 144.441 97.7598 144.202 97.7598Z"
        fill="#1F235B"
      />
      <path
        d="M143.152 107.807H104.249C104.01 107.807 103.817 107.613 103.817 107.375C103.817 107.136 104.01 106.943 104.249 106.943H143.152C143.39 106.943 143.583 107.136 143.583 107.375C143.583 107.613 143.39 107.807 143.152 107.807Z"
        fill="#1F235B"
      />
      <path
        d="M154.95 87.2462H137.427C137.189 87.2462 136.995 87.0529 136.995 86.8144C136.995 86.5759 137.189 86.3826 137.427 86.3826H154.95C155.189 86.3826 155.382 86.5759 155.382 86.8144C155.382 87.0529 155.189 87.2462 154.95 87.2462Z"
        fill="#1F235B"
      />
      <path
        d="M108.258 56.7425H103.805C103.566 56.7425 103.373 56.5492 103.373 56.3107C103.373 56.0722 103.566 55.8789 103.805 55.8789H108.258C108.496 55.8789 108.689 56.0722 108.689 56.3107C108.688 56.5501 108.496 56.7425 108.258 56.7425Z"
        fill="#1F235B"
      />
      <path
        d="M133.431 88.4545C132.255 88.4545 131.299 87.4978 131.299 86.3212C131.299 85.1454 132.255 84.1887 133.431 84.1887C134.608 84.1887 135.564 85.1454 135.564 86.3212C135.564 87.4978 134.608 88.4545 133.431 88.4545ZM133.431 85.0514C132.731 85.0514 132.161 85.6207 132.161 86.3212C132.161 87.0216 132.731 87.5918 133.431 87.5918C134.131 87.5918 134.702 87.0216 134.702 86.3212C134.702 85.6207 134.131 85.0514 133.431 85.0514Z"
        fill="#1F235B"
      />
      <path
        d="M99.7101 125.611H82.8492V145.256H99.7101V125.611Z"
        fill="#C7D7FE"
      />
      <path
        d="M86.2646 133.392C86.1298 133.392 85.9977 133.329 85.9135 133.211L84.3343 131.001C84.196 130.807 84.2412 130.537 84.4345 130.399C84.6278 130.26 84.8983 130.306 85.0366 130.499L86.6157 132.71C86.7541 132.904 86.7088 133.173 86.5155 133.312C86.4393 133.367 86.3515 133.392 86.2646 133.392Z"
        fill="white"
      />
      <path
        d="M63.8381 112.547C63.713 112.547 63.5889 112.492 63.5038 112.388L60.6611 108.913C60.5104 108.729 60.5379 108.456 60.7223 108.306C60.9067 108.155 61.1781 108.181 61.3297 108.367L64.1723 111.842C64.3231 112.026 64.2956 112.298 64.1112 112.449C64.0305 112.515 63.9338 112.547 63.8381 112.547Z"
        fill="#C7D7FE"
      />
      <path
        d="M178.183 132.131C178.072 132.131 177.962 132.089 177.878 132.005C177.709 131.836 177.709 131.563 177.878 131.395L181.668 127.604C181.837 127.436 182.11 127.436 182.278 127.604C182.447 127.773 182.447 128.046 182.278 128.214L178.488 132.005C178.404 132.089 178.293 132.131 178.183 132.131Z"
        fill="#C7D7FE"
      />
      <path
        d="M183.552 132.447C183.442 132.447 183.332 132.404 183.247 132.32C183.079 132.152 183.079 131.879 183.247 131.71L187.038 127.92C187.206 127.751 187.48 127.751 187.648 127.92C187.816 128.088 187.816 128.361 187.648 128.53L183.857 132.32C183.773 132.405 183.662 132.447 183.552 132.447Z"
        fill="#C7D7FE"
      />
      <path
        d="M135.266 35.4763C124.381 44.218 114.428 56.9337 109.069 63.5464C98.8154 76.1983 99.6861 79.5623 103.072 81.6052C112.229 87.13 124.784 73.8398 133.434 64.2292C133.434 64.2292 158.331 71.9946 183.318 50.852C208.306 29.7094 135.266 35.4763 135.266 35.4763Z"
        fill="#C7D7FE"
      />
      <path
        d="M105.558 80.595C105.432 80.595 105.308 80.5409 105.222 80.4354C105.073 80.25 105.1 79.9787 105.285 79.828C107.491 78.036 109.805 75.1118 109.686 72.2798C109.617 70.6324 108.721 69.211 107.026 68.0539C106.829 67.9191 106.779 67.6514 106.912 67.4545C107.046 67.2568 107.315 67.208 107.512 67.341C109.446 68.6595 110.467 70.3087 110.548 72.2434C110.68 75.3955 108.197 78.5742 105.83 80.4983C105.749 80.563 105.653 80.595 105.558 80.595Z"
        fill="#1F235B"
      />
      <path
        d="M123.155 57.3731C123.05 57.3731 122.944 57.335 122.862 57.2578L119.44 54.08C119.266 53.9186 119.256 53.6456 119.418 53.47C119.579 53.2953 119.853 53.2856 120.028 53.4478L123.45 56.6256C123.624 56.787 123.634 57.0601 123.472 57.2357C123.387 57.327 123.272 57.3731 123.155 57.3731Z"
        fill="white"
      />
      <path
        d="M125.745 44.1758C125.618 44.1758 125.493 44.12 125.407 44.0144C125.258 43.8282 125.289 43.5569 125.475 43.408L131.585 38.5198C131.771 38.3699 132.042 38.4027 132.191 38.5871C132.34 38.7733 132.31 39.0447 132.124 39.1936L126.014 44.0818C125.935 44.1448 125.839 44.1758 125.745 44.1758Z"
        fill="#1F235B"
      />
    </svg>
  );
}
