import { SxProps, Tab, TabProps, Tabs, TabsProps, Theme } from "@mui/material";
import classNames from "classnames";

export enum TabSize {
  Sm = "sm",
  Md = "md",
}
export enum TabType {
  Underline = "underline",
  Folder = "folder",
}
export interface StyledTabsProps extends TabsProps {
  defaultTab?: Number;
  tabSize?: TabSize;
  tabType?: TabType;
  labels: string[];
  tabProps?: TabProps;
  fullWidth?: boolean;
  customTabStyles?: SxProps<Theme>;
  extraClasses?: string;
}

export default function StyledTabs(props: StyledTabsProps) {
  const {
    tabType = TabType.Underline,
    tabSize = TabSize.Sm,
    labels,
    tabProps,
    extraClasses,
    ...otherProps
  } = props;

  return (
    <Tabs
      {...otherProps}
      classes={{
        root: classNames(
          "tabs",
          `tabs--${tabSize}`,
          `tabs--${tabType}`,
          extraClasses
        ),
        flexContainer: "tabs__flex-container",
        indicator: "tabs__indicator",
      }}
    >
      {labels.map((label) => (
        <Tab
          {...tabProps}
          key={label}
          label={label}
          disableRipple
          disableFocusRipple
          classes={{
            root: classNames("tabs__tab", `tabs__tab--${tabSize}`),
            selected: "tabs__tab--selected",
          }}
        />
      ))}
    </Tabs>
  );
}
