import classNames from "classnames";
import StyledButton from "../../../components/common/button/StyledButton";
import Logo, {
  LogoColor,
  LogoSize,
  LogoType,
} from "../../../components/common/logo/Logo";
import SignedOutTemplate from "../../../components/templates/SignedOutTemplate";
import { useSmBreakpoint } from "../../../util/breakpoints";
import { useNavigate } from "react-router-dom";

export type LandingPageProps = {
  classes?: string;
};

export default function LandingPage(props: LandingPageProps) {
  const { classes }: LandingPageProps = props;

  const isSm = useSmBreakpoint();
  const navigate = useNavigate();

  return (
    <SignedOutTemplate isLanding>
      <div className={classNames("landing-page", classes)}>
        <div className="landing-page__logo mx-auto">
          <Logo
            type={LogoType.Full}
            includeOmni
            size={LogoSize.Md}
            logoColor={LogoColor.Light}
            textColor={LogoColor.Light}
          />
        </div>
        <div className="landing-page__buttons">
          <StyledButton
            size={isSm ? "md" : "2xl"}
            color="primary"
            outlined
            classes="mb-6 w-full"
            onClick={() => navigate("/sign-up")}
          >
            Create an account
          </StyledButton>
          <StyledButton
            size={isSm ? "md" : "2xl"}
            color="primary"
            classes="w-full"
            onClick={() => navigate("/login")}
          >
            Log in
          </StyledButton>
        </div>
      </div>
    </SignedOutTemplate>
  );
}
