import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps, SvgType } from "./types";

export default function HexagonIcon(props: SvgProps) {
  const {
    type = SvgType.Line,
    width = "24",
    height = "24",
    stroke = allColors.black,
    fill = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__hexagon", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      {type === SvgType.Solid ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5971 1.18505C11.8629 1.13038 12.1371 1.13038 12.4029 1.18505C12.7102 1.24824 12.9848 1.40207 13.2032 1.52436C13.2235 1.53575 13.2433 1.54687 13.2627 1.55761L20.6627 5.66872C20.6831 5.68009 20.7042 5.6917 20.7258 5.70359C20.9569 5.8309 21.2476 5.99097 21.4707 6.23315C21.6637 6.44253 21.8097 6.69069 21.899 6.96105C22.0023 7.27375 22.0011 7.60553 22.0002 7.8694C22.0001 7.89406 22 7.91813 22 7.94153V16.0586C22 16.082 22.0001 16.1061 22.0002 16.1308C22.0011 16.3946 22.0023 16.7264 21.899 17.0391C21.8097 17.3095 21.6637 17.5576 21.4707 17.767C21.2476 18.0092 20.9569 18.1693 20.7258 18.2966C20.7042 18.3085 20.6831 18.3201 20.6627 18.3314L13.2627 22.4426C13.2433 22.4533 13.2235 22.4644 13.2032 22.4758C12.9848 22.5981 12.7102 22.7519 12.4029 22.8151C12.1371 22.8698 11.8629 22.8698 11.5971 22.8151C11.2898 22.7519 11.0152 22.5981 10.7968 22.4758C10.7765 22.4644 10.7567 22.4533 10.7373 22.4426L3.33733 18.3314C3.31687 18.3201 3.2958 18.3085 3.2742 18.2966C3.04306 18.1693 2.75245 18.0092 2.52927 17.767C2.33632 17.5576 2.1903 17.3095 2.10097 17.0391C1.99765 16.7264 1.99886 16.3946 1.99982 16.1308C1.99991 16.1061 2 16.082 2 16.0586V7.94153C2 7.91813 1.99991 7.89406 1.99982 7.8694C1.99886 7.60553 1.99765 7.27375 2.10097 6.96105C2.1903 6.69069 2.33632 6.44253 2.52927 6.23315C2.75244 5.99097 3.04306 5.8309 3.27419 5.70359C3.29579 5.6917 3.31687 5.68009 3.33733 5.66872L10.7373 1.55761C10.7567 1.54687 10.7765 1.53575 10.7968 1.52436C11.0152 1.40207 11.2898 1.24824 11.5971 1.18505Z"
          fill={fill}
        />
      ) : (
        <path
          d="M11.223 2.43167C11.5066 2.27412 11.6484 2.19534 11.7985 2.16445C11.9315 2.13712 12.0685 2.13712 12.2015 2.16445C12.3516 2.19534 12.4934 2.27412 12.777 2.43168L20.177 6.54279C20.4766 6.70919 20.6263 6.79239 20.7354 6.91073C20.8318 7.01542 20.9049 7.1395 20.9495 7.27467C21 7.42747 21 7.5988 21 7.94144V16.0585C21 16.4012 21 16.5725 20.9495 16.7253C20.9049 16.8605 20.8318 16.9846 20.7354 17.0893C20.6263 17.2076 20.4766 17.2908 20.177 17.4572L12.777 21.5683C12.4934 21.7259 12.3516 21.8046 12.2015 21.8355C12.0685 21.8629 11.9315 21.8629 11.7985 21.8355C11.6484 21.8046 11.5066 21.7259 11.223 21.5683L3.82297 17.4572C3.52345 17.2908 3.37369 17.2076 3.26463 17.0893C3.16816 16.9846 3.09515 16.8605 3.05048 16.7253C3 16.5725 3 16.4012 3 16.0585V7.94144C3 7.59879 3 7.42747 3.05048 7.27467C3.09515 7.1395 3.16816 7.01542 3.26463 6.91073C3.37369 6.79239 3.52345 6.70919 3.82297 6.54279L11.223 2.43167Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}
