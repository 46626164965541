import classNames from "classnames";
import { allColors } from "../../../util/colors";
import { SvgProps, SvgType } from "./types";

export default function ThreeLayersIcon(props: SvgProps) {
  const {
    type = SvgType.Line,
    width = "24",
    height = "24",
    fill = allColors.black,
    stroke = allColors.black,
    classes,
  } = props;

  return (
    <svg
      className={classNames("icon icon__three-layers", classes)}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      {type === SvgType.Solid ? (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.7234 1.08474C11.9062 1.05045 12.0938 1.05045 12.2766 1.08474C12.493 1.12535 12.6861 1.22388 12.7794 1.27149C12.789 1.2764 12.7976 1.28076 12.805 1.28446L22.4472 6.10558C22.786 6.27497 23 6.62123 23 7C23 7.37878 22.786 7.72504 22.4472 7.89443L12.805 12.7155C12.7976 12.7193 12.789 12.7236 12.7794 12.7285C12.6861 12.7761 12.493 12.8747 12.2766 12.9153C12.0938 12.9496 11.9062 12.9496 11.7234 12.9153C11.507 12.8747 11.3139 12.7761 11.2206 12.7285C11.211 12.7236 11.2024 12.7192 11.195 12.7155L1.55279 7.89443C1.214 7.72504 1 7.37878 1 7C1 6.62123 1.214 6.27497 1.55279 6.10558L11.195 1.28446C11.2024 1.28076 11.211 1.27639 11.2206 1.27149C11.3139 1.22388 11.507 1.12535 11.7234 1.08474Z"
            fill={fill}
          />
          <path
            d="M2.52769 10.618L1.55279 11.1055C1.214 11.2749 1 11.6211 1 11.9999C1 12.3787 1.214 12.7249 1.55279 12.8943L11.195 17.7154L11.2206 17.7284C11.3139 17.776 11.507 17.8746 11.7234 17.9152C11.9062 17.9495 12.0938 17.9495 12.2766 17.9152C12.493 17.8746 12.6861 17.776 12.7794 17.7284L12.805 17.7154L22.4472 12.8943C22.786 12.7249 23 12.3787 23 11.9999C23 11.6211 22.786 11.2749 22.4472 11.1055L21.4723 10.618L13.6994 14.5045L13.6876 14.5104C13.6388 14.5349 13.5187 14.5952 13.3985 14.6475C13.2382 14.7172 12.9785 14.8185 12.6454 14.881C12.2189 14.9611 11.7811 14.9611 11.3546 14.881C11.0215 14.8185 10.7618 14.7172 10.6015 14.6475C10.4813 14.5952 10.3612 14.5349 10.3124 14.5104L10.3006 14.5045L2.52769 10.618Z"
            fill={fill}
          />
          <path
            d="M2.52784 15.6179L1.55279 16.1055C1.214 16.2749 1 16.6211 1 16.9999C1 17.3787 1.214 17.7249 1.55279 17.8943L11.195 22.7154L11.2206 22.7284C11.3139 22.776 11.507 22.8746 11.7234 22.9152C11.9062 22.9495 12.0938 22.9495 12.2766 22.9152C12.493 22.8746 12.6861 22.776 12.7794 22.7284L12.805 22.7154L22.4472 17.8943C22.786 17.7249 23 17.3787 23 16.9999C23 16.6211 22.786 16.2749 22.4472 16.1055L21.4722 15.6179L13.6994 19.5043L13.6876 19.5102C13.6388 19.5348 13.5187 19.595 13.3985 19.6473C13.2382 19.7171 12.9785 19.8184 12.6454 19.8809C12.2189 19.9609 11.7811 19.9609 11.3546 19.8809C11.0215 19.8184 10.7618 19.7171 10.6015 19.6473C10.4813 19.595 10.3612 19.5348 10.3124 19.5102L10.3006 19.5043L2.52784 15.6179Z"
            fill={fill}
          />
        </>
      ) : (
        <path
          d="M7 9.5L2 12L11.6422 16.8211C11.7734 16.8867 11.839 16.9195 11.9078 16.9324C11.9687 16.9438 12.0313 16.9438 12.0922 16.9324C12.161 16.9195 12.2266 16.8867 12.3578 16.8211L22 12L17 9.5M7 14.5L2 17L11.6422 21.8211C11.7734 21.8867 11.839 21.9195 11.9078 21.9324C11.9687 21.9438 12.0313 21.9438 12.0922 21.9324C12.161 21.9195 12.2266 21.8867 12.3578 21.8211L22 17L17 14.5M2 7L11.6422 2.17889C11.7734 2.1133 11.839 2.0805 11.9078 2.0676C11.9687 2.05616 12.0313 2.05616 12.0922 2.0676C12.161 2.0805 12.2266 2.1133 12.3578 2.17889L22 7L12.3578 11.8211C12.2266 11.8867 12.161 11.9195 12.0922 11.9324C12.0313 11.9438 11.9687 11.9438 11.9078 11.9324C11.839 11.9195 11.7734 11.8867 11.6422 11.8211L2 7Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
}
