import { AxiosResponse, GenericAbortSignal } from "axios";
import omniAxios from "./axios";
import { Trip } from "../types/trip";

export enum TripsSortMethod {
  TripNameAsc = "name",
  TripNameDesc = "-name",
  LaneIdAsc = "external_id",
  LaneIdDesc = "-external_id",

  // The following sort methods do not currently work
  AvIdAsc = "long_av_id",
  AvIdDesc = "-long_av_id",
  TripProgressAsc = "trip_progress",
  TripProgressDesc = "-trip_progress",

  // The following sort method are not used in the customer portal
  StatusAsc = "status",
  StatusDesc = "-status",
  StartTimeAsc = "start_time",
  StartTimeDesc = "-start_time",
  CustomerNameAsc = "customer_name",
  CustomerNameDesc = "-customer_name",
}

export interface TripsRequestBody {
  text?: string;
  customer_ids?: string[];
  av_ids?: string[];
  external_ids?: string[];
  page?: number;
  statuses?: string[];
  start_date?: string;
  end_date?: string;
  ordering?: string | TripsSortMethod;
  size?: number;
}

export interface TripsResponseBody {
  count: number;
  results: Trip[];
}

export const fetchTripsV2 = async (
  payload: TripsRequestBody,
  signal?: GenericAbortSignal
) => {
  return await omniAxios
    .get<
      TripsResponseBody,
      AxiosResponse<TripsResponseBody, TripsRequestBody>,
      TripsRequestBody
    >(`/v2/trips/`, {
      params: {
        ...payload,
      },
      signal: signal,
      paramsSerializer: { indexes: null },
    })
    .then((resp) => resp.data);
};

export interface SingleTripsRequestBody {
  id: string;
}
export const fetchTripById = async (payload: SingleTripsRequestBody) => {
  return await omniAxios
    .get<
      Trip,
      AxiosResponse<Trip, SingleTripsRequestBody>,
      SingleTripsRequestBody
    >(`/v2/trips/${payload.id}`)
    .then((resp) => resp.data);
};
