import classNames from "classnames";
import { useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import StyledTooltip, { StyledTooltipProps } from "../tooltip/StyledTooltip";
import { ConditionalWrapper } from "../../../util/util";

export type ActionMenuProps = {
  buttonId: string;
  menuId: string;
  buttonContent: React.ReactElement;
  buttonClasses?: string;
  menuClasses?: string;
  containerClasses?: string;
  hideBoxShadow?: boolean;
  items: ActionMenuItem[];
  buttonTooltip?: StyledTooltipProps;
};

export type ActionMenuItem = {
  content?: string | React.ReactElement;
  startIcon?: string | React.ReactElement;
  endIcon?: string | React.ReactElement;
  onClick?: () => void;
  isDisabled?: boolean;
  tooltip?: StyledTooltipProps;
  bottomDivider?: boolean;
};

export default function ActionMenu(props: ActionMenuProps) {
  const {
    buttonId,
    menuId,
    buttonContent,
    buttonClasses,
    menuClasses,
    containerClasses,
    hideBoxShadow,
    items,
    buttonTooltip,
  }: ActionMenuProps = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={classNames(
        "action-menu",
        {
          "action-menu--open": open,
          "action-menu--hide-box-shadow": hideBoxShadow,
        },
        containerClasses
      )}
    >
      <ConditionalWrapper
        condition={buttonTooltip !== undefined}
        wrapper={(children: any) => {
          return <StyledTooltip {...buttonTooltip}>{children}</StyledTooltip>;
        }}
      >
        <button
          id={buttonId}
          aria-haspopup="true"
          aria-controls={open ? menuId : undefined}
          onClick={handleClick}
          className={classNames("action-menu__button", buttonClasses)}
        >
          <span className="sr-only">Open action menu</span>
          {buttonContent}
        </button>
      </ConditionalWrapper>
      <Menu
        id={menuId}
        aria-labelledby={buttonId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          root: classNames("action-menu__menu", menuClasses),
          paper: "action-menu__menu-paper",
          list: "action-menu__menu-list",
        }}
      >
        {items.map((item, index) => {
          const onClickItem = () => {
            item.onClick && item.onClick();
            handleClose();
          };
          return (
            <MenuItem
              key={index}
              classes={{
                root: "action-menu__item",
                disabled: "action-menu__item--disabled",
                divider: "action-menu__divider",
                focusVisible: "action-menu__item--focusVisible",
              }}
              disableRipple
              divider={item.bottomDivider}
              onClick={onClickItem}
              disabled={item.isDisabled}
            >
              <div className="action-menu__left-content">
                {item.startIcon && (
                  <div className="action-menu__item-start-content mr-4">
                    {item.startIcon}
                  </div>
                )}
                {item.content}
              </div>
              {item.endIcon && (
                <div className="action-menu__item-end-content float-right">
                  {item.endIcon}
                </div>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
