import {
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from "@mui/material";
import classNames from "classnames";

export interface StyledToggleButtonGroupProps extends ToggleButtonGroupProps {
  buttons: StyledToggleButton[];
  groupLabel: string;
  groupClasses?: string;
}
export interface StyledToggleButton {
  value: string;
  label: string;
  disabled?: boolean;
  content: string | React.ReactElement;
}

export default function StyledToggleButtonGroup(
  props: StyledToggleButtonGroupProps
) {
  const { buttons, groupLabel, groupClasses, ...otherProps } = props;

  return (
    <ToggleButtonGroup
      {...otherProps}
      aria-label={groupLabel}
      classes={{
        root: classNames("toggle-button-group", groupClasses),
        disabled: "toggle-button-group--disabled",
      }}
    >
      {buttons.map((button, index) => (
        <ToggleButton
          key={index}
          value={button.value}
          aria-label={button.label}
          disabled={button.disabled}
          disableRipple
          classes={{
            root: classNames("toggle-button"),
            disabled: "toggle-button--disabled",
            selected: "toggle-button--selected",
          }}
        >
          {button.content}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
