import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import { useOverlayContext } from "../../context/OverlayContext";
import { UserInfo } from "../../types/common";
import { useLocalStorage } from "../../util/local-storage";
import PageTemplate from "./PageTemplate";

export interface ProtectedRouteProps {}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const [localStorageValue] = useLocalStorage();
  const { setIsOverlayOpen, setOverlayContent } = useOverlayContext();
  const navigate = useNavigate();

  useEffect(() => {
    const parsedUserInfo: UserInfo | null =
      typeof localStorageValue === "string"
        ? JSON.parse(localStorageValue)
        : null;

    // if there's no auth keys, redirect to login.
    if (!parsedUserInfo?.access_key || !parsedUserInfo?.refresh_key) {
      setIsOverlayOpen(false);
      setOverlayContent(null);
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorageValue]);

  return (
    <PageTemplate>
      <Outlet />
    </PageTemplate>
  );
};
