import classNames from "classnames";
import NavigationItem, { NavigationItemProps } from "./NavigationItem";

export type NavigationMenuProps = {
  items?: NavigationItemProps[];
  isMinimized?: boolean;
  currentPath?: string;
  classes?: string;
};

export default function NavigationMenu(props: NavigationMenuProps) {
  const { items, isMinimized, currentPath, classes }: NavigationMenuProps =
    props;

  return (
    <div
      className={classNames(
        "navigation-menu",
        { "navigation-menu--minimized": isMinimized },
        classes
      )}
    >
      {items &&
        items.map((item, index) => {
          if (item.hideLink) {
            return null;
          }
          return (
            <div className="navigation-menu__item-container" key={index}>
              <NavigationItem
                isMinimized={isMinimized}
                currentPath={currentPath}
                {...item}
              />
            </div>
          );
        })}
    </div>
  );
}
